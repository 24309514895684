import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { routes } from '@router';

export function Router() {
	const router = createBrowserRouter(routes, {
		basename:
			import.meta.env.DEV || import.meta.env.MODE === 'preview'
				? ''
				: '/dashboard',
	});
	return <RouterProvider router={router} />;
}
