import { DataTableDevices, ProblemDialog } from '@components';
import { useState } from 'react';

export function Maintenance() {
	const [problemOpen, setProblemOpen] = useState(false);

	return (
		<>
			<DataTableDevices />
			<ProblemDialog open={problemOpen} setOpen={setProblemOpen} />
		</>
	);
}
