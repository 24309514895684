import { DateRangePreset } from '@frontend/util';
import { t } from 'i18next';
import { locales } from './locales';

declare module 'i18next' {
	interface CustomTypeOptions {
		defaultNS: 'en';
		resources: {
			en: typeof locales.en.translation;
			nb: typeof locales.nb.translation;
			nn: typeof locales.nb.translation;
		};
	}
}

export function getPrevDateReadable(type: DateRangePreset) {
	if (type === 'day') return t('dates.previous.yesterday');
	else if (type === 'week') return t('dates.previous.last-week');
	else if (type === 'month') return t('dates.previous.last-month');
	else return t('dates.previous.last-year');
}

export function getCurrDateReadable(type: DateRangePreset) {
	if (type === 'day') return t('dates.current.today');
	else if (type === 'week') return t('dates.current.this-week');
	else if (type === 'month') return t('dates.current.this-month');
	else return t('dates.current.this-year');
}

export { useTranslation } from 'react-i18next';
export * from './i18n';
export {
	getAllLocaleInfo,
	getCurrentLocale,
	getLocaleFromLocalStorage,
	getLocaleFromString,
	setDateLocale,
} from './locales';
export type { LocaleInfo, ValidLocale } from './locales';
export { t };
