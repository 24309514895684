import { Slot } from '@radix-ui/react-slot';
import { type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { buttonVariants } from '#ui';
import { cn } from '@frontend/util';
import { Loader2 } from 'lucide-react';

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean;
	isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{ className, variant, size, asChild = false, isLoading = false, ...props },
		ref,
	) => {
		const Comp = asChild ? Slot : 'button';
		return (
			<Comp
				className={cn(buttonVariants({ variant, size, className }))}
				disabled={isLoading || props.disabled}
				ref={ref}
				{...props}
			>
				{props.children}
				{isLoading && <Loader2 className="ml-2 h-5 w-5 animate-spin" />}
			</Comp>
		);
	},
);
Button.displayName = 'Button';

export { Button };
