import { useTranslation } from '@frontend/locale';
import { Button } from '@frontend/ui';
import { Keyboard } from 'lucide-react';
import { useEffect } from 'react';
import { FilterSidebarBaseProps, FilterSidebarRecord } from '../store';

type BaseApplyButtonProps<T extends FilterSidebarRecord> =
	FilterSidebarBaseProps<
		T,
		{
			onApply?: (values: T) => void;
		}
	>;

export const BaseApplyButton = <T extends FilterSidebarRecord>({
	useStore,
	onApply,
}: BaseApplyButtonProps<T>) => {
	const { filterValues } = useStore();
	const { t } = useTranslation();

	useEffect(() => {
		const handleEnter = (e: KeyboardEvent) => {
			if (e.key === 'Enter') {
				onApply && onApply(filterValues);
			}
		};
		window.addEventListener('keydown', handleEnter);
		return () => {
			window.removeEventListener('keydown', handleEnter);
		};
	}, [filterValues, onApply]);

	return (
		<div className="flex flex-1 flex-col justify-center">
			<Button
				onClick={() => onApply && onApply(filterValues)}
				className="w-full"
				variant="default"
			>
				<span>{t('common.apply')}</span>
			</Button>
			<span className="text-muted-foreground mt-2 flex justify-center text-xs">
				<Keyboard className="mr-2 h-4 w-4" />
				<span>enter</span>
			</span>
		</div>
	);
};
