import { cn } from '@frontend/util';
import { BaseApplyButton, BaseClearButton, BaseCloseButton } from '.';
import { FilterSidebarBaseProps, FilterSidebarRecord } from '../store';

type BaseFilterSidebarProps<T extends FilterSidebarRecord> =
	FilterSidebarBaseProps<
		T,
		{
			title?: string;
			subTitle?: string;
			onApply?: (values: T) => void;
			children?: React.ReactNode;
		}
	>;

// TODO: i18n
export const BaseFilterSidebar = <T extends FilterSidebarRecord>({
	useStore,
	title = 'Filter',
	onApply,
	children,
}: BaseFilterSidebarProps<T>) => {
	const { collapsed, setCollapsed } = useStore();

	return (
		<div
			className={cn({
				'fixed left-0 top-0 z-10 flex h-full w-full justify-end': true,
				hidden: collapsed,
			})}
		>
			<div
				className="bg-resani grow opacity-50"
				onClick={() => {
					setCollapsed(!collapsed);
				}}
				onKeyUp={() => setCollapsed(!collapsed)}
			/>

			<div
				className={cn(
					`border-border bg-background flex h-full w-80 flex-col overflow-auto border-l p-6`,
				)}
			>
				<div className="flex flex-col gap-4">
					<div className="mb-2">
						<div className="mb-2 flex items-center gap-2">
							<BaseCloseButton useStore={useStore} />
							{title && <p className="text-xl">{title}</p>}
						</div>
					</div>
					{children}
				</div>

				<div className="mt-4 flex flex-row gap-2 border-t pt-4">
					<BaseApplyButton
						useStore={useStore}
						onApply={(values) => onApply && onApply(values)}
					/>
					<BaseClearButton
						useStore={useStore}
						onClear={(values) => onApply && onApply(values)}
					/>
				</div>
			</div>
		</div>
	);
};
