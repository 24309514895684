import { UserManager } from 'oidc-client-ts';
import {
	BASE_SETTINGS,
	LOCAL_STORAGE_AUTH_KEY,
	REFRESH_OFFSET_MULTIPLIER,
} from './constants';
import { getURI, hasAuthParams, shouldRelogForOfflineAccess } from './utils';
import { useEffect, useRef } from 'react';
import { setManager, setUser, useAuthStore } from './store';
import { setLocalStorage } from '@frontend/util';

type UseInitAuthProps = {
	client_id: string;
	authority: string;
	online: boolean;
	pingServer: () => Promise<boolean> | boolean;
	onAccessTokenRefresh: (token?: string) => void;
};

export const useInitAuth = ({
	client_id,
	authority,
	online,
	pingServer,
	onAccessTokenRefresh,
}: UseInitAuthProps) => {
	const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

	const mgr = new UserManager({
		...BASE_SETTINGS,
		client_id,
		authority,
		automaticSilentRenew: false,
	});

	const signinSilent = async () => {
		const online = await pingServer();
		if (!online) return;
		try {
			const user = await mgr.signinSilent();
			if (user) {
				setUser(user);
				setLocalStorage(LOCAL_STORAGE_AUTH_KEY, user.access_token || '');
				onAccessTokenRefresh(user.access_token);
				startRefreshTimeout();
			}
		} catch (_) {
			mgr.signoutRedirect();
		}
	};

	const startRefreshTimeout = () => {
		if (timeoutRef.current) clearTimeout(timeoutRef.current);

		const { user } = useAuthStore.getState();
		if (!user) return;

		if (!user.expires_in || user.expires_in <= 0) return signinSilent();

		// offset multiplier to refresh before token expires
		const expiresInMs = user.expires_in * 1000 * REFRESH_OFFSET_MULTIPLIER;

		timeoutRef.current = setTimeout(() => {
			signinSilent();
		}, expiresInMs);
	};

	const signinCallback = async () => {
		const usr = await mgr.signinCallback();
		window.location.href = getURI();
		return usr || null;
	};

	useEffect(() => {
		const init = async () => {
			let user = await mgr.getUser();

			try {
				if (!user)
					if (hasAuthParams()) user = await signinCallback();
					else await mgr.signinRedirect();
			} catch (e) {
				console.error(e);
				return;
			}

			if (shouldRelogForOfflineAccess(user)) mgr.signoutRedirect();

			setLocalStorage(LOCAL_STORAGE_AUTH_KEY, user?.access_token || '');
			onAccessTokenRefresh(user?.access_token);

			if (user) {
				setManager(mgr);
				setUser(user);
				startRefreshTimeout();
			}
		};

		init();
	}, []);

	useEffect(() => {
		if (!online) return;
		startRefreshTimeout();
	}, [online]);
};
