import { getDateFromTime } from '@frontend/util';
import { z } from 'zod';

export const shiftTypeSchema = z
	.object({
		name: z.string().max(100).min(1),
		start: z.string().min(1),
		end: z.string().min(1),
		sectionId: z.string(),
	})
	.refine(
		(data) => {
			if (!data.start || !data.end) return false;
			const start = getDateFromTime(data.start);
			const end = getDateFromTime(data.end);
			return start < end;
		},
		{
			message: 'Start time must be before end time',
			path: ['start'],
		},
	);
