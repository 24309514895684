import { useFilterSidebar } from '@components';
import { Operators } from '@frontend/api';
import { usePremise } from '@frontend/context';
import { useURLParams } from '@frontend/hook';
import { useTranslation } from '@frontend/locale';
import { formatDate } from '@frontend/util';
import { useEffect, useState } from 'react';

export type MaintenanceFilterType = {
	sectionId: string;
	status?: 'on' | 'off';
	powerType?: 'battery' | 'cable';
	fluidOp?: Operators;
	fluidVal?: number;
	batteryVal?: number;
	batteryOp?: Operators;
	batteryExpDate?: Date;
	batteryExpOp?: Operators;
	batteryChgDate?: Date;
	batteryChgOp?: Operators;
	searchTerm?: string;
};

export const useMaintenanceFilterSidebar = () => {
	const { t } = useTranslation();
	const { sections } = usePremise();

	const sectionOpts = Object.values(sections).map((section) => ({
		label: section.name,
		value: section.id,
	}));

	const getSectionName = (id: string) => {
		return (
			sectionOpts.find((section) => section.value === id)?.label || 'UNKNOWN'
		);
	};

	const { getURLParam, setURLParam, defaultValues } =
		useURLParams<MaintenanceFilterType>({
			sectionId: sectionOpts[0]?.value,
			status: undefined,
			powerType: undefined,
			fluidOp: undefined,
			fluidVal: undefined,
			batteryOp: undefined,
			batteryVal: undefined,
			searchTerm: undefined,
			batteryChgOp: undefined,
			batteryExpOp: undefined,
			batteryChgDate: undefined,
			batteryExpDate: undefined,
		});

	const urlParams = getURLParam([
		'sectionId',
		'status',
		'powerType',
		'fluidOp',
		'fluidVal',
		'batteryVal',
		'batteryOp',
		'batteryExpDate',
		'batteryExpOp',
		'batteryChgDate',
		'batteryChgOp',
		'searchTerm',
	]);

	useEffect(() => {
		if (sectionOpts.length > 0) applyFilter({});
	}, [sectionOpts.length]);

	const [filterState, setFilterState] =
		useState<MaintenanceFilterType>(urlParams);

	const statusOpts = [
		{
			label: t('common.on'),
			value: 'on',
		},
		{
			label: t('common.off'),
			value: 'off',
		},
	];

	const powerTypeOpts = [
		{
			label: t('common.battery'),
			value: 'battery',
		},
		{
			label: t('common.cable'),
			value: 'cable',
		},
	];

	const operatorOpts: { label: string; value: Operators }[] = [
		{
			label: '<',
			value: 'lt',
		},
		{
			label: '<=',
			value: 'lte',
		},
		{
			label: '=',
			value: 'eq',
		},
		{
			label: '>=',
			value: 'gte',
		},
		{
			label: '>',
			value: 'gt',
		},
	];

	const applyFilter = (filters: Partial<MaintenanceFilterType>) => {
		const obj: MaintenanceFilterType = {
			...urlParams,
			...filters,
		};

		const keys = Object.keys(filters) as (keyof MaintenanceFilterType)[];

		keys.forEach((key) => {
			const val = filters[key];
			setURLParam(key, val);
		});

		setFilterState(obj);
	};

	const {
		Sidebar,
		ToggleButton,
		RadioGroup,
		Select,
		Slider,
		DatePicker,
		Text,
		Badges,
	} = useFilterSidebar<MaintenanceFilterType>(urlParams, defaultValues);

	const BadgesList = () => (
		<Badges
			onBadgeRemove={(key) => {
				applyFilter({ [key]: undefined });
			}}
			badges={{
				sectionId: {
					label: t('common.department'),
					value: getSectionName(urlParams.sectionId),
					preventOnBadgeRemove: true,
				},
				status: {
					label: t('common.status'),
					value: statusOpts.find((item) => item.value === urlParams.status)
						?.label,
				},
				powerType: {
					label: t('titles.power-type'),
					value: powerTypeOpts.find(
						(item) => item.value === urlParams.powerType,
					)?.label,
				},
				fluidVal: {
					label: t(`common.fluid`),
					value: () => {
						if (urlParams.fluidVal === undefined) return;

						const defaultOp: Operators = 'lt';

						const op =
							operatorOpts.find((item) => item.value === urlParams.fluidOp)
								?.value || defaultOp;

						return t(`numbers.${op}`, {
							val: t(`numbers.percentage`, {
								val: urlParams.fluidVal || 0,
							}),
						});
					},
				},
				batteryVal: {
					label: t(`common.battery`),
					value: () => {
						if (urlParams.batteryVal === undefined) return;

						const defaultOp: Operators = 'lt';

						const op: Operators =
							operatorOpts.find((item) => item.value === urlParams.batteryOp)
								?.value || defaultOp;

						return t(`numbers.${op}`, {
							val: t(`numbers.percentage`, {
								val: urlParams.batteryVal || 0,
							}),
						});
					},
				},
				batteryExpDate: {
					label: t(`titles.battery-runs-out-in`),
					value: () => {
						if (!urlParams.batteryExpDate) return;

						const defaultOp: Operators = 'lt';
						const op =
							operatorOpts.find((item) => item.value === urlParams.batteryExpOp)
								?.value || defaultOp;

						return t(`numbers.${op}`, {
							val: t(`dates.relative.default`, {
								val:
									urlParams.batteryExpDate &&
									formatDate(urlParams.batteryExpDate, 'PPP'),
							}),
						});
					},
				},
				batteryChgDate: {
					label: t(`titles.battery-changed`),
					value: () => {
						if (!urlParams.batteryChgDate) return;

						const defaultOp: Operators = 'lt';

						const op =
							operatorOpts.find((item) => item.value === urlParams.batteryChgOp)
								?.value || defaultOp;

						return t(`numbers.${op}`, {
							val: t(`dates.relative.default`, {
								val:
									urlParams.batteryChgDate &&
									formatDate(urlParams.batteryChgDate, 'PPP'),
							}),
						});
					},
				},
				searchTerm: {
					label: t('titles.search-by-room-name'),
					value: urlParams.searchTerm,
				},
			}}
		/>
	);

	const FilterSidebar = () => (
		<Sidebar
			title="Filter"
			subTitle="Maintenance filter knobs"
			onApply={(vals) => {
				applyFilter(vals);
			}}
		>
			<Select
				name="sectionId"
				label={t('common.department')}
				selectFirst={true}
				opts={sectionOpts}
			/>
			<RadioGroup name="status" label={t('common.status')} opts={statusOpts} />
			<RadioGroup
				name="powerType"
				label={t('titles.power-type')}
				opts={powerTypeOpts}
			/>
			<Slider name="fluidVal" label={t('common.fluid')} />
			<RadioGroup name="fluidOp" label="" opts={operatorOpts} />
			<Slider name="batteryVal" label={t('common.battery')} />
			<RadioGroup name="batteryOp" label="" opts={operatorOpts} />
			<DatePicker
				name="batteryExpDate"
				label={t('titles.battery-runs-out-in')}
			/>
			<RadioGroup
				name="batteryExpOp"
				disabledBasedOn="batteryExpDate"
				label=""
				opts={operatorOpts}
			/>
			<DatePicker name="batteryChgDate" label={t('titles.battery-changed')} />
			<RadioGroup
				name="batteryChgOp"
				disabledBasedOn="batteryChgDate"
				label=""
				opts={operatorOpts}
			/>
			<Text name="searchTerm" label={t('titles.search-by-room-name')} />
		</Sidebar>
	);

	return {
		filterState,
		BadgesList,
		FilterSidebar,
		ToggleButton,
	};
};
