import { Badge } from '@frontend/ui';
import { uuid } from '@frontend/util';
import { X } from 'lucide-react';

export type BadgeObj = {
	label?: (() => string | undefined) | string | undefined;
	value: (() => string | undefined) | string | undefined;
	onRemoveBadge?: () => void;
};

type BadgesListProps = {
	badges: BadgeObj[];
	className?: string;
};

export const BadgesList = ({ badges, className }: BadgesListProps) => {
	return (
		<div className={`flex flex-row flex-wrap gap-2  ${className}`}>
			{badges?.map((b) => {
				if (!b.value) return null;

				const value = typeof b.value === 'function' ? b.value() : b.value;

				if (!value) return null;

				const label = typeof b.label === 'function' ? b.label() : b.label;

				return (
					<Badge key={uuid()} variant="default" className="">
						{b.onRemoveBadge && (
							<button onClick={() => b.onRemoveBadge && b.onRemoveBadge()}>
								<X className="mr-2 h-3 w-3 cursor-pointer" />
							</button>
						)}
						<span>
							{label && <span className="mr-2">{label}:</span>}
							<span>{value}</span>
						</span>
					</Badge>
				);
			})}
		</div>
	);
};
