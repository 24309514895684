import { getCurrDateReadable } from '@frontend/locale';
import { DateRange, getFormatForRange, isToday } from '@frontend/util';

type HumanDateRangeStringProps = {
	range: DateRange;
};

export function HumanDateRangeString({ range }: HumanDateRangeStringProps) {
	const { from, to, preset } = range;
	if (preset !== 'custom')
		return (
			<span className="text-center w-full">{getCurrDateReadable(preset)}</span>
		);

	if (from && to && isToday(from) && isToday(to))
		return getCurrDateReadable('day');

	const [f, t] = getFormatForRange({ from, to, preset });

	return (
		<span className="flex w-full flex-col text-center">
			<span>{f}</span>
			<span>{t}</span>
		</span>
	);
}
