import { useTranslation } from '@frontend/locale';
import {
	Checkbox,
	Label,
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@frontend/ui';
import { capitalizeFirstLetter, uuid } from '@frontend/util';
import { Layers } from 'lucide-react';
import { ReactNode, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { ControlButton } from './PremiseMapControls';
import { usePremiseStore } from './pixi';
import { getMapURLParams, setMapURLParam } from './pixi/modules';

const OptionsPopoverCheckbox = ({
	id = uuid(),
	label,
	isChecked,
	onChange,
}: {
	id?: string;
	label: string | ReactNode;
	isChecked?: boolean;
	onChange: (checked: boolean) => void;
}) => {
	const [checked, setChecked] = useState(isChecked || false);

	return (
		<div className="flex w-full items-center gap-4">
			<Checkbox
				id={id}
				onCheckedChange={() => {
					onChange(!checked);
					setChecked(!checked);
				}}
				checked={checked}
			/>
			<Label htmlFor={id}>{label}</Label>
		</div>
	);
};

export const PremiseMapOptionsPopover = () => {
	const { t } = useTranslation();
	const { app } = usePremiseStore(
		useShallow((state) => ({
			app: state.app,
		})),
	);
	const [open, setOpen] = useState(false);

	const { showNames, showCounts, showIcons } = getMapURLParams();

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<ControlButton>
					<Layers className="text-muted-foreground h-5 w-5" />
				</ControlButton>
			</PopoverTrigger>
			<PopoverContent className="flex w-auto flex-col gap-2" side="right">
				<OptionsPopoverCheckbox
					label={capitalizeFirstLetter(t('titles.room-name'))}
					isChecked={showNames === 1}
					onChange={(checked) => {
						if (checked) app?.premiseMap.toggleRoomNames(true);
						else app?.premiseMap.toggleRoomNames(false);
						setMapURLParam('showNames', checked ? 1 : 0);
					}}
				/>
				<OptionsPopoverCheckbox
					label={capitalizeFirstLetter(t('common.icon'))}
					isChecked={showIcons === 1}
					onChange={(checked) => {
						if (checked) app?.premiseMap.toggleRoomIcon(true);
						else app?.premiseMap.toggleRoomIcon(false);
						setMapURLParam('showIcons', checked ? 1 : 0);
					}}
				/>
				<OptionsPopoverCheckbox
					label={capitalizeFirstLetter(t('titles.dispenser-usage'))}
					isChecked={showCounts === 1}
					onChange={(checked) => {
						if (checked) app?.premiseMap.toggleRoomCounts(true);
						else app?.premiseMap.toggleRoomCounts(false);
						setMapURLParam('showCounts', checked ? 1 : 0);
					}}
				/>
				{/*
				TODO: Heatmap is currently based on min/max of usage, prob not right so we reavaulate
				you can still enable with url param &showHeatmap=1
				<OptionsPopoverCheckbox
					label={capitalizeFirstLetter(t('common.heatmap'))}
					isChecked={showHeatmap === 1}
					onChange={(checked) => {
						if (checked) app?.premiseMap.showHeatmap();
						else app?.premiseMap.hideHeatmap();
						setMapURLParam('showHeatmap', checked ? 1 : 0);
					}}
				/>
				*/}
			</PopoverContent>
		</Popover>
	);
};
