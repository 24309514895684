import { FloorNode, MapNode } from '@components';
import { Layer } from '@frontend/api';
import { t } from '@frontend/locale';
import { getThemeColor } from '@frontend/ui';
import {
	capitalizeFirstLetter,
	getColorIndex,
	getPctInRange,
} from '@frontend/util';
import { FlexContainer, LODContainer, LabelContainer } from '.';
import { PremiseMapApp } from '..';
import { Edges } from '../utils';
import PremiseText from './PremiseText';
import { Icon } from './RoomTypeIcon';
import { FillMap, IconName } from './assets';
import { DoorGraphic, WindowGraphic, ZoneGraphic } from './graphics';
import WallsGraphic from './graphics/WallGraphic';

const LABEL = 'Primary Container';
const LAYER_NAME_WALL = 'walls';
const LAYER_NAME_DOOR = 'doors';
const LAYER_NAME_WINDOW = 'windows';
const LAYER_NAME_ZONE = 'zones';
const LAYER_NAME_COVER = 'cover';

type MapNodeExtra = MapNode & {
	graphic: ZoneGraphic;
};

type NodeIdMap = { [id: string]: MapNodeExtra };

type RoomTypeIconMap = Record<string, IconName>;

const roomTypeIconMap: RoomTypeIconMap = {
	'00000000-21f5-438d-86ab-5114d851e73d': 'bed-double',
	'00000000-5dcd-44fe-bf51-980a4daa056c': 'wc',
	'00000000-4094-4585-8655-0cd874bff7da': 'wc',
	'00000000-8b81-44dc-b037-9cef45f7b752': 'door-enter',
	'00000000-80aa-41f7-9dcb-b9cb56b552b7': 'foot-steps',
	'00000000-bb84-4fda-b45c-8a8a0f84e70e': 'fork-and-knife',
	'00000000-c10e-4710-bec6-1f3b150539ec': 'stairs',
	'00000000-5dca-43f3-a1e1-ee79bcec040c': 'living-room',
	'00000000-88e2-43ca-b367-62d1301f33da': 'elevator',
	'00000000-4630-49cf-a11d-013534cdd5d4': 'storage',
	'00000000-e83c-4a74-add7-a9201e510876': 'storage',
	'00000000-a29b-4921-a9e5-503f285ef886': 'storage',
	'00000000-440c-485e-841e-4d2aea775c0c': 'washing-machine',
	'00000000-8f94-47ff-83d4-0a4ba0e0607b': 'washing-machine',
	'00000000-eea6-40e4-a7a8-100dae270930': 'office',
	'00000000-e370-4b83-b2d4-a086c402baf1': 'gym',
	'00000000-4ecf-44a1-8b21-846a363f2d32': 'staff',
	'00000000-3662-42af-bae7-908d273beb68': 'pill',
	'00000000-0d66-4e62-a776-228068ed0119': 'meeting',
};

export default class PremiseMap extends FlexContainer {
	private _app: PremiseMapApp;
	private _nodeIdMap: NodeIdMap = {};
	private _hoverNode?: MapNodeExtra;
	private _activeNode?: MapNodeExtra;
	private _minTransCount = 0;
	private _maxTransCount = 0;

	public LODContainers: LODContainer[] = [];
	public labels: (LabelContainer | FlexContainer)[] = [];
	public icons: Icon[] = [];
	public names: PremiseText[] = [];
	public counts: PremiseText[] = [];

	constructor(app: PremiseMapApp) {
		super();

		this.label = LABEL;
		this._app = app;
	}

	addToViewport() {
		if (!this._app.viewport) throw new Error('No viewport found');
		this._app.viewport.addChild(this);
	}

	render(floorArray: FloorNode[]) {
		for (const f of floorArray) {
			const c = new FlexContainer();
			c.sortableChildren = true;
			const zonec = new FlexContainer();
			const labelc = new FlexContainer();
			const staticc = new FlexContainer();

			for (const n of f.nodes) {
				if (n.name === 'STATIC')
					staticc.addChild(
						...this.renderStatic(n.layers, f.name, f.totalCount),
					);
				else {
					const [zones, labels] = this.renderZones(n);
					zonec.addChild(zones);
					labelc.addChild(labels);
				}
			}

			c.addChild(zonec);
			c.addChild(staticc);
			c.addChild(labelc);
			this.addChild(c);
		}
	}

	renderStatic(layers: Layer[], floorName: string, floorCount: number) {
		const doorLayer = new LODContainer({
			lodRange: [0.5, 0],
		});

		doorLayer.zIndex = 10;

		const windowLayer = new LODContainer({
			lodRange: [0.5, 0],
		});

		windowLayer.zIndex = 10;

		const wallLayer = new LODContainer({
			lodRange: [0.5, 0],
		});

		wallLayer.zIndex = 10;

		const coverLayer = new LODContainer({
			lodRange: [0, 0.5],
		});

		for (const layer of layers) {
			if (layer.name === LAYER_NAME_WALL) {
				const walls = layer.paths.map((p) => new WallsGraphic(p));
				wallLayer.addChild(...walls);
			} else if (layer.name === LAYER_NAME_DOOR) {
				const doors = layer.paths.map((p) => new DoorGraphic(p));
				doorLayer.addChild(...doors);
			} else if (layer.name === LAYER_NAME_WINDOW) {
				const windows = layer.paths.map((p) => new WindowGraphic(p));
				windowLayer.addChild(...windows);
			} else if (layer.name === LAYER_NAME_COVER) {
				const cover = new WallsGraphic(layer.paths[0]);

				const edges = cover.getEdges();
				const c = new LabelContainer({ parentEdges: edges });

				const name = new PremiseText({
					text: `${capitalizeFirstLetter(t('common.floor', { count: 1 }))}: ${floorName}`,
					fontSize: 'lg',
					fontColor: 'muted-foreground',
				});
				const count = new PremiseText({
					text: floorCount.toString(),
					fontColor: 'resani',
					fontWeight: '900',
					fontSize: '2xl',
				});

				c.addChild(name);
				c.addChild(count);

				c.xCenterChildren();
				c.alignChildren({});

				this.labels.push(c);
				coverLayer.addChild(cover);
				coverLayer.addChild(c);

				c.center();
			}
		}

		this.LODContainers.push(wallLayer);
		this.LODContainers.push(doorLayer);
		this.LODContainers.push(windowLayer);
		this.LODContainers.push(coverLayer);

		return [wallLayer, windowLayer, coverLayer, doorLayer];
	}

	renderZones(n: MapNode) {
		const zonesLayer = new LODContainer({ lodRange: [0.5, 0] });
		const labelsLayer = new LODContainer({ lodRange: [0.5, 0] });

		for (const layer of n.layers) {
			if (layer.name === LAYER_NAME_ZONE) {
				const zone = new ZoneGraphic(layer.paths[0], n.id);
				zonesLayer.addChild(zone);
				const edges = zone.getEdges();

				//if (n.name === 'Gang 7') {
				//	console.log(JSON.stringify(zone.getPoints().map((p) => [p.x, p.y])));
				//}

				const labelC = this.renderZoneLabels(n, edges);
				labelC.xCenterChildren();
				labelC.alignChildren({ gap: 5 });
				labelC.center();

				//const g = new Graphics();
				//g.circle(edges.center[0], edges.center[1], 2.5);
				//g.fill({ color: 'red' });
				//zonesLayer.addChild(g);

				this._nodeIdMap[n.id] = {
					...n,
					graphic: zone,
				};

				labelsLayer.addChild(labelC);
			}
		}

		this.LODContainers.push(zonesLayer);
		this.LODContainers.push(labelsLayer);

		return [zonesLayer, labelsLayer];
	}

	createZoneNameLabel(name: string) {
		const text = new PremiseText({
			text: name,
			fontColor: 'muted-foreground',
		});
		return text;
	}

	createZoneCountLabel(count: number) {
		const text = new PremiseText({
			text: count.toString(),
			fontSize: 'md',
			fontColor: 'resani',
			fontWeight: 'bold',
		});

		if (count > this._maxTransCount) this._maxTransCount = count;
		if (count < this._minTransCount) this._minTransCount = count;

		return text;
	}

	createZoneIcon(n: MapNode) {
		const iconName = roomTypeIconMap[n.typeId || ''];

		if (!iconName) return;

		const fills: FillMap | undefined =
			n.transCount && n.transCount > 0
				? { primary: 'muted-foreground', secondary: 'background' }
				: undefined;

		return new Icon({ name: iconName, fillMap: fills });
	}

	renderZoneLabels(n: MapNode, edges: Edges) {
		const c = new LabelContainer({ parentEdges: edges });

		const icon = this.createZoneIcon(n);
		if (icon) {
			c.addChild(icon);
			this.icons.push(icon);
		}

		const name = this.createZoneNameLabel(n.name);
		c.addChild(name);
		this.names.push(name);

		if (n.transCount) {
			const count = this.createZoneCountLabel(n.transCount);
			c.addChild(count);
			this.counts.push(count);
		}

		this.labels.push(c);

		return c;
	}

	setHoverNode(id?: string) {
		const current = this._hoverNode;
		const next = this._getNodeById(id);

		if (current?.id !== next?.id && next?.id !== this._activeNode?.id) {
			next?.graphic.toggleHover(true);
			this._hoverNode = next;
		}

		if (current?.id !== this._activeNode?.id) {
			current?.graphic.toggleHover(false);
		}
	}

	setActiveNode(id: string) {
		const current = this._activeNode;
		const next = this._nodeIdMap[id];

		if (current?.id !== next?.id) {
			next.graphic.toggleActive(true);
			this._activeNode = next;
		}

		current?.graphic.toggleActive(false);
	}

	clean() {
		this._nodeIdMap = {};
		this._activeNode = undefined;
		this._hoverNode = undefined;
		this.LODContainers = [];
		this.labels = [];
		this.icons = [];
		this.names = [];
		this.counts = [];
		this.removeChildren();
	}

	toggleRoomIcon(visible: boolean) {
		for (const x of this.icons) {
			x.visible = visible;
		}

		for (const l of this.labels) {
			l.alignChildren({ gap: 5 });
			if (l instanceof LabelContainer) l.center();
			l.xCenterChildren();
		}
	}

	toggleRoomNames(visible: boolean) {
		for (const x of this.names) {
			x.visible = visible;
		}

		for (const l of this.labels) {
			l.alignChildren({ gap: 5 });
			if (l instanceof LabelContainer) l.center();
			l.xCenterChildren();
		}
	}

	toggleRoomCounts(visible: boolean) {
		for (const x of this.counts) {
			x.visible = visible;
		}

		for (const l of this.labels) {
			l.alignChildren({ gap: 5 });
			if (l instanceof LabelContainer) l.center();
			l.xCenterChildren();
		}
	}

	showHeatmap() {
		const graphics = Object.values(this._nodeIdMap);

		const colorTreshold = [0, 15, 25, 50, 75];
		const colors = [
			getThemeColor('background'),
			'e1d8f7',
			'd7c8f3',
			'd0bef2',
			'c0a7eb',
		];

		graphics.forEach((g) => {
			const value = getPctInRange(
				this._minTransCount,
				this._maxTransCount,
				g.transCount || 0,
			);
			const colorIndex = getColorIndex(colorTreshold, value) || 0;
			const color = colors[colorIndex];

			if (color) {
				g.graphic.setHeatmapColor(color);
			}
		});
	}

	hideHeatmap() {
		const graphics = Object.values(this._nodeIdMap);
		for (const g of graphics) {
			g.graphic.removeHeatmapColor();
		}
	}

	_getNodeById(id?: string) {
		if (!id) return;
		return this._nodeIdMap[id];
	}
}
