import { usePremise, useQueryGetUsageInPeriod } from '@frontend/context';
import { useTranslation } from '@frontend/locale';
import {
	Spinner,
	Card,
	CardContent,
	CardHeader,
	CardTitle,
} from '@frontend/ui';
import { DateRange } from '@frontend/util';
import { BaseBarChart } from '@components';
import * as TWColors from 'tailwindcss/colors';

type DosagesByFluidTypeCard = {
	sectionIds: string[];
	roomTypeIds?: string[];
} & DateRange;

//const generateData = (
//	howMany: number = 3,
//	range: [number, number] = [0, 1000],
//): {
//	name: string;
//	soap: number;
//	alchohol: number;
//}[] => {
//	const arr: ReturnType<typeof generateData> = [];
//
//	for (let i = 0; i < howMany; i++) {
//		arr.push({
//			name: "dept-" + i,
//			soap: generateRandomNumber(range[0], range[1]),
//			alchohol: generateRandomNumber(range[0], range[1]),
//		});
//	}
//
//	return arr;
//};

export const DosagesByFluidTypeCard = ({
	from,
	to,
	sectionIds,
	roomTypeIds,
}: DosagesByFluidTypeCard) => {
	const { t } = useTranslation();
	const { sections } = usePremise();
	const { isLoading, data } = useQueryGetUsageInPeriod({
		from,
		to,
		roomTypeIds: roomTypeIds || [],
		sectionIds: sectionIds || [],
		roomIds: [],
	});

	const reducer = (d: typeof data) =>
		d === undefined
			? {}
			: d.transactions.reduce<{
					[key: string]: { soap: number; alchohol: number };
				}>((acc, { sectionId, fluidType }) => {
					if (acc[sectionId] === undefined) {
						acc[sectionId] = {
							soap: 1,
							alchohol: 1,
						};
					} else {
						acc[sectionId].soap += fluidType === 'Soap' ? 1 : 0;
						acc[sectionId].alchohol += fluidType === 'Alcohol' ? 1 : 0;
					}
					return acc;
				}, {});

	const reduced = reducer(data);
	const labels = Object.keys(reduced).map((r) => sections[r].name);
	const series = Object.values(reduced);

	return (
		<Card>
			<CardHeader>
				<CardTitle>{t('titles.usage-by-fluid-type')}</CardTitle>
			</CardHeader>
			<CardContent className="flex h-96 items-center justify-center">
				{isLoading && <Spinner className="h-20 w-20" />}
				{!isLoading && (
					<BaseBarChart
						colors={{
							light: [TWColors['sky']['500'], TWColors['purple']['500']],
							dark: [TWColors['sky']['500'], TWColors['purple']['500']],
						}}
						layout="horizontal"
						dataKey="name"
						labels={labels}
						series={series}
					/>
				)}
			</CardContent>
		</Card>
	);
};
