import {
	LocaleInfo,
	getAllLocaleInfo,
	setDateLocale,
	useTranslation,
} from '@frontend/locale';

import {
	Button,
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@frontend/ui';
import { Languages } from 'lucide-react';

export function LanguageDropdown() {
	const allLocales = getAllLocaleInfo();
	const { i18n } = useTranslation();

	const handleMenuItemClick = (newLocale: LocaleInfo) => {
		i18n.changeLanguage(newLocale.id);
		setDateLocale(newLocale.id);
		location.reload();
	};

	return (
		<>
			<DropdownMenu>
				<DropdownMenuTrigger asChild>
					<Button variant="ghost" className="w-full justify-start">
						<Languages className="mr-2 h-4 w-4" />
						{i18n.t('common.language')}
					</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent>
					{allLocales.map((lang) => (
						<DropdownMenuItem
							onClick={() => handleMenuItemClick(lang)}
							key={lang.id}
						>
							{lang.label}
						</DropdownMenuItem>
					))}
				</DropdownMenuContent>
			</DropdownMenu>
		</>
	);
}
