import { LegendProps } from 'recharts';

type ChartLegendProps = LegendProps & {
	className?: string;
};

export const ChartLegend = ({ payload, className }: ChartLegendProps) => {
	return (
		<div className={`flex flex-wrap justify-end gap-2  ${className}`}>
			{payload?.map((entry, _i) => {
				return (
					<div
						key={`chart_legend_${_i + entry.value}`}
						className="flex items-center"
					>
						<span
							className="mr-2 block h-3 w-3 rounded bg-black opacity-90"
							style={{ backgroundColor: entry.color }}
						></span>
						<span className="text-muted-foreground">{entry.value}</span>
					</div>
				);
			})}
		</div>
	);
};
