import { useTranslation } from '@frontend/locale';
import {
	Button,
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@frontend/ui';
import { uuid } from '@frontend/util';
import { Table as TableType } from '@tanstack/react-table';
import { ChevronLeft, ChevronRight } from 'lucide-react';

export const Pagination = <TData,>({
	totalItems,
	table,
}: {
	totalItems: number;
	table: TableType<TData>;
}) => {
	const { t } = useTranslation();
	return (
		<div className="flex flex-col items-center justify-center gap-2 text-xs md:flex-row">
			<span>
				{t('titles.val-of-max', {
					aVal: table.getRowModel().rows?.length,
					bVal: totalItems ?? 0,
				})}
			</span>
			<div className="flex items-center gap-2">
				<Button
					variant="outline"
					className="p-1"
					onClick={() => table.previousPage()}
					disabled={!table.getCanPreviousPage()}
				>
					<ChevronLeft className="h-4 w-4" />
				</Button>
				{t('titles.page-of-max', {
					aVal: table.getState().pagination.pageIndex + 1,
					bVal: table.getPageCount(),
				})}
				<Button
					variant="outline"
					className="p-1"
					onClick={() => table.nextPage()}
					disabled={!table.getCanNextPage()}
				>
					<ChevronRight className="h-4 w-4" />
				</Button>
			</div>
			<Select
				defaultValue="20"
				onValueChange={(val) => {
					table.setPageSize(Number(val));
				}}
			>
				<SelectTrigger className="w-auto p-2 text-xs">
					<SelectValue />
				</SelectTrigger>
				<SelectContent>
					<SelectGroup className="text-xs">
						{[10, 20, 30, 40, 50].map((pageSize) => (
							<SelectItem value={pageSize.toString()} key={uuid()}>
								{t('titles.show-per-page', { val: pageSize })}
							</SelectItem>
						))}
					</SelectGroup>
				</SelectContent>
			</Select>
		</div>
	);
};
