import { Button } from '@frontend/ui';
import { cn } from '@frontend/util';
import { X } from 'lucide-react';
import { FilterSidebarBaseProps, FilterSidebarRecord } from '../store';

type BaseCloseButtonProps<T extends FilterSidebarRecord> =
	FilterSidebarBaseProps<T, Record<string, unknown>>;

export const BaseCloseButton = <T extends FilterSidebarRecord>({
	useStore,
}: BaseCloseButtonProps<T>) => {
	const { collapsed, setCollapsed } = useStore();
	return (
		<Button
			onClick={() => setCollapsed(!collapsed)}
			variant="ghost"
			className={cn({ hidden: collapsed }, 'p-2')}
		>
			<X />
		</Button>
	);
};
