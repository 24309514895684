import { DateRangePreset } from './types';

type DateFormat = Record<
	DateRangePreset,
	{
		short: string;
		long: string;
	}
>;

export default {
	day: {
		short: 'HH:mm',
		long: 'HH:mm',
	},
	week: {
		short: 'eee, HH:mm',
		long: 'eeee, HH:mm',
	},
	month: {
		short: 'dd',
		long: 'MMMM dd',
	},
	year: {
		short: 'MMM',
		long: 'MMMM dd',
	},
	custom: {
		short: 'dd',
		long: 'dd',
	},
} satisfies DateFormat;
