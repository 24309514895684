import { Context, useContext } from 'react';

export default function <T>(context: Context<T>) {
	const c = useContext(context);
	if (!c)
		throw new Error(
			`No ${context.displayName} found, have you wrapped your App with the correct provider?`,
		);

	return c;
}
