import { setAuthHeader } from '@frontend/api';
import {
	PremiseProvider,
	useIsOnline,
	usePingServer,
	useInitAuth,
} from '@frontend/context';
import { useTranslation } from '@frontend/locale';
import { useTheme, Version, useScreenLoader } from '@frontend/ui';
import { cn, getResaniTenantInfo } from '@frontend/util';
import { useEffect } from 'react';

import { Outlet } from 'react-router-dom';

export function App() {
	const { theme } = useTheme();
	const online = useIsOnline();
	const { pingServer } = usePingServer();
	const { addLoader, resetLoader } = useScreenLoader('auth');
	const { t } = useTranslation();

	const CLIENT_ID = 'tenant-frontend';
	const { urls } = getResaniTenantInfo();

	useInitAuth({
		client_id: CLIENT_ID,
		authority: urls.authority,
		online,
		pingServer,
		onAccessTokenRefresh: (token) => token && setAuthHeader(token),
	});

	useEffect(() => {
		if (theme === 'dark') {
			document.body.classList.add('dark');
		} else {
			document.body.classList.remove('dark');
		}

		return () => {
			resetLoader();
		};
	}, [theme]);

	useEffect(() => {
		addLoader(t('titles.logging-in'));

		return () => {
			resetLoader();
		};
	}, []);

	// Refresh every hour
	useEffect(() => {
		if (!online) return;

		const hourMS = 60 * 60 * 3600 * 1000;

		const refresh = setTimeout(async () => {
			const online = await pingServer();
			if (online) window.location.reload();
		}, hourMS);

		return () => {
			clearTimeout(refresh);
		};
	}, [online]);

	return (
		<div className={cn('relative flex h-full w-full flex-col')}>
			<PremiseProvider>
				<Outlet />
			</PremiseProvider>
			<Version version={__APP_VERSION__} />
		</div>
	);
}
