import {
	BaseChartContainer,
	BaseChartProps,
	ChartLegend,
	ChartTooltip,
	createXAxis,
	createYAxis,
} from '@components';
import { useTheme } from '@frontend/ui';
import { CartesianGrid, Legend, Line, Tooltip } from 'recharts';

import { chartConfig } from './config';

export const BaseLineChart = ({
	labels,
	series,
	hasLegend = true,
	hasTooltip = true,
	colors = chartConfig.colors,
	dataKey,
	xAxis,
	yAxis,
	tooltipContent,
	layout,
	strokeDash = '3 3',
	strokeIndex = 0,
	strokeWidth = 3,
	chartKeys,
}: BaseChartProps & { chartKeys: string[] }) => {
	const { theme } = useTheme();

	const data = labels.map((label, index) => {
		const lineData = series[index];
		return {
			[dataKey]: label,
			...lineData,
		};
	});

	return (
		<BaseChartContainer type="line" data={data} layout={layout}>
			{createXAxis({ layout, dataKey, ...xAxis })}
			{createYAxis({ layout, dataKey, ...yAxis })}
			<CartesianGrid {...chartConfig.cartesianGrid} />
			{hasLegend && <Legend content={<ChartLegend />} />}
			{hasTooltip && <Tooltip content={tooltipContent || <ChartTooltip />} />}
			{chartKeys.map((line, _i) => {
				const color = colors[theme][_i];
				return (
					<Line
						key={line + '_line'}
						dataKey={line}
						strokeWidth={strokeWidth}
						stroke={color}
						type={'bump'}
						strokeDasharray={strokeDash && _i === strokeIndex ? strokeDash : ''}
						dot={false}
					/>
				);
			})}
		</BaseChartContainer>
	);
};
