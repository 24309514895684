import { useTranslation } from '@frontend/locale';
import { Label, Slider } from '@frontend/ui';
import { useEffect, useState } from 'react';
import { FilterSidebarBaseProps, FilterSidebarRecord } from '../store';

type BaseSliderProps<T extends FilterSidebarRecord> = FilterSidebarBaseProps<
	T,
	{
		name: keyof T;
		label: string;
		min?: number;
		max?: number;
		disabled?: boolean;
	}
>;

export const BaseSlider = <T extends FilterSidebarRecord>({
	name,
	label,
	min = 0,
	max = 100,
	disabled,
	useStore,
}: BaseSliderProps<T>) => {
	const { filterValues, setFilterValue } = useStore();

	// TODO: Can we make this more type-safe?
	const selected = filterValues[name] as number | undefined;

	if (selected !== undefined && typeof selected !== 'number') {
		throw new Error('Filter value must be a number');
	}

	const [localVal, setLocalVal] = useState<number>(selected || min);

	const { t } = useTranslation();

	useEffect(() => {
		setLocalVal(selected !== undefined ? selected : min);
	}, [selected]);

	return (
		<div className="flex flex-col gap-2">
			<Label>{label}</Label>
			<Slider
				min={min}
				max={max}
				disabled={disabled ?? false}
				defaultValue={[localVal]}
				value={[localVal]}
				step={1}
				onValueChange={(val) => {
					setLocalVal(val[0]);
				}}
				onValueCommit={(val) => {
					setFilterValue(name, val[0] as T[keyof T]);
				}}
			/>
			<span>
				{t('numbers.percentage', {
					val: localVal,
				})}
			</span>
		</div>
	);
};
