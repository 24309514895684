import { Input, Label } from '@frontend/ui';
import { FilterSidebarBaseProps, FilterSidebarRecord } from '../store';

type BaseTextProps<T extends FilterSidebarRecord> = FilterSidebarBaseProps<
	T,
	{
		name: keyof T;
		label: string;
		type?: 'basic' | 'week';
		onSelect?: (value: T[keyof T]) => void;
	}
>;

export const BaseText = <T extends FilterSidebarRecord>({
	name,
	label,
	useStore,
}: BaseTextProps<T>) => {
	const { filterValues, setFilterValue } = useStore();

	// TODO: Can we make this more type-safe?
	const selected = filterValues[name] as string | undefined;

	if (selected !== undefined && typeof selected !== 'string') {
		throw new Error('Filter value must be a number');
	}

	// const [state, setState] = useState<string>(selected || '');

	return (
		<div>
			<Label>{label}</Label>
			<Input
				value={selected || ''}
				onChange={(e) => {
					// TODO: NOT PERFORMANT, EACH TIME YOU TYPE IT WILL RE RENDER the whole sidebar :(
					// Onblur is also not great :/ since you have to focus out in order to apply state
					setFilterValue(name, e.target.value as T[keyof T]);
				}}
			/>
		</div>
	);
};
