import { useTranslation } from '@frontend/locale';
import {
	Button,
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@frontend/ui';
import { uuid } from '@frontend/util';
import { Expand, RotateCw, Shrink, ZoomIn, ZoomOut } from 'lucide-react';

import { PropsWithChildren, forwardRef } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { PremiseMapOptionsPopover } from './PremiseMapOptionsPopover';
import { usePremiseStore } from './pixi';

type ControlButtonProps = PropsWithChildren<{
	onClick?: () => void;
}>;

export const ControlButton = forwardRef<HTMLButtonElement, ControlButtonProps>(
	({ onClick, children }, ref) => {
		return (
			<Button
				ref={ref}
				variant="outline"
				className="h-10 w-10 p-0"
				onClick={onClick}
			>
				{children}
			</Button>
		);
	},
);

type ViewportControlsProps = {
	onClickZoomIn: () => void;
	onClickZoomOut: () => void;
	onClickZoomReset: () => void;
};

const ViewportControls = ({
	onClickZoomIn,
	onClickZoomOut,
	onClickZoomReset,
}: ViewportControlsProps) => (
	<>
		<ControlButton onClick={onClickZoomIn}>
			<ZoomIn className="text-muted-foreground h-5 w-5" />
		</ControlButton>
		<ControlButton onClick={onClickZoomOut}>
			<ZoomOut className="text-muted-foreground h-5 w-5" />
		</ControlButton>
		<ControlButton onClick={onClickZoomReset}>
			<RotateCw className="text-muted-foreground h-5 w-5" />
		</ControlButton>
	</>
);

type FloorLabelItem = {
	label: string;
	value: number;
};

type FloorSelectProps = {
	floors: FloorLabelItem[];
	onSelectFloor: (item: FloorLabelItem) => void;
};

const FloorSelect = ({ floors, onSelectFloor }: FloorSelectProps) => {
	const { t } = useTranslation();
	if (floors.length <= 1) return null;
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button
					variant="outline"
					className="text-muted-foreground rounded-full"
				>
					{t('common.floor', { count: 2 })}
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent className="ml-6 flex  flex-col justify-start gap-2 border-none bg-transparent shadow-none">
				{floors.map((floor) => (
					<DropdownMenuItem
						key={uuid()}
						onClick={() => onSelectFloor(floor)}
						asChild
					>
						<Button
							variant="outline"
							className="flex h-10 w-10 cursor-pointer justify-center rounded-full shadow-md"
						>
							{floor.label}
						</Button>
					</DropdownMenuItem>
				))}
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

type FullscreenButtonProps = {
	fullscreen: boolean;
	setFullscreen: (fullScreen: boolean) => void;
};

const FullscreenButton = ({
	fullscreen,
	setFullscreen,
}: FullscreenButtonProps) => (
	<ControlButton onClick={() => setFullscreen(!fullscreen)}>
		{fullscreen ? (
			<Shrink className="text-muted-foreground h-5 w-5" />
		) : (
			<Expand className="text-muted-foreground h-5 w-5" />
		)}
	</ControlButton>
);

export const PremiseMapControls = () => {
	const { app, floorLabelArray, isFullScreen } = usePremiseStore(
		useShallow((state) => ({
			app: state.app,
			floorLabelArray: state.floorLabelArray,
			isFullScreen: state.isFullScreen,
		})),
	);

	return (
		<>
			<div className="absolute left-2 top-2 flex flex-col gap-2">
				<ViewportControls
					onClickZoomIn={() => app?.viewport?.zoomIn()}
					onClickZoomOut={() => app?.viewport?.zoomOut()}
					onClickZoomReset={() => app?.viewport?.zoomReset()}
				/>
				<PremiseMapOptionsPopover />
			</div>
			<div className="absolute bottom-0 left-0 p-2">
				<FloorSelect
					floors={floorLabelArray}
					onSelectFloor={(f) => {
						app?.viewport?.zoomToFloor(f.value);
					}}
				/>
			</div>
			<div className="absolute right-0 top-0 p-2">
				<FullscreenButton
					fullscreen={isFullScreen}
					setFullscreen={() =>
						usePremiseStore.setState({ isFullScreen: !isFullScreen })
					}
				/>
			</div>
		</>
	);
};
