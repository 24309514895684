import { setWeek } from 'date-fns/setWeek';
import { setYear } from 'date-fns/setYear';
import { getStartOfWeek, WeekYear } from './';

export default function ({ week, year }: WeekYear): Date {
	let date = new Date();
	date = setYear(date, year);
	date = setWeek(date, week);

	return getStartOfWeek(date);
}
