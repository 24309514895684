import { HTMLAttributes, useState } from 'react';

import { useTranslation } from '@frontend/locale';
import {
	Popover,
	PopoverContent,
	PresetSelect,
	RangeTriggerButton,
	DateStaticInput,
	CalendarDialog,
	getScreenSize,
	CalendarPopover,
} from '#ui';
import {
	DateRange,
	DateRangePreset,
	getDateRange,
	getDateRangePreset,
	getEndOfDay,
	getStartOfDay,
} from '@frontend/util';

export type DatePresetType = {
	label: string;
	value: string;
};

type DatePickerWithRangeProps = {
	range?: DateRange;
	setRange: (range: DateRange) => void;
	className?: string;
} & HTMLAttributes<HTMLDivElement>;

export function DatePickerRange({
	range = {
		...getDateRange({
			from: new Date(),
			preset: 'day',
		}),
	},
	setRange,
}: DatePickerWithRangeProps) {
	const { t } = useTranslation();
	const [state, setState] = useState<{ open: boolean; calendarOpen: boolean }>({
		open: false,
		calendarOpen: false,
	});
	const [local, setLocal] = useState<DateRange>(range);

	const onSelectPresetChanged = (preset: DateRangePreset) => {
		const range = getDateRange({
			from: new Date(),
			preset,
		});

		setRange(range);
	};

	const setCalendarOpen = (open: boolean) =>
		setState((state) => ({ ...state, calendarOpen: open }));
	const setOpen = (open: boolean) => setState((state) => ({ ...state, open }));

	return (
		<Popover open={state.open}>
			<RangeTriggerButton range={local} onClick={() => setOpen(true)} />
			<PopoverContent
				onInteractOutside={() => {
					setState({ open: false, calendarOpen: false });
					setRange(local);
				}}
				className="w-auto p-0 relative"
				align="center"
				side="left"
			>
				{['sm', 'md'].includes(getScreenSize()) ? (
					<CalendarDialog
						open={state.calendarOpen}
						setOpen={setCalendarOpen}
						range={local}
						onApply={(from, to) => {
							const preset = getDateRangePreset({ from, to });
							setLocal({ ...range, from, to, preset });
						}}
					/>
				) : (
					<CalendarPopover
						open={state.calendarOpen}
						setOpen={setCalendarOpen}
						range={local}
						onSelect={(range) => {
							setLocal((state) => {
								const cloned = { ...state, ...range };
								const preset = getDateRangePreset({
									from: cloned.from,
									to: cloned.to,
								});

								const d: DateRange = {
									from: getStartOfDay(cloned.from),
									to: getEndOfDay(cloned.to),
									preset,
								};

								return d;
							});
						}}
					/>
				)}
				<div className="flex flex-col gap-2 p-4">
					<PresetSelect
						preset={local.preset}
						onPresetChanged={onSelectPresetChanged}
					/>
					<DateStaticInput
						date={local.from}
						onClickCalendar={() => setCalendarOpen(true)}
						onChangeInput={() => null}
						label={t('common.from')}
					/>
					<DateStaticInput
						date={local.to}
						onClickCalendar={() => setCalendarOpen(true)}
						onChangeInput={() => null}
						label={t('common.to')}
					/>
				</div>
			</PopoverContent>
		</Popover>
	);
}
