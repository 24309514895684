import {
	PremiseMapContainer,
	PremiseMapControls,
	PremiseMapV5,
	useMapViewFilterSidebar,
	usePremiseStore,
} from '@components';
import { useMapData } from '@hooks';
import { useShallow } from 'zustand/react/shallow';

export function MapView() {
	const {
		filterState: f,
		BadgesList,
		ToggleButton,
		FilterSidebar,
	} = useMapViewFilterSidebar();

	const { nodeFloors, isLoading: dataIsLoading } = useMapData({
		sectionId: f?.sectionId,
		from: f?.from,
		to: f?.to,
	});

	const { isLoading: mapIsLoading, isFullScreen } = usePremiseStore(
		useShallow((state) => ({
			isLoading: state.isLoading,
			isFullScreen: state.isFullScreen,
		})),
	);

	return (
		<>
			<div className="mb-2 flex items-center gap-2">
				<ToggleButton />
				<BadgesList />
			</div>

			<PremiseMapContainer
				isLoading={mapIsLoading || dataIsLoading}
				noFloors={nodeFloors.length <= 0}
				isFullScreen={isFullScreen}
			>
				<PremiseMapV5 nodeFloors={nodeFloors} />
				<PremiseMapControls />
			</PremiseMapContainer>

			<FilterSidebar />
		</>
	);
}
