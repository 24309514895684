import { Shift, ShiftType, UpdateShift, WeekShift } from '@frontend/api';
import { useTranslation } from '@frontend/locale';
import {
	Button,
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuTrigger,
	Input,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
	useToast,
} from '@frontend/ui';
import { formatDate, getWeekdaysArray } from '@frontend/util';
import { Plus } from 'lucide-react';
import {
	ChangeEvent,
	ReactNode,
	memo,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { ButtonWithPopover } from '../ButtonWithConfirm';

const ShiftTypeLabel = ({
	name,
	start,
	end,
}: Pick<ShiftType, 'name' | 'start' | 'end'>) => {
	const startStr = start.slice(0, -3);
	const endStr = end.slice(0, -3);
	return (
		<div className="w-32">
			<p className="font-bold">{name}</p>
			<p className="text-muted-foreground">
				{startStr} - {endStr}
			</p>
		</div>
	);
};

const ShiftTypeDropdown = ({
	types,
	onAddShift,
	uniqueTypes,
}: {
	types: ({ exists?: boolean } & ShiftType)[];
	onAddShift: (type: ShiftType) => void;
	uniqueTypes: string[];
}) => {
	const { t } = useTranslation();
	const dropdownJsx = types?.map((type) => {
		return (
			<DropdownMenuItem
				key={type.id}
				disabled={uniqueTypes.includes(type.id)}
				onClick={() => onAddShift(type)}
				className="flex justify-between"
			>
				<ShiftTypeLabel {...type} />
				<span className="sr-only">Select shift type</span>
			</DropdownMenuItem>
		);
	});

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="default" className="h-7 w-7 p-0">
					<Plus className="text-background h-5 w-5" />
					<span className="sr-only">Add Shift Type</span>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent>
				{!types?.length && (
					<DropdownMenuLabel>{t('titles.no-shift-types')}</DropdownMenuLabel>
				)}
				{dropdownJsx}
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

const WeekdayTitles = () => {
	const weekDays = getWeekdaysArray();

	return (
		<>
			{weekDays.map((day, index) => (
				<TableHead key={index} className="bg-background sticky top-0 w-32">
					{formatDate(day, 'EEEE')}
				</TableHead>
			))}
		</>
	);
};

const ShiftHead = ({
	types,
	onAddShift,
	uniqueTypes,
}: {
	types: ({ exists?: boolean } & ShiftType)[];
	onAddShift: (type: ShiftType) => void;
	uniqueTypes: string[];
}) => {
	const { t } = useTranslation();
	return (
		<>
			<TableHead className="bg-background sticky top-0 w-16 text-center">
				<ShiftTypeDropdown
					uniqueTypes={uniqueTypes}
					types={types}
					onAddShift={onAddShift}
				/>
			</TableHead>
			<TableHead className="bg-background sticky top-0 w-32">
				{t('common.shift', { count: 2 })}
			</TableHead>
			<WeekdayTitles />
		</>
	);
};

const ShiftInput = memo(
	({
		isActive,
		shift,
		onChangeInput,
	}: {
		isActive: boolean;
		shift: Shift;
		onChangeInput: (val: number) => void;
	}) => {
		const inputRef = useRef<HTMLInputElement | null>(null);

		const [val, setVal] = useState<number | string>(shift.employees);

		const onChange = (e: ChangeEvent<HTMLInputElement>) => {
			if (e.target.value === '') {
				return setVal('');
			}
			const newVal = parseInt(e.target.value);

			if (newVal < 0) {
				return setVal(0);
			}
			if (newVal > 999) {
				return setVal(999);
			}

			setVal(newVal);
			onChangeInput(newVal);
		};

		useEffect(() => {
			inputRef.current = null;
		}, [shift]);

		const hasChanged = val !== shift.employees;

		return (
			<TableCell className="w-40 min-w-40">
				<Input
					disabled={!isActive}
					ref={inputRef}
					type="number"
					className={`w-16 ${hasChanged ? 'border-primary border-2' : ''}`}
					value={val}
					onChange={onChange}
				/>
			</TableCell>
		);
	},
);

const ShiftRow = ({
	isActive,
	onClickConfirmDelete,
	children,
}: {
	isActive: boolean;
	onClickConfirmDelete: () => void;
	children: ReactNode | ReactNode[];
}) => {
	const { t } = useTranslation();

	return (
		<TableRow className={`${!isActive && 'line-through hover:bg-transparent'}`}>
			<TableCell>
				{isActive && (
					<ButtonWithPopover onClickYes={onClickConfirmDelete}>
						{t('titles.confirm-delete-shift')}
					</ButtonWithPopover>
				)}
			</TableCell>

			{children}
		</TableRow>
	);
};

const ShiftRows = ({
	weekShifts,
	onChangeInput,
	onClickDelete,
}: {
	weekShifts: WeekShift[];
	onChangeInput: (val: number, typeIndex: number, shiftIndex: number) => void;
	onClickDelete: (shiftTypeId: string) => void;
}) => {
	return (
		<>
			{weekShifts.map((weekShift, typeIndex) => {
				const { shifts, shiftType } = weekShift;
				return (
					<ShiftRow
						isActive={shiftType.isActive}
						onClickConfirmDelete={() => onClickDelete(shiftType.id)}
						key={shiftType.id}
					>
						<TableCell className="bg-background sticky left-0">
							<ShiftTypeLabel {...shiftType} />
						</TableCell>
						{shifts
							.sort(
								(a, b) =>
									new Date(a.date).getTime() - new Date(b.date).getTime(),
							)
							.map((shift, shiftIndex) => {
								return (
									<ShiftInput
										isActive={shiftType.isActive}
										onChangeInput={(val: number) => {
											onChangeInput(val, typeIndex, shiftIndex);
										}}
										key={shift.id}
										shift={shift}
									/>
								);
							})}
					</ShiftRow>
				);
			})}
		</>
	);
};

export function ShiftTable({
	types,
	weekShifts,
	onAddShift,
	onSubmit,
	onDelete,
}: {
	types: ShiftType[];
	weekShifts: WeekShift[];
	onAddShift: (type: ShiftType) => void;
	onSubmit: (shifts: UpdateShift[]) => void;
	onDelete: (shiftTypeId: string) => void;
}) {
	const { t } = useTranslation();
	const { toast } = useToast();

	const changedShifts = useRef<UpdateShift[]>([]);
	const [hasChanged, setHasChanged] = useState(false);

	const uniqueTypes = useMemo(() => {
		return weekShifts.map((w) => w.shiftType.id);
	}, [weekShifts]);

	const onChangeInput = (
		val: number,
		typeIndex: number,
		shiftIndex: number,
	) => {
		const shift = weekShifts[typeIndex].shifts[shiftIndex];
		const prevChanged = [...changedShifts.current];

		const update: UpdateShift = {
			id: shift.id,
			employees: val,
			shiftTypeId: shift.shiftTypeId,
		};

		const exists = prevChanged.find((c) => c.id === shift.id);

		if (exists) {
			const isInitialVal = exists.employees === val;

			// If initial value then remove from changed shifts
			if (isInitialVal) {
				const index = prevChanged.indexOf(exists);
				prevChanged.splice(index, 1);
				changedShifts.current = prevChanged;
				return;
			}

			exists.employees = val;
			changedShifts.current = prevChanged;
			return;
		}

		prevChanged.push(update);
		changedShifts.current = prevChanged;

		if (changedShifts.current.length > 0) {
			setHasChanged(true);
		} else {
			setHasChanged(false);
		}
	};

	const onClickSubmit = () => {
		if (!changedShifts.current.length) {
			return toast({
				title: t('actions.no-change.title'),
				description: t('actions.no-change.desc'),
				variant: 'destructive',
			});
		}

		onSubmit(changedShifts.current);
		changedShifts.current = [];

		setHasChanged(false);
	};

	const onClickDelete = (shiftTypeId: string) => {
		onDelete(shiftTypeId);
	};

	return (
		<>
			<div className="relative h-96 overflow-auto">
				<Table className="absolute w-full table-fixed">
					<TableHeader>
						<TableRow>
							<ShiftHead
								uniqueTypes={uniqueTypes}
								types={types}
								onAddShift={onAddShift}
							/>
						</TableRow>
					</TableHeader>
					<TableBody>
						<ShiftRows
							weekShifts={weekShifts}
							onClickDelete={onClickDelete}
							onChangeInput={onChangeInput}
						/>
						{weekShifts.length === 0 && (
							<TableRow>
								<TableCell colSpan={9}>
									<div className="flex justify-center">
										<p className="text-center">{t('titles.no-shifts')}</p>
									</div>
								</TableCell>
							</TableRow>
						)}
						<TableRow>
							<TableCell colSpan={9}></TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</div>
			<div className="flex justify-end border-t p-4">
				<Button
					disabled={!hasChanged}
					onClick={onClickSubmit}
					variant="default"
				>
					{t('common.submit')}
				</Button>
			</div>
		</>
	);
}
