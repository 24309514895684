import {
	ShiftTable,
	ShiftsFilterType,
	Spinner,
	WeekSelector,
	useShiftFilterSidebar,
} from '@components';
import { ShiftType, UpdateShift, WeekShift, AxiosError } from '@frontend/api';
import {
	useMutDeleteShifts,
	useMutPostShifts,
	useMutPutShifts,
	useQueryGetShiftTypes,
	useQueryGetShifts,
} from '@frontend/context';
import { t } from '@frontend/locale';
import { Card, useToast } from '@frontend/ui';
import { getDateFromWeekYear, getWeekYearFromDate } from '@frontend/util';
import { useEffect, useState } from 'react';

const ShiftTableSection = ({
	selectedDate,
	userGroupId,
	sectionId,
}: ShiftsFilterType) => {
	const { toast } = useToast();
	const [current, setCurrent] = useState<WeekShift[]>([]);

	const { week, year } = getWeekYearFromDate(selectedDate);

	const QUERY_KEY = ['shifts', week, year, sectionId];

	const shifts = useQueryGetShifts({
		year: year,
		week: week,
		sectionIds: [sectionId],
		queryKey: QUERY_KEY,
	});
	const shiftType = useQueryGetShiftTypes({
		sectionIds: [sectionId],
	});

	const post = useMutPostShifts({
		invalidateQueryKey: QUERY_KEY,
		onSuccess: (data) => {
			toast({
				title: t('actions.shift-type-added.title'),
				description: t('actions.shift-type-added.desc', {
					name: data.userGroups[0].shifts[0].shiftType.name,
				}),
				variant: 'default',
			});
		},
	});
	const put = useMutPutShifts({
		invalidateQueryKey: QUERY_KEY,
		onSuccess: () => {
			toast({
				title: t('actions.shift-type-updated.title'),
				description: t('actions.shift-type-updated.desc'),
				variant: 'default',
			});
		},
	});
	const _delete = useMutDeleteShifts({
		week,
		year,
		invalidateQueryKey: QUERY_KEY,
		onSuccess: () => {
			toast({
				title: t('actions.shift-type-deleted.title'),
				description: t('actions.shift-type-deleted.desc'),
			});
		},
	});

	useEffect(() => {
		if (!userGroupId || !shifts.data) return;

		const _current = shifts.data.userGroups.find(
			(x) => x.userGroup.id === userGroupId,
		);

		setCurrent(_current?.shifts.filter((x) => x.shiftType.isActive) || []);

		return () => {
			setCurrent([]);
		};
	}, [userGroupId, shifts.data]);

	const onAddShift = (type: ShiftType) => {
		if (!userGroupId) return;
		post.mutate({
			year: year,
			week: week,
			shifts: [
				{
					userGroupId: userGroupId,
					shiftTypeId: type.id,
				},
			],
		});
	};

	const onSubmit = (shifts: UpdateShift[]) => {
		put.mutate(shifts);
	};

	const onDelete = (shiftTypeId: string) => {
		if (!userGroupId) {
			return console.error('No user group selected');
		}
		_delete.mutate({
			shifts: [
				{
					shiftTypeId,
					userGroupId,
				},
			],
		});
	};

	const error = post.error || put.error || _delete.error || shifts.error;

	if (error && error instanceof AxiosError) {
		if (error.response?.status === 404) {
			return (
				<ShiftTable
					types={shiftType.data?.items || []}
					weekShifts={[]}
					onSubmit={onSubmit}
					onAddShift={onAddShift}
					onDelete={onDelete}
				/>
			);
		}
	}

	const isLoading = shifts.isLoading || shiftType.isLoading;

	return (
		<>
			{isLoading && (
				<div className="p-24 flex justify-center">
					<Spinner className="h-20 w-20" />
				</div>
			)}

			{!isLoading && (
				<ShiftTable
					types={shiftType.data?.items || []}
					weekShifts={current}
					onSubmit={onSubmit}
					onAddShift={onAddShift}
					onDelete={onDelete}
				/>
			)}
		</>
	);
};

export function ShiftCard() {
	const {
		filterState: f,
		FilterSidebar,
		ToggleButton,
		applyFilter,
		BadgesList,
	} = useShiftFilterSidebar();

	useEffect(() => {
		// needed to re render
	}, [f]);

	const selectedDate = f?.selectedDate;
	const selectedWeek = selectedDate
		? getWeekYearFromDate(selectedDate)
		: undefined;

	return (
		<>
			<div className="mb-2 flex items-start gap-2">
				<ToggleButton />
				<BadgesList />
			</div>
			<Card className="flex flex-col">
				{f?.sectionId && f?.userGroupId && (
					<>
						<div className="flex border-b p-2">
							<WeekSelector
								week={selectedWeek?.week || 0}
								year={selectedWeek?.year || 0}
								onClick={(week, year) => {
									applyFilter({
										selectedDate: getDateFromWeekYear({ week, year }),
									});
								}}
							/>
						</div>

						<ShiftTableSection {...f} />
					</>
				)}
			</Card>
			<FilterSidebar />
		</>
	);
}
