import {
	ColumnDef,
	PaginationState,
	Row,
	SortingState,
	getCoreRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';

import { CardView, Pagination, TableView, ViewSwitcher } from '@components';
import { useURLParams } from '@frontend/hook';
import { cn } from '@frontend/util';
import { useEffect, useMemo, useState } from 'react';

interface DataTableProps<TData, TValue> {
	columns: ColumnDef<TData, TValue>[];
	data: TData[];
	pageCount: number | undefined;
	defaultPageSize?: number;
	onChangePagination?: (pageIndex: number, pageSize: number) => void;
	onChangeSorting?: (sorting: SortingState) => void;
	isLoading?: boolean;
	totalItems?: number;
	navChildren?: React.ReactNode;
	cardViewTemplate?: React.FC<{ row: Row<TData> }>;
}

export function DataTable<TData, TValue>({
	columns,
	data,
	pageCount,
	defaultPageSize = 5,
	onChangePagination,
	onChangeSorting,
	isLoading = false,
	totalItems,
	navChildren,
	cardViewTemplate,
}: DataTableProps<TData, TValue>) {
	const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: defaultPageSize,
	});
	const pagination = useMemo(
		() => ({
			pageIndex,
			pageSize,
		}),
		[pageIndex, pageSize],
	);

	const { getURLParam, setURLParam } = useURLParams<{
		viewType: 'table' | 'card';
	}>({
		viewType: 'table',
	});

	const [view, setView] = useState<'table' | 'card'>(
		getURLParam(['viewType']).viewType,
	);

	const [sorting, setSorting] = useState<SortingState>([]);

	const table = useReactTable({
		data,
		columns,
		pageCount: pageCount,
		state: {
			pagination,
			sorting,
		},
		onPaginationChange: (pagination) => {
			setPagination(pagination);
		},
		onSortingChange: setSorting,
		getSortedRowModel: getSortedRowModel(),
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		manualPagination: true,
		manualSorting: true,
	});

	useEffect(() => {
		onChangePagination && onChangePagination(pageIndex, pageSize);
	}, [pageIndex, pageSize]);

	useEffect(() => {
		onChangeSorting && onChangeSorting(sorting);
	}, [sorting]);

	return (
		<div className={cn('flex h-full w-full flex-col')}>
			<div className="flex gap-2 pb-2">
				<ViewSwitcher
					state={view}
					setState={(view) => {
						setView(view);
						setURLParam('viewType', view);
					}}
				/>
				{navChildren}
			</div>

			<div className="flex h-full w-full flex-col overflow-hidden rounded-lg border">
				{view === 'card' && (
					<CardView
						table={table}
						template={cardViewTemplate}
						isLoading={isLoading}
					/>
				)}
				{view === 'table' && (
					<TableView
						table={table}
						columnLength={columns.length}
						isLoading={isLoading}
					/>
				)}

				<div className="flex items-center justify-center border-t p-2">
					<Pagination table={table} totalItems={totalItems || 0} />
				</div>
			</div>
		</div>
	);
}
