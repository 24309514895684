type VersionProps = {
	version: string;
};

export function Version({ version }: VersionProps) {
	return (
		<p className="text-xs text-muted-foreground absolute bottom-4 left-4 z-30">
			{version}
		</p>
	);
}
