import { useQuery } from '@tanstack/react-query';

import { APIClient, InferRequestParams } from '@frontend/api';
import { parseToUTC } from '@frontend/util';

type RequestParams = InferRequestParams<'/v1/usage', 'get'>['query'];

const getData = async (params: RequestParams) =>
	await APIClient.request('/v1/usage', 'get', {
		params: {
			query: params,
		},
	});

type QueryProps = {
	sectionIds: string[];
	from?: Date;
	to?: Date;
	roomTypeIds: string[];
	roomIds: string[];
};

export const useQueryGetUsageInPeriod = ({
	sectionIds,
	from,
	to,
	roomTypeIds,
	roomIds,
}: QueryProps) => {
	const fromUTC = from && parseToUTC(from).toISOString();
	const toUTC = to && parseToUTC(to).toISOString();

	return useQuery({
		queryKey: [sectionIds, from, to, roomTypeIds, roomIds],
		queryFn: () =>
			getData({
				'section-id': sectionIds,
				start: fromUTC,
				end: toUTC,
				'room-type-id': roomTypeIds,
				'room-id': roomIds,
			}),
		enabled: !!from && !!to && !!sectionIds.length,
	});
};
