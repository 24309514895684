import { ThemeColorKey, getThemeColor } from '@frontend/ui';
import { Color, Text } from 'pixi.js';

type FontSizeKey =
	| 'xs'
	| 'sm'
	| 'md'
	| 'lg'
	| 'xl'
	| '2xl'
	| '3xl'
	| '4xl'
	| '5xl'
	| '6xl'
	| '7xl'
	| '8xl'
	| '9xl';
type FontSizeRecord = Record<FontSizeKey, number>;
const sizes: FontSizeKey[] = [
	'xs',
	'sm',
	'md',
	'lg',
	'xl',
	'2xl',
	'3xl',
	'4xl',
	'5xl',
	'6xl',
	'7xl',
	'8xl',
	'9xl',
];

function createSizes(base = 15) {
	let prev = base;

	const obj: Partial<FontSizeRecord> = {};

	for (const s of sizes) {
		obj[s] = prev;
		prev = prev / 3 + prev;
	}

	return obj as FontSizeRecord;
}

const fontSizeMap: FontSizeRecord = createSizes();

type PremiseTextProps = {
	text: string;
	fontSize?: FontSizeKey;
	fontColor?: ThemeColorKey;
	fontWeight?: Text['style']['fontWeight'];
};

export default class PremiseText extends Text {
	public isCullable = true;

	constructor({
		text,
		fontSize = 'sm',
		fontColor = 'foreground',
		fontWeight,
	}: PremiseTextProps) {
		super({ text });

		this.style = {
			fontSize: fontSizeMap[fontSize],
			fill: new Color(getThemeColor(fontColor)),
			fontWeight: fontWeight || this.style.fontWeight,
			align: 'center',
		};

		this.scale.set(0.75);

		this.eventMode = 'none';
	}
}
