import { Calendar as CalendarIcon } from 'lucide-react';

import { useTranslation } from '@frontend/locale';
import { Button, Calendar, Popover, PopoverContent, PopoverTrigger } from '#ui';
import { cn, formatDate } from '@frontend/util';
import { Dispatch, SetStateAction, useState } from 'react';

type DatePickerBasicProps = {
	date?: Date;
	setDate: Dispatch<SetStateAction<Date | undefined>>;
};

export function DatePickerBasic({ date, setDate }: DatePickerBasicProps) {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<Button
					variant={'outline'}
					className={cn(
						'w-full justify-start text-left font-normal',
						!date && 'text-muted-foreground',
					)}
				>
					<CalendarIcon className="mr-2 h-4 w-4" />
					{date ? (
						formatDate(date, 'PPP')
					) : (
						<span>{t('inputs.pick-a-date')}</span>
					)}
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-auto p-0">
				<Calendar
					mode="single"
					selected={date}
					onSelect={setDate}
					onDayClick={() => setOpen(false)}
					autoFocus
				/>
			</PopoverContent>
		</Popover>
	);
}
