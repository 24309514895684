import { Button, HumanDateRangeString, PopoverTrigger } from '#ui';
import { cn, DateRange } from '@frontend/util';
import { CalendarDays } from 'lucide-react';

type RangeToggleButtonProps = {
	range: DateRange;
	onClick: () => void;
};

export function RangeTriggerButton({ range, onClick }: RangeToggleButtonProps) {
	return (
		<PopoverTrigger asChild>
			<Button
				onClick={onClick}
				id="date"
				variant={'outline'}
				className={cn(
					'text-muted-foreground h-auto justify-between text-left flex gap-2 px-2',
				)}
			>
				<CalendarDays className="h-5 w-5" />
				<HumanDateRangeString range={range} />
			</Button>
		</PopoverTrigger>
	);
}
