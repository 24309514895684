import { useTranslation } from '@frontend/locale';
import { Button } from '@frontend/ui';
import { SlidersHorizontal } from 'lucide-react';
import { FilterSidebarBaseProps, FilterSidebarRecord } from '../store';

type BaseToggleButtonProps<T extends FilterSidebarRecord> =
	FilterSidebarBaseProps<T, Record<string, unknown>>;

export const BaseToggleButton = <T extends FilterSidebarRecord>({
	useStore,
}: BaseToggleButtonProps<T>) => {
	const { t } = useTranslation();
	const { collapsed, setCollapsed } = useStore();
	return (
		<Button
			title={t('titles.open-filter-sidebar')}
			onClick={() => {
				setCollapsed(!collapsed);
			}}
			variant="outline"
			className="p-2"
		>
			<SlidersHorizontal className="h-6 w-6" />
		</Button>
	);
};
