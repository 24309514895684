import { Button, buttonVariants, Label } from '@frontend/ui';
import { cn } from '@frontend/util';
import { ReactNode } from 'react';

type RadioButtonGroupProps = {
	label?: string;
	children: ReactNode | ReactNode[];
	className?: string;
};

export const RadioButtonGroup = ({
	label,
	children,
	className,
}: RadioButtonGroupProps) => {
	return (
		<div className="flex flex-col gap-2">
			{label && <Label>{label}</Label>}
			<div>
				<div
					className={cn(
						buttonVariants({ variant: 'outline' }),
						'max-w-[250px] overflow-hidden p-0 hover:bg-transparent',
						className,
					)}
				>
					{children}
				</div>
			</div>
		</div>
	);
};

export type RadioButtonItem = {
	label: string;
	value: string;
};

type RadioButtonProps = RadioButtonItem & {
	active?: boolean;
	onClick?: (val: string | undefined) => void;
	disabled?: boolean;
	className?: string;
};

export const RadioButton = ({
	label,
	value,
	active,
	onClick,
	disabled,
	className,
}: RadioButtonProps) => {
	return (
		<div className="border-r">
			<Button
				variant={active ? 'default' : 'outline'}
				disabled={disabled}
				onClick={() => onClick && onClick(value)}
				className={cn('rounded-none border-none', className)}
			>
				{label}
			</Button>
		</div>
	);
};
