import {
	BaseLineChart,
	ChartLabel,
	ChartSerie,
	ChartTooltipDateRange,
	getClosestIndex,
	mapTimestampToChartPosition,
} from '@components';
import { Transaction } from '@frontend/api';
import { getCurrDateReadable, getPrevDateReadable } from '@frontend/locale';
import {
	DateRangeWithPrev,
	dateFormats,
	getFormatForRange,
	parseFromUTC,
	isDateValid,
	formatDate,
} from '@frontend/util';
import { useEffect, useState } from 'react';
import { genEmptyChartData } from './charts';

type DosagesLineChartProps = DateRangeWithPrev & {
	prevTransactions: Transaction[];
	currTransactions: Transaction[];
};

export function DosagesLineChart({
	prevTransactions,
	currTransactions,
	from,
	to,
	pFrom,
	pTo,
	preset,
}: DosagesLineChartProps) {
	const [chartLabels, setChartLabels] = useState<ChartLabel[]>([]);
	const [chartSeries, setChartSeries] = useState<ChartSerie[]>([]);

	const chartKeys: string[] = [];

	if (preset !== 'custom') {
		chartKeys.push(getPrevDateReadable(preset));
		chartKeys.push(getCurrDateReadable(preset));
	} else {
		const pf = getFormatForRange({ from: pFrom, to: pTo, preset });
		const ct = getFormatForRange({ from: from, to: to, preset });
		chartKeys.push(`${pf[0]} - ${pf[1]}`);
		chartKeys.push(`${ct[0]} - ${ct[1]}`);
	}

	function processTrans(
		trans: Transaction[] | undefined,
		from: Date,
		to: Date,
		newSeries: ChartSerie[],
		labelsLength: number,
		chartKeyIndex: number,
	) {
		if (!trans?.length) return;

		trans.forEach((item) => {
			const { timestamp } = item;

			const parsedTs = parseFromUTC(timestamp);

			const position = mapTimestampToChartPosition(
				parsedTs.getTime(),
				from.getTime(),
				to.getTime(),
				500,
			);

			const closestIndex = getClosestIndex(position, 500, labelsLength);

			const serie = newSeries[closestIndex];

			if (serie) {
				serie[chartKeys[chartKeyIndex]]++;
			}
		});
	}

	useEffect(() => {
		if (!currTransactions && !prevTransactions) return;
		if (!from || !to || !pFrom || !pTo) return;

		const { series, labels } = genEmptyChartData({
			from,
			to,
			preset,
			keys: chartKeys,
		});

		processTrans(prevTransactions, pFrom, pTo, series, labels.length, 0);
		processTrans(currTransactions, from, to, series, labels.length, 1);

		setChartLabels(labels);
		setChartSeries(series);
	}, [prevTransactions, currTransactions, from, to, pFrom, pTo]);

	const DATA_KEY = 'DOSAGES_DATE';

	return (
		<>
			<BaseLineChart
				labels={chartLabels || []}
				series={chartSeries || []}
				dataKey={DATA_KEY}
				hasLegend={true}
				chartKeys={chartKeys}
				xAxis={{
					minTickGap: 100,
					tickFormatter: (value) => {
						const isDate = isDateValid(value);
						if (isDate)
							return formatDate(new Date(value), dateFormats[preset].short);
						return value;
					},
				}}
				tooltipContent={
					<ChartTooltipDateRange
						from={from}
						to={to}
						preset={preset}
						dataKey={DATA_KEY}
					/>
				}
			/>
		</>
	);
}
