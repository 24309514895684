import { ShiftTypeTable } from '@components';
import { SelectSection } from '@frontend/global';
import { Card } from '@frontend/ui';
import { useState } from 'react';

export function ShiftTypeCard() {
	const [sectionId, setSectionId] = useState<string>('');

	return (
		<Card className="lg:inline-block">
			<div className="flex items-center p-2">
				<SelectSection
					className="min-w-44 max-w-44"
					value={sectionId}
					selectFirst={true}
					onChange={(val) => setSectionId(val.id)}
				/>
			</div>
			{sectionId !== '' && <ShiftTypeTable sectionIds={[sectionId]} />}
		</Card>
	);
}
