import { usePremise } from '@frontend/context';
import { useURLParams } from '@frontend/hook';
import { getCurrDateReadable, useTranslation } from '@frontend/locale';
import {
	DateRangeWithPrev,
	getDateRange,
	getFormatForRange,
	getPrevDateRange,
} from '@frontend/util';
import { useState } from 'react';
import { useFilterSidebar } from '../FilterSidebar';

export type DashboardFilterType = DateRangeWithPrev & {
	sectionIds: string[];
	roomTypeId?: string;
	range?: DateRangeWithPrev;
};

export const useDashboardFilterSidebar = () => {
	const { t } = useTranslation();
	const { sections, roomTypes } = usePremise();
	const sectionOpts = Object.values(sections).map((section) => ({
		label: section.name,
		value: section.id,
	}));

	const range = getDateRange({ from: new Date(), preset: 'day' });
	const pRange = getPrevDateRange(range);

	const { setURLParam, getURLParam, defaultValues } = useURLParams<
		Omit<DashboardFilterType, 'range' | 'pRange'>
	>({
		sectionIds: [sectionOpts[0]?.value],
		preset: 'day',
		from: range.from,
		to: range.to,
		pFrom: pRange.from,
		pTo: pRange.to,
	});

	const urlParams = getURLParam([
		'sectionIds',
		'roomTypeId',
		'preset',
		'from',
		'to',
		'pFrom',
		'pTo',
	]);

	const [filterState, setFilterState] =
		useState<Omit<DashboardFilterType, 'range' | 'pRange'>>(urlParams);

	const {
		useStore,
		Sidebar,
		SelectMulti,
		DatePickerRange,
		ToggleButton,
		Badges,
		Combobox,
	} = useFilterSidebar<DashboardFilterType>(
		{
			...urlParams,
			range: {
				from: urlParams.from,
				to: urlParams.to,
				preset: urlParams.preset,
				pFrom: urlParams.pFrom,
				pTo: urlParams.pTo,
			},
		},
		{
			...defaultValues,
			range: {
				from: defaultValues.from,
				to: defaultValues.to,
				preset: defaultValues.preset,
				pFrom: defaultValues.pFrom,
				pTo: defaultValues.pTo,
			},
		},
	);

	const applyFilter = (filters: Partial<DashboardFilterType>) => {
		const obj: DashboardFilterType = {
			...urlParams,
			...filters,
			from: filters.range?.from || urlParams.from,
			to: filters.range?.to || urlParams.to,
			pFrom: filters.range?.pFrom || urlParams.pFrom,
			pTo: filters.range?.pTo || urlParams.pTo,
			preset: filters.preset || 'custom',
		};

		const keys = Object.keys(obj) as (keyof DashboardFilterType)[];

		keys.forEach((key) => {
			if (key === 'range') return;
			const val = obj[key];
			setURLParam(key, val);
		});

		setFilterState(obj);
	};

	const BadDatePickerRange = () => {
		const store = useStore();
		return (
			<DatePickerRange
				onSelect={(val) => {
					const rng = getPrevDateRange(val);

					store.setFilterValue('range', {
						...val,
						pFrom: rng.from,
						pTo: rng.to,
					});
					store.setFilterValue('preset', val.preset);
				}}
				name="range"
				label={t('inputs.select-date-range')}
			/>
		);
	};

	const FilterSidebar = () => (
		<Sidebar onApply={(vals) => applyFilter(vals)}>
			<SelectMulti
				name="sectionIds"
				label={t('inputs.select-sections')}
				opts={sectionOpts}
			/>
			<BadDatePickerRange />
			{/*
			<DatePickerRange name="pRange" label={t('inputs.select-date-range')} />
					*/}
			<Combobox
				opts={roomTypes.map((type) => ({ value: type.id, label: type.name }))}
				name="roomTypeId"
				label={t('titles.room-type')}
			/>
		</Sidebar>
	);

	const BadgesList = () => (
		<Badges
			badges={{
				sectionIds: {
					label: t('common.department'),
					value: () => {
						const filtered = sectionOpts
							.filter((x) => urlParams.sectionIds.includes(x.value))
							.map((x) => x.label);

						return filtered.join(',');
					},
					preventOnBadgeRemove: true,
				},
				range: {
					value: () => {
						const { from, to, preset } = filterState || urlParams;

						if (preset !== undefined && preset !== 'custom')
							return getCurrDateReadable(preset);

						const [sf, st] = getFormatForRange({ from, to, preset });

						return `${sf} - ${st}`;
					},
					preventOnBadgeRemove: true,
				},
				roomTypeId: {
					label: t('titles.room-type'),
					value: roomTypes.find((x) => x.id === urlParams.roomTypeId)?.name,
				},
			}}
		/>
	);

	return {
		ToggleButton,
		FilterSidebar,
		applyFilter,
		filterState,
		BadgesList,
	};
};
