export * from './useInitAuth';
export * from './useAuth';

export type Roles = 'ComplianceOfficer' | 'HealthWorker' | 'Maintenance';

export const VALID_ROLES: Roles[] = [
	'ComplianceOfficer',
	'HealthWorker',
	'Maintenance',
];
