import { DatePickerRange } from '@frontend/ui';
import { Label } from '@frontend/ui';
import { DateRange } from '@frontend/util';
import { FilterSidebarBaseProps, FilterSidebarRecord } from '../store';

type BaseDatePickerRangeProps<T extends FilterSidebarRecord> =
	FilterSidebarBaseProps<
		T,
		{
			name: keyof T;
			label: string;
			onSelect?: (value: DateRange) => void;
		}
	>;

export const BaseDatePickerRange = <T extends FilterSidebarRecord>({
	name,
	label,
	useStore,
	onSelect,
}: BaseDatePickerRangeProps<T>) => {
	const { filterValues, setFilterValue } = useStore();

	// TODO: Can we make this more type-safe?
	const selected = filterValues[name] as DateRange;

	return (
		<div className="flex flex-col gap-2">
			<Label>{label}</Label>
			{selected && (
				<DatePickerRange
					range={selected}
					key={selected.from.toString() + selected.to?.toString()}
					setRange={({ from, to, preset }) => {
						if (!to) return;

						setFilterValue(name, {
							from,
							to,
							preset,
						} as T[keyof T]);

						onSelect && onSelect({ from, to, preset });
					}}
				/>
			)}
		</div>
	);
};
