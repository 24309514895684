import { FloorNode, MapNode } from '@components';
import {
	useQueryGetSectionMap,
	useQueryGetUsageInPeriod,
} from '@frontend/context';
import { useEffect, useState } from 'react';
//import { flipCoin, generateRandomNumber } from '@frontend/util';

type UseMapDataProps = {
	from?: Date;
	to?: Date;
	sectionId?: string;
};

type Floor = NonNullable<
	ReturnType<typeof useQueryGetSectionMap>['data']
>['premises'][0]['floors'][0];

type TransactionCountMap = Record<string, number>;

const premiseToFloorNodes = (
	floors: Floor[],
	transMap: TransactionCountMap,
): FloorNode[] => {
	const floorArray: FloorNode[] = [];

	for (const f of floors) {
		const ns: MapNode[] = [];
		// Static Layers
		ns.push({
			name: 'STATIC',
			id: f.id,
			layers: f.layers,
		});

		let totalCount = 0;

		// Zones
		for (const r of f.rooms) {
			const count = transMap[r.id];
			//const count = flipCoin() ? generateRandomNumber(0, 50) : 0;
			const hasDevice = count > 0;
			totalCount += count || 0;

			ns.push({
				name: r.name,
				id: r.id,
				transCount: count,
				typeId: r.type.id,
				layers: r.layers,
				hasDevice,
			});
		}

		if (f.layers.length > 0)
			floorArray.push({
				nodes: ns,
				name: f.name,
				number: f.number,
				id: f.id,
				totalCount,
			});
	}

	return floorArray;
};

const mapRoomIdTransCounts = (
	data: ReturnType<typeof useQueryGetUsageInPeriod>['data'],
): TransactionCountMap => {
	if (!data) return {};

	const { transactions } = data;

	if (!transactions) return {};

	const mapped: TransactionCountMap = {};

	for (const t of transactions) {
		if (!mapped[t.roomId]) {
			mapped[t.roomId] = 1;
		} else {
			mapped[t.roomId] += 1;
		}
	}

	return mapped;
};

export const useMapData = ({ from, to, sectionId }: UseMapDataProps) => {
	const [nodeFloors, setNodeFloors] = useState<FloorNode[]>([]);

	const map = useQueryGetSectionMap(sectionId);
	const usage = useQueryGetUsageInPeriod({
		from,
		to,
		sectionIds: sectionId ? [sectionId] : [],
		roomIds: [],
		roomTypeIds: [],
	});

	const isLoading = map.isLoading || usage.isLoading;

	useEffect(() => {
		if (!map.data) return;

		const premise = map.data.premises[0];

		if (!premise) {
			return console.warn('no premises found, wont render map');
		}

		const transMap = mapRoomIdTransCounts(usage.data);
		const floors = premiseToFloorNodes(premise.floors, transMap);
		setNodeFloors(floors.sort((a, b) => b.number - a.number));

		return () => {
			setNodeFloors([]);
		};
	}, [map.data, usage.data]);

	return {
		isLoading,
		nodeFloors,
	};
};
