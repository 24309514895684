import { Badge } from '#ui';
import { cn } from '@frontend/util';
import { ImagePlus, X } from 'lucide-react';
import { InputHTMLAttributes, forwardRef, useRef, useState } from 'react';

export type InputProps = InputHTMLAttributes<HTMLInputElement>;

const Input = forwardRef<HTMLInputElement, InputProps>(
	({ className, type, ...props }, ref) => {
		return (
			<input
				type={type}
				className={cn(
					'border-input bg-background placeholder:text-muted-foreground focus-visible:ring-ring flex h-9 w-full rounded-md border px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50',
					className,
				)}
				ref={ref}
				{...props}
			/>
		);
	},
);
Input.displayName = 'Input';

const InputImg = ({
	label,
	className,
	onChange,
}: {
	label: string;
	className?: string;
	onChange: (images: File) => void;
}) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const [images, setImages] = useState<File[]>([]);

	const handleClick = () => {
		inputRef.current?.click();
	};

	const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		const files = e.target.files;

		if (files && files.length > 0) {
			const filesArray = Array.from(files);

			setImages((prev) => [...prev, ...filesArray]);
			onChange(filesArray[0]);
		}
	};

	return (
		<>
			<button
				type="button"
				onClick={handleClick}
				className={cn(
					'hover:bg-secondary  flex w-full cursor-pointer select-none flex-col items-center rounded border-2 border-dashed p-4',
					className,
				)}
			>
				<ImagePlus className="stroke-border h-20 w-20" />
				<span className="text-muted-foreground text-center">{label}</span>
			</button>
			<div className="mt-2 flex flex-col gap-2">
				{images.map(({ name }, _i) => (
					<div key={`${name}-${_i}`}>
						<Badge variant="secondary">
							<button
								type="button"
								onClick={() => {
									setImages((prev) =>
										prev.filter((image) => image.name !== name),
									);
								}}
							>
								<X className="mr-2 h-4 w-4 cursor-pointer" />
							</button>
							<span className="break-all">{name}</span>
						</Badge>
					</div>
				))}
			</div>
			<input
				onChange={handleInput}
				type="file"
				ref={inputRef}
				accept="image/*"
				className="hidden"
				multiple={true}
			/>
		</>
	);
};

export { Input, InputImg };
