import { APIClient, CreateShiftType, ShiftType } from '@frontend/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

type UseMutPostShiftTypeProps = {
	invalidateQueryKey?: unknown[];
	onSuccess?: (data: ShiftType) => void;
};

export const useMutPostShiftType = ({
	invalidateQueryKey,
	onSuccess,
}: UseMutPostShiftTypeProps) => {
	const queryClient = useQueryClient();

	const postData = async (data: CreateShiftType) => {
		data.start = data.start + ':00';
		data.end = data.end + ':00';
		return await APIClient.request('/v1/shift-types', 'post', { body: data });
	};

	return useMutation({
		mutationKey: ['postShiftType'],
		mutationFn: postData,
		onSuccess: (data) => {
			if (invalidateQueryKey)
				queryClient.invalidateQueries({
					queryKey: invalidateQueryKey,
				});
			onSuccess && onSuccess(data);
		},
	});
};
