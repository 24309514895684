import { differenceInDays } from 'date-fns/differenceInDays';
import { differenceInSeconds } from 'date-fns/differenceInSeconds';
import { DateRange } from './types';
import { dateAdd } from '.';

export default function ({
	from,
	to,
	columns = 24,
}: Omit<DateRange, 'preset'> & { columns?: number }): Date[] {
	const totalDays = differenceInDays(to, from) + 1;
	const maxDate = dateAdd(from, { days: totalDays });

	let totalSeconds = differenceInSeconds(maxDate, from);
	const secondsToAdd = totalSeconds / columns;

	let incrementDate = from;
	const dates: Date[] = [];

	while (totalSeconds > 0) {
		dates.push(incrementDate);

		incrementDate = dateAdd(incrementDate, { seconds: secondsToAdd });
		totalSeconds -= secondsToAdd;
	}

	return dates;
}
