import { cn } from '@frontend/util';
import { Minus, TrendingDown, TrendingUp } from 'lucide-react';

type CardTrendSectionProps = {
	startingValue: number;
	finalValue: number;
};

const calcPct = (startingValue: number, finalValue: number) => {
	const pct = ((startingValue - finalValue) / startingValue) * 100;
	if (isNaN(pct)) return 0;
	return pct;
};

export const CardTrendSectionMain = ({
	startingValue,
	finalValue,
}: CardTrendSectionProps) => {
	const pct = startingValue === 0 ? 0 : calcPct(startingValue, finalValue);

	let Icon = Minus;
	let className = 'text-muted-foreground';

	if (pct < 0) {
		Icon = TrendingUp;
		className = 'text-green-500';
	} else if (pct > 0) {
		Icon = TrendingDown;
		className = 'text-red-500';
	}

	return (
		<div className="flex items-center justify-between  text-2xl">
			<div className="font-bold">
				<span className="mr-1">{finalValue}</span>
				<span className="text-muted-foreground text-base">
					({startingValue})
				</span>
			</div>
			<div>
				<span className={cn('flex items-center', className)}>
					<Icon className="mr-2" />
					{Math.abs(Math.round(pct))}%
				</span>
			</div>
		</div>
	);
};

type CardTrendSectionSubProps = {
	title: string;
	mainValue: number;
	compareToValue: number;
};

export const CardTrendSecctionSub = ({
	title,
	mainValue,
	compareToValue,
}: CardTrendSectionSubProps) => {
	return (
		<div className="flex items-center justify-between">
			<span className="mb-2 mr-1 font-bold">{title}</span>
			<div className="flex">
				<span className="mr-1">{mainValue}</span>
				<span className="text-muted-foreground mr-1 text-sm">
					({Math.round(compareToValue)})
				</span>
			</div>
		</div>
	);
};
