import { differenceInDays } from 'date-fns/differenceInDays';
import { differenceInMonths } from 'date-fns/differenceInMonths';
import { differenceInWeeks } from 'date-fns/differenceInWeeks';
import { differenceInYears } from 'date-fns/differenceInYears';
import { isLastDayOfMonth } from 'date-fns/isLastDayOfMonth';
import { isThisMonth } from 'date-fns/isThisMonth';
import { isThisWeek } from 'date-fns/isThisWeek';
import { isThisYear } from 'date-fns/isThisYear';
import { isToday } from 'date-fns/isToday';
import { DateRange, DateRangePreset } from './';

export default function ({
	from,
	to,
}: Omit<DateRange, 'preset'>): DateRangePreset {
	if (differenceInDays(to, from) === 0 && isToday(from) && isToday(to))
		return 'day';

	if (differenceInWeeks(to, from) === 0 && isThisWeek(from) && isThisWeek(to))
		return 'week';

	if (
		differenceInMonths(to, from) === 0 &&
		isThisMonth(from) &&
		isLastDayOfMonth(to)
	)
		return 'month';

	if (
		differenceInYears(to, from) === 0 &&
		isThisYear(from) &&
		isLastDayOfMonth(to)
	)
		return 'year';

	return 'custom';
}
