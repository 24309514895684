import { memo } from 'react';

import { cn } from '@frontend/util';
import { ResaniLogo, useScreenLoader } from '@frontend/ui';
import './ScreenLoader.css';

type SpinnerProps = {
	className?: string;
};

export function Spinner({ className }: SpinnerProps) {
	return <div className={cn('loader', className)} />;
}

export const ScreenLoader = memo(() => {
	const { active } = useScreenLoader();

	if (!active) return null;

	return (
		<div
			className={`bg-background absolute z-50 flex h-screen w-screen items-center justify-center`}
		>
			<ResaniLogo className="h-10 absolute top-4 left-4 border-foreground fill-foreground" />
			<div className="flex flex-col gap-4 justify-center items-center">
				<Spinner />
				<p className="font-bold">{active.title}</p>
			</div>
		</div>
	);
});
