import { ThemeColorKey, ThemeScreenKey } from '#ui';
import { getStylePropertyValue } from '@frontend/util';
import { screens } from 'tailwindcss/defaultTheme';

export function getThemeColorValue(color: ThemeColorKey): string {
	return getStylePropertyValue(`--${String(color)}`);
}

export function getThemeColor(color: ThemeColorKey): string {
	return `hsl(${getThemeColorValue(color)})`;
}

export const getScreenNum = (scr: ThemeScreenKey) => {
	const screen = screens[scr];

	// all css units
	const units = ['px', 'em', 'rem', 'vw', 'vh', 'vmin', 'vmax', '%'];

	// remove units from screen sizes
	const size = screen.replace(new RegExp(units.join('|'), 'g'), '');

	return Number(size);
};

export const getScreenSize = () => {
	const screenKeys = Object.keys(screens) as Array<ThemeScreenKey>;

	// Sort screenKeys based on their numeric values in descending order
	screenKeys.sort((a, b) => getScreenNum(b) - getScreenNum(a));

	const innerWidth = window.innerWidth;

	for (const scr of screenKeys) {
		const scrNum = getScreenNum(scr);

		if (innerWidth >= scrNum) {
			return scr;
		}
	}

	// if no screen size is found, return the smallest screen size
	return screenKeys[screenKeys.length - 1];
};
