import { APIClient, Operators, SortBy, SortDirection } from '@frontend/api';
import { parseToUTC } from '@frontend/util';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

export const useQueryGetDevices = ({
	sectionIds,
	pageSize,
	pageIndex,
	isOn,
	isBattery,
	fluidVal,
	fluidOp,
	batteryVal,
	batteryOp,
	batteryExpDate,
	batteryExpOp,
	batteryChgDate,
	batteryChgOp,
	sortBy,
	sortDirection,
	searchTerm,
}: {
	sectionIds: string[];
	pageSize: number;
	pageIndex: number;
	isOn?: boolean;
	isBattery?: boolean;
	fluidVal?: number;
	fluidOp?: Operators;
	batteryVal?: number;
	batteryOp?: Operators;
	batteryExpDate?: Date;
	batteryExpOp?: Operators;
	batteryChgDate?: Date;
	batteryChgOp?: Operators;
	sortBy?: SortBy;
	sortDirection?: SortDirection;
	searchTerm?: string;
}) => {
	const getData = async () => {
		return APIClient.request('/v1/devices', 'get', {
			params: {
				query: {
					'search.term': searchTerm,
					'section-id': sectionIds,
					'status.is-on': isOn,
					'status.runs-on-battery': isBattery,
					'fluid.value': fluidVal,
					'fluid.comparer': fluidOp,
					'battery.value': batteryVal,
					'battery.comparer': batteryOp,
					'battery.expected-empty.timestamp':
						batteryExpDate && parseToUTC(batteryExpDate).toISOString(),
					'battery.expected-empty.comparer': batteryExpOp,
					'battery.last-changed.timestamp':
						batteryChgDate && parseToUTC(batteryChgDate).toISOString(),
					'battery.last-changed.comparer': batteryChgOp,
					PageSize: pageSize,
					Page: pageIndex + 1, // db starts at 1
					'sort.by': sortBy,
					'sort.direction': sortDirection,
				},
			},
		});
	};

	// TODO: Maybe not cache this and refetch on every change?
	return useQuery({
		queryKey: [
			'devices',
			pageIndex,
			pageSize,
			sectionIds,
			isOn,
			isBattery,
			fluidVal,
			fluidOp,
			batteryVal,
			batteryOp,
			batteryExpDate,
			batteryExpOp,
			batteryChgDate,
			batteryChgOp,
			sortBy,
			sortDirection,
			searchTerm,
		],
		enabled: sectionIds.length > 0,
		queryFn: getData,
		placeholderData: keepPreviousData,
	});
};
