import { APIClient, DeleteShifts } from '@frontend/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

type UseMutDeleteShiftsProps = {
	week: number;
	year: number;
	invalidateQueryKey?: unknown[];
	onSuccess?: () => void;
};

export const useMutDeleteShifts = ({
	week,
	year,
	invalidateQueryKey,
	onSuccess,
}: UseMutDeleteShiftsProps) => {
	const queryClient = useQueryClient();
	const _delete = async (data: DeleteShifts) =>
		APIClient.request('/v1/shifts/{year}/{week}', 'delete', {
			params: {
				path: { year, week },
			},
			body: data,
		});

	return useMutation({
		mutationKey: ['deleteShifts'],
		mutationFn: _delete,
		onSuccess: () => {
			if (invalidateQueryKey)
				queryClient.invalidateQueries({
					queryKey: invalidateQueryKey,
				});
			onSuccess && onSuccess();
		},
	});
};
