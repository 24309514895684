import { APIClient } from '@frontend/api';
import { useQuery } from '@tanstack/react-query';

export const useQueryGetSections = (enabled: boolean = true) => {
	const getData = async () => {
		return await APIClient.request('/v1/sections', 'get', {});
	};

	return useQuery({
		queryKey: ['sections'],
		queryFn: getData,
		gcTime: 1000 * 60 * 60 * 24, // cache for 24 hours
		enabled,
	});
};
