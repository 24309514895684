import { getWeek } from 'date-fns/getWeek';
import { getYear } from 'date-fns/getYear';
import { WeekYear } from './';

export default function (date: Date): WeekYear {
	return {
		year: getYear(date),
		week: getWeek(date),
	};
}
