import { Slider } from '@frontend/ui';
import { useState } from 'react';

export const FluidSlider = ({
	initialVal = 0,
	onChange,
	disabled,
}: {
	initialVal: number;
	onChange: (val: number) => void;
	disabled?: boolean;
}) => {
	const [value, setValue] = useState<number>(initialVal);
	return (
		<>
			<Slider
				min={0}
				max={100}
				disabled={disabled ?? false}
				defaultValue={[initialVal]}
				step={1}
				onValueChange={(val) => {
					setValue(val[0]);
				}}
				onValueCommit={(val) => {
					onChange(val[0]);
				}}
				className="mb-2"
			/>
			<span>{value}%</span>
		</>
	);
};
