import { ContainerOptions } from 'pixi.js';
import { FlexContainer } from '.';
import { Edges } from '../utils';

type LabelContainerProps = {
	parentEdges: Edges;
} & ContainerOptions;

export default class LabelContainer extends FlexContainer {
	private _edges: Edges;

	constructor(props: LabelContainerProps) {
		super(props);

		this._edges = props.parentEdges;
	}

	center() {
		const b = this.getBounds();
		this.pivot.set((b.width / 2) * 0.75, (b.height / 2) * 0.75);
		this.position.set(this._edges.center[0], this._edges.center[1]);
	}
}
