import {
	BaseLineChart,
	ChartLabel,
	ChartSerie,
	getClosestIndex,
	mapTimestampToChartPosition,
} from '@components';
import { Transaction } from '@frontend/api';
import { usePremise } from '@frontend/context';
import { useTranslation } from '@frontend/locale';
import { twColors } from '@frontend/ui';
import {
	DateRange,
	dateFormats,
	parseFromUTC,
	formatDate,
} from '@frontend/util';
import { useEffect, useState } from 'react';
import { genEmptyChartData } from './charts';

type DosagesByRoomChartProps = DateRange & {
	transactions?: Transaction[];
};

export const DosagesByRoomChart = ({
	transactions,
	from,
	to,
	preset,
}: DosagesByRoomChartProps) => {
	const { getRoomById } = usePremise();
	const { t } = useTranslation();
	const [chartLabels, setChartLabels] = useState<ChartLabel[]>([]);
	const [chartSeries, setChartSeries] = useState<ChartSerie[]>([]);
	const [chartKeys, setChartKeys] = useState<string[]>([]);

	const AGGRIGATE_AFTER = 5;

	useEffect(() => {
		if (!transactions) return;

		const trans = transactions;

		const grouped = trans.reduce<Record<string, number>>((acc, val) => {
			if (!acc[val.roomId]) acc[val.roomId] = 0;
			acc[val.roomId] += 1;
			return acc;
		}, {});

		const sortable: [string, number][] = [];
		for (const group in grouped) {
			sortable.push([group, grouped[group]]);
		}

		const sorted = sortable.sort((a, b) => b[1] - a[1]);
		const topNth = sorted.splice(0, AGGRIGATE_AFTER);

		const keys = topNth.map((item) => getRoomById(item[0])?.name || 'UNKNOWN');

		if (sorted.length) keys.push(t('common.other'));

		const { labels, series } = genEmptyChartData({ from, to, preset, keys });

		for (const tran of trans) {
			const roomName = getRoomById(tran.roomId)?.name || 'UNKNOWN';
			const parsedTs = parseFromUTC(tran.timestamp);

			const position = mapTimestampToChartPosition(
				parsedTs.getTime(),
				from.getTime(),
				to ? to.getTime() : from.getTime(),
				500,
			);

			const closestIndex = getClosestIndex(position, 500, labels.length);

			const _index = keys.indexOf(roomName);
			const chartKeyIndex = _index < 0 ? keys.length - 1 : _index;

			const serie = series[closestIndex];

			if (serie) {
				serie[keys[chartKeyIndex]] += 1;
			}
		}

		setChartLabels(labels);
		setChartSeries(series);
		setChartKeys(keys);
	}, [transactions]);

	if (!transactions) return <>{t('titles.no-data')}</>;

	return (
		<BaseLineChart
			dataKey="ROOMTYPE"
			colors={{
				light: [
					twColors['red']['500'],
					twColors['green']['500'],
					twColors['sky']['500'],
					twColors['purple']['500'],
					twColors['yellow']['500'],
					twColors['gray']['500'],
				],
				dark: [
					twColors['red']['500'],
					twColors['green']['500'],
					twColors['sky']['500'],
					twColors['purple']['500'],
					twColors['yellow']['500'],
					twColors['gray']['500'],
				],
			}}
			xAxis={{
				minTickGap: 100,
				tickFormatter: (value) => {
					return formatDate(new Date(value), dateFormats[preset].short);
				},
			}}
			labels={chartLabels}
			series={chartSeries}
			chartKeys={chartKeys}
			strokeDash={false}
			strokeWidth={1}
			hasTooltip={true}
		/>
	);
};
