import { useAuth } from '@frontend/context';
import { useTranslation } from '@frontend/locale';
import {
	Button,
	Link,
	RLogo,
	ResaniLogo,
	ThemeSpacingKey,
	TypographyLead,
	buttonVariants,
	getScreenSize,
} from '@frontend/ui';
import {
	URLParamManager,
	cn,
	getBaseURL,
	getResaniTenantInfo,
	uuid,
} from '@frontend/util';
import { LinkObj, useLinkTree } from '@hooks';
import { ArrowLeftToLine, Menu, X } from 'lucide-react';
import { useLocation } from 'react-router-dom';
import { create } from 'zustand';

type SidebarState = {
	collapsed: boolean;
	setCollapsed: (collapsed: boolean) => void;
};

const checkIsMobile = () => ['sm', 'md'].includes(getScreenSize());
const isSM = () => getScreenSize() === 'sm';

const manager = new URLParamManager<{
	collapsed: boolean;
}>({
	collapsed: checkIsMobile(),
});

const queryParams = manager.getURLParam(['collapsed']);

const useSidebarStore = create<SidebarState>((set) => ({
	collapsed: queryParams.collapsed,
	setCollapsed: (collapsed: boolean) => set({ collapsed }),
}));

export const SidebarHamburger = ({ className }: { className?: string }) => {
	const { collapsed, setCollapsed } = useSidebarStore();
	const { t } = useTranslation();

	return (
		<Button
			onClick={() => {
				setCollapsed(!collapsed);
				manager.setURLParam('collapsed', !collapsed);
			}}
			variant="ghost"
			// TODO: fix a11y wrong title
			title={t('titles.collapse-sidebar')}
			className={cn('p-2', className)}
		>
			{collapsed ? (
				<Menu className="h-6 w-6" />
			) : (
				<ArrowLeftToLine className="h-6 w-6" />
			)}
		</Button>
	);
};

export const SidebarCloseButton = ({ className }: { className?: string }) => {
	const { collapsed, setCollapsed } = useSidebarStore();
	const { t } = useTranslation();

	return (
		<Button
			onClick={() => {
				setCollapsed(!collapsed);
				manager.setURLParam('collapsed', !collapsed);
			}}
			variant="ghost"
			// TODO: fix a11y wrong title
			title={t('titles.collapse-sidebar')}
			className={cn('p-2', className, { hidden: !checkIsMobile() })}
		>
			<X className="h-6 w-6" />
		</Button>
	);
};

const SidebarLinkButtons = () => {
	const { linkTree } = useLinkTree();
	const { pathname } = useLocation();
	const { setCollapsed, collapsed } = useSidebarStore();
	const { getRoles } = useAuth();

	const roles = getRoles();

	const renderLinks = (link: LinkObj, key = uuid()): JSX.Element | null => {
		if (link.hideSidebarLink) return null;
		if (link.hideWhenMinimized && collapsed) return null;

		if (link.roles && !link.roles.some((role) => roles.includes(role)))
			return null;

		const isActive = pathname === link.href;

		const getLinkIcon = () =>
			link.icon ? (
				<link.icon
					className={cn({
						'mr-4 h-5 w-5': !collapsed,
						'h-8 w-8': collapsed,
					})}
				/>
			) : undefined;

		const linkClass = cn(buttonVariants({ variant: 'ghost' }), {
			'justify-start p-2 text-muted-foreground': true,
			'text-resani': isActive,
		});

		const getLink = () =>
			link.isNotPartOfRouter ? (
				<a
					title={link.label}
					className={linkClass}
					href={`${getBaseURL()}${link.href}`}
				>
					{getLinkIcon()}
					{!collapsed && link.label}
				</a>
			) : (
				<Link
					className={linkClass}
					to={link.href}
					// open in new tab if external link
					onClick={() => {
						if (checkIsMobile()) setCollapsed(true);
					}}
					title={link.label}
				>
					{getLinkIcon()}
					{!collapsed && link.label}
				</Link>
			);

		return (
			<div key={key}>
				{link.isTitle ? (
					<>
						{!collapsed && (
							<TypographyLead className="text-base">
								{link.label}
							</TypographyLead>
						)}
					</>
				) : (
					getLink()
				)}
				<div
					className={cn({
						'flex flex-col': true,
						'': !collapsed,
						'items-center gap-4': collapsed,
					})}
				>
					{link.children && (
						<>{link.children.map((childLink) => renderLinks(childLink))}</>
					)}
				</div>
			</div>
		);
	};

	return (
		<div className="flex flex-col gap-4">
			{linkTree.map((link) => renderLinks(link))}
		</div>
	);
};

export const Sidebar = ({
	minWidth,
	maxWidth,
}: {
	minWidth: `w-${ThemeSpacingKey}`;
	maxWidth: `w-${ThemeSpacingKey}`;
}) => {
	const { collapsed, setCollapsed } = useSidebarStore();

	return (
		<div
			className={cn({
				'fixed left-0 top-0 z-10 flex h-screen w-screen lg:w-auto': true,
				hidden: isSM() && collapsed,
				'w-auto': collapsed,
			})}
		>
			<div
				className={cn(
					`border-border bg-background h-full border-r`,
					'left-0 top-0',
					{
						'p-6': !collapsed,
						'': collapsed,
						[minWidth]: collapsed && !isSM(),
						hidden: isSM() && collapsed,
						[maxWidth]: !collapsed,
					},
				)}
			>
				{collapsed ? (
					<div className="mb-4 flex justify-center p-3">
						<RLogo className="fill-resani w-6" />
					</div>
				) : (
					<div className="mb-10 flex items-center justify-between">
						<div>
							<ResaniLogo className="fill-resani w-32" />
							<p className="text-muted-foreground">
								{getResaniTenantInfo().name}
							</p>
						</div>
						<SidebarCloseButton />
					</div>
				)}

				<SidebarLinkButtons />
			</div>

			<div
				className={cn({ 'bg-resani grow opacity-50': true, hidden: collapsed })}
				onClick={() => setCollapsed(!collapsed)}
				onKeyUp={() => setCollapsed(!collapsed)}
			/>
		</div>
	);
};

export const SidebarAndContent = ({
	minWidth = 'w-20',
	maxWidth = 'w-72',
	minMargin = 'ml-20',
	maxMargin = 'ml-72',
	children,
}: {
	minWidth?: `w-${ThemeSpacingKey}`;
	maxWidth?: `w-${ThemeSpacingKey}`;
	minMargin?: `ml-${ThemeSpacingKey}`;
	maxMargin?: `ml-${ThemeSpacingKey}`;
	children: React.ReactNode;
}) => {
	const { collapsed } = useSidebarStore();

	return (
		<div className="flex h-full w-full">
			<Sidebar maxWidth={maxWidth} minWidth={minWidth} />
			<div
				className={cn({
					'flex h-full w-full flex-col': true,
					[minMargin]: collapsed && !isSM(),
					[maxMargin]: !collapsed,
				})}
			>
				{children}
			</div>
		</div>
	);
};
