import { ApplicationOptions } from 'pixi.js';

type ExtendedOptions = ApplicationOptions & {
	// Enable pixi debugger to be use in conjunction with pixi-inspector
	enableDebugger: boolean;
	// If true will discard current instance of pixi when its finished loading
	isDeadApp: boolean;
};

export const defaultOptions: Partial<ExtendedOptions> = {
	width: 0,
	height: 0,
	backgroundAlpha: 0,
	eventMode: 'static',
	autoStart: false,
	antialias: true,
	resolution: 1,
	enableDebugger: import.meta.env.DEV,
};
