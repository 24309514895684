import { sub } from 'date-fns/sub';
import getDateAlters from './getDateAlters';
import { DateRange } from './types';

export default function (range: DateRange): DateRange {
	const alter = getDateAlters(range);
	return {
		from: sub(range.from, alter),
		to: range.to && sub(range.to, alter),
		preset: range.preset,
	};
}
