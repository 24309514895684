import { TypographyH1, TypographyP } from '@frontend/ui';
import { ErrorLayout } from '@layouts';

export const ErrorDisplay = ({
	title,
	description,
	returnUrl,
}: {
	title: string;
	description: string;
	returnUrl: string | false;
}) => {
	return (
		<ErrorLayout>
			<div className="flex flex-col justify-center text-center">
				<TypographyH1>{title}</TypographyH1>
				<TypographyP>{description}</TypographyP>
				{returnUrl ? (
					<a className="underline" href={returnUrl}>
						Return to Dashboard
					</a>
				) : null}
			</div>
		</ErrorLayout>
	);
};
