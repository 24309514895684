import { BaseHeatmapChart, BaseHeatmapData } from '@components';
import { Transaction } from '@frontend/api';
import { usePremise } from '@frontend/context';
import { useTranslation } from '@frontend/locale';
import { twColors } from '@frontend/ui';
import {
	DateRange,
	dateFormats,
	getClosestBinarySearch,
	getDateColumnsInRange,
	parseFromUTC,
	formatDate,
} from '@frontend/util';
import { deepClone } from '@frontend/util';

type DosagesByRoomHeatmapChartProps = DateRange & {
	transactions?: Transaction[];
};

export const DosagesByRoomHeatmapChart = ({
	transactions,
	from,
	to,
	preset,
}: DosagesByRoomHeatmapChartProps) => {
	const { t } = useTranslation();
	const { getRoomById } = usePremise();

	const trans = transactions || [];

	const dates = getDateColumnsInRange({ from, to });
	const datesTS = dates.map((d) => d.getTime()).sort();

	const heatmapData: BaseHeatmapData = {};

	const keyIndex: { [key: string]: number } = {};

	const datesKeysObj = datesTS.map((date, i) => {
		const key = formatDate(date, 'dd-MM-yy HH:mm');

		keyIndex[key] = i;

		return { header: new Date(date), value: 0 };
	});

	let max = 0;

	for (const tran of trans) {
		const roomName = getRoomById(tran.roomId)?.name || 'UNKNOWN';
		const parsedTS = parseFromUTC(tran.timestamp).getTime();

		if (!heatmapData[roomName]) heatmapData[roomName] = deepClone(datesKeysObj);

		const closestDate = getClosestBinarySearch(datesTS, parsedTS);
		if (!closestDate) {
			console.warn('unable to find closest date for heatmap calculation');
			continue;
		}

		const closestDateFormat = formatDate(
			new Date(closestDate),
			'dd-MM-yy HH:mm',
		);

		if (heatmapData[roomName][keyIndex[closestDateFormat]] === undefined) {
			console.warn(
				'unable to find date formatted for closest date in heatmap data',
			);
			continue;
		}

		const dateItem = heatmapData[roomName][keyIndex[closestDateFormat]].value++;

		max = dateItem > max ? dateItem : max;
	}

	return (
		<BaseHeatmapChart
			data={heatmapData}
			min={0}
			max={max}
			colorTreshold={[0, 15, 25, 50, 75]}
			colors={{
				dark: [],
				light: [
					twColors['slate']['100'],
					twColors['green']['100'],
					twColors['green']['300'],
					twColors['green']['600'],
					twColors['green']['900'],
				],
			}}
			headerFormat={(header) => {
				if (header instanceof Date)
					return formatDate(header, dateFormats[preset].short);
				return header.toString();
			}}
			tooltipContent={(col, row) => {
				const dateStr =
					col.header instanceof Date
						? formatDate(col.header, dateFormats[preset].long)
						: '';

				const text = `${col.value.toString()} ${t('common.dosage', { count: col.value })}, ${dateStr}`;

				return (
					<>
						<p className="text-xs font-bold">{row}</p>
						<p className="text-xs">{text}</p>
					</>
				);
			}}
		/>
	);
};
