import {
	FilterSidebarBaseProps,
	FilterSidebarRecord,
	UseStoreType,
} from './store';

export function injectSidebarStore<T extends FilterSidebarRecord, P>(
	Component: React.ComponentType<FilterSidebarBaseProps<T, P>>,
	useStore: UseStoreType<T>,
) {
	return (props: Omit<P, 'useStore'>) => (
		// TODO: BAD :[ This is a hack to get around the type error with as
		<Component {...(props as P)} useStore={useStore} />
	);
}
