type ErrorLayoutProps = {
	children: React.ReactNode | React.ReactNode[];
};

export const ErrorLayout = ({ children }: ErrorLayoutProps) => {
	return (
		<div className="mx-auto flex h-screen justify-center px-4 py-32">
			{children}
		</div>
	);
};
