import { useLoader } from '@frontend/context';
import { Loader2 } from 'lucide-react';
import { memo } from 'react';

import { cn } from '@frontend/util';
import { ResaniLogo } from '@frontend/ui';

type SpinnerProps = {
	size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
	className?: string;
};

export function Spinner({ size = 'sm', className }: SpinnerProps) {
	return (
		<Loader2
			className={cn(`animate-spin`, className, {
				'h-10 w-10': size === 'xs',
				'h-12 w-12': size === 'sm',
				'h-14 w-14': size === 'md',
				'h-16 w-16': size === 'lg',
				'h-20 w-20': size === 'xl',
			})}
		/>
	);
}

export const GlobalLoader = memo(() => {
	const { active } = useLoader();

	if (!active) return null;

	return (
		<div
			className={`bg-background absolute z-50 flex h-screen w-screen items-center justify-center`}
		>
			<div className="flex flex-col items-center justify-center text-lg md:text-lg">
				<ResaniLogo className="h-20 md:h-40 border-b-4 border-black" />
				<div className="flex items-center gap-4">
					<Spinner className="stroke-resani my-4" />
					<span className="text-center text-muted-foreground">
						{active.title}
					</span>
				</div>
			</div>
		</div>
	);
});
