import { ShiftCard, ShiftTypeCard } from '@components';
import { useTranslation } from '@frontend/locale';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@frontend/ui';
import { Clock4, ListChecks } from 'lucide-react';

export const Shifts = () => {
	const { t } = useTranslation();
	return (
		<Tabs defaultValue="shifts" className="flex h-full w-full flex-col">
			<TabsList className="inline-block">
				<div className="flex">
					<TabsTrigger value="shifts">
						<Clock4 className="mr-2 h-4 w-4" />
						{t('titles.shift-tab')}
					</TabsTrigger>
					<TabsTrigger value="types">
						<ListChecks className="mr-2 h-4 w-4" />
						{t('titles.shift-type-tab')}
					</TabsTrigger>
				</div>
			</TabsList>
			<TabsContent value="shifts">
				<ShiftCard />
			</TabsContent>
			<TabsContent value="types">
				<ShiftTypeCard />
			</TabsContent>
		</Tabs>
	);
};
