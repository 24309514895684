import { badgeVariants } from '#ui';
import { type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '@frontend/util';

export interface BadgeProps
	extends React.HTMLAttributes<HTMLDivElement>,
		VariantProps<typeof badgeVariants> {}

export function Badge({ className, variant, ...props }: BadgeProps) {
	return (
		<div className={cn(badgeVariants({ variant }), className)} {...props} />
	);
}
