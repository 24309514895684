import { round, URLParamManager } from '@frontend/util';
import { getPremiseStore } from '..';

const manager = new URLParamManager<{
	scale?: number;
	posX?: number;
	posY?: number;
	showNames?: 0 | 1;
	showCounts?: 0 | 1;
	showIcons?: 0 | 1;
	showHeatmap?: 0 | 1;
}>({
	scale: undefined,
	posX: undefined,
	posY: undefined,
	showNames: 0,
	showCounts: 1,
	showIcons: 1,
	showHeatmap: 0,
});

let timeout: ReturnType<typeof setTimeout> | null = null;

export const updateMapCordsQueryParams = () => {
	timeout && clearTimeout(timeout);
	const { app } = getPremiseStore();

	timeout = setTimeout(() => {
		if (!app || !app.viewport) return;

		if (
			app.viewport.scale.y === Infinity ||
			app.viewport.position.x.toString() === 'NaN' ||
			app.viewport.position.y.toString() === 'NaN'
		)
			return;

		if (
			typeof app.viewport.scale.y === 'number' &&
			typeof app.viewport.position.x === 'number' &&
			typeof app.viewport.position.y === 'number'
		) {
			manager.setURLParam('scale', round(app.viewport.scale.y, 4));
			manager.setURLParam('posX', round(app.viewport.position.x, 4));
			manager.setURLParam('posY', round(app.viewport.position.y, 4));
		}
	}, 250);
};

export const getMapURLParams = () =>
	manager.getURLParam([
		'scale',
		'posX',
		'posY',
		'showNames',
		'showCounts',
		'showHeatmap',
		'showIcons',
	]);

export const setMapURLParam = manager.setURLParam;
