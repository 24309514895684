import { LabelValueType, RadioButton, RadioButtonGroup } from '@components';
import { uuid } from '@frontend/util';
import { FilterSidebarBaseProps, FilterSidebarRecord } from '../store';

type ExtendedLabelValueType<T, K extends keyof T> = LabelValueType & {
	value: T[K];
};

type BaseRadioGroupProps<T extends FilterSidebarRecord> =
	FilterSidebarBaseProps<
		T,
		{
			name: keyof T;
			label: string;
			opts: ExtendedLabelValueType<T, keyof T>[];
			disabledBasedOn?: keyof T;
		}
	>;

export const BaseRadioGroup = <T extends FilterSidebarRecord>({
	name,
	label,
	opts,
	disabledBasedOn,
	useStore,
}: BaseRadioGroupProps<T>) => {
	const { filterValues, setFilterValue } = useStore();

	const selected = filterValues[name] as string | undefined;

	const getIsDisabled = () => {
		if (disabledBasedOn) {
			return filterValues[disabledBasedOn] === undefined;
		}
		return false;
	};

	if (selected !== undefined && typeof selected !== 'string') {
		throw new Error('Filter value must be a string');
	}

	return (
		<RadioButtonGroup label={label}>
			{opts.map((opt) => (
				<RadioButton
					disabled={getIsDisabled()}
					key={uuid()}
					label={opt.label || ''}
					// TODO: Can we make this more type-safe?
					value={opt.value || ''}
					active={selected === opt.value}
					onClick={() => setFilterValue(name, opt.value)}
				/>
			))}
		</RadioButtonGroup>
	);
};
