import { getScreenSize } from '@frontend/ui';
import { Container } from 'pixi.js';

export default class LODContainer extends Container {
	public lodRange: [number, number];

	// LODLevel 0,0 = always show
	constructor({ lodRange = [0, 0] }: { lodRange?: [number, number] }) {
		super();
		this.lodRange = this.getLODRangeBasedOnScreenSize(lodRange);
	}

	getLODRangeBasedOnScreenSize(range: [number, number | 0]): [number, number] {
		const size = getScreenSize();

		let from = range[0];
		let to = range[1];

		if (['xs', 'sm', 'md'].includes(size)) {
			from = from * 0.5;
			to = to * 0.5;
		}

		return [from, to];
	}
}
