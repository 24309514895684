import { APIClient, AxiosError } from '@frontend/api';
import { useRetryToast } from '@frontend/ui';
import { setOnline } from './store';

export const usePingServer = () => {
	const retry = useRetryToast();

	const pingServer = async () => {
		try {
			await APIClient.request('/v1/ping', 'get', {});
			setOnline(true);
			return true;
		} catch (e) {
			if (e instanceof AxiosError && e.response?.status === 401) {
				setOnline(true);
				return true;
			}

			setOnline(false);

			retry(() => pingServer());

			return false;
		}
	};

	return { pingServer };
};
