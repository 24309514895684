import { getResaniTenantInfo, initResaniTenantInfo } from '@frontend/util';
import { setAxiosHeader } from '@frontend/api';
import { APIProvider } from '@frontend/context';
import { initI18n } from '@frontend/locale';
import {
	ScreenLoader,
	ThemeProvider,
	Toaster,
	TooltipProvider,
} from '@frontend/ui';
import '@frontend/ui/theme';
import { Router } from '@router';
import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';

initResaniTenantInfo({
	// dev options
	// all of these will be either undefined or false in prod
	// and will use the url to decide the urls
	tenantName: import.meta.env.VITE_RESANI_TENANT_NAME,
	isDev: import.meta.env.DEV,
	isDevRoute: import.meta.env.VITE_IS_DEV_ROUTE === 'true',
	isPreview: import.meta.env.MODE === 'preview',
});
const { headers, name, urls } = getResaniTenantInfo();

initI18n();
setAxiosHeader(headers.tenant, name);

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
	<APIProvider
		clientConfig={{
			paramsSerializer: { indexes: null },
			baseURL: urls.api,
		}}
		queryDebugger={import.meta.env.DEV}
		queryConfig={{
			queries: {
				refetchOnWindowFocus: false,
				refetchOnReconnect: false,
			},
		}}
	>
		<ThemeProvider>
			<Suspense>
				<ScreenLoader />
				<Toaster />

				<StrictMode>
					<TooltipProvider>
						<Router />
					</TooltipProvider>
				</StrictMode>
			</Suspense>
		</ThemeProvider>
	</APIProvider>,
);
