export default function (nums: number[], target: number): number | undefined {
	let start = 0;
	let end = nums.length - 1;

	if (nums.length === 0) {
		return; // Handle empty array
	}

	while (start < end) {
		const mid = Math.floor((start + end) / 2);

		if (nums[mid] === target) {
			return nums[mid];
		} else if (nums[mid] < target) {
			start = mid + 1;
		} else {
			end = mid;
		}
	}

	// Handle the case where we're comparing the last two elements
	if (
		start != 0 &&
		Math.abs(nums[start] - target) >= Math.abs(nums[start - 1] - target)
	) {
		return nums[start - 1];
	}
	return nums[start];
}
