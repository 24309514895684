import {
	Calendar,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogDescription,
	Button,
	getScreenSize,
} from '#ui';
import { useTranslation } from '@frontend/locale';
import { getEndOfDay, getStartOfDay } from '@frontend/util';
import { Root as VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { useState } from 'react';
import { DateRange } from 'react-day-picker';

type CalendarPopoverProps = {
	open: boolean;
	range?: DateRange;
	setOpen: (open: boolean) => void;
	onApply: (from: Date, to: Date) => void;
};

export function CalendarDialog({
	open,
	range,
	setOpen,
	onApply,
}: CalendarPopoverProps) {
	const [state, setState] = useState<DateRange>(range || { from: new Date() });
	const { t } = useTranslation();

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent hasXBtn={false} className="p-0 w-auto">
				{/* accesibility */}
				<VisuallyHidden>
					<DialogTitle>Select date range</DialogTitle>
					<DialogDescription>Select date range</DialogDescription>
				</VisuallyHidden>

				<div className="flex flex-col">
					<Calendar
						autoFocus
						className=" border-b p-2 pt-4"
						mode="range"
						defaultMonth={state?.from || new Date()}
						selected={state}
						onSelect={(date, triggerDate) => {
							setState((s) => {
								if (s?.to) {
									return { from: new Date(triggerDate) };
								}
								return date || { from: new Date() };
							});
						}}
						numberOfMonths={getScreenSize() === 'sm' ? 1 : 2}
						pagedNavigation
					/>

					<div className="flex justify-end gap-2 p-2">
						<Button variant="ghost" onClick={() => setOpen(false)}>
							{t('common.cancel')}
						</Button>
						<Button
							onClick={() => {
								const from = state.from || new Date();
								const to = state.to || from;
								onApply(getStartOfDay(from), getEndOfDay(to));
								setOpen(false);
							}}
						>
							{t('common.apply')}
						</Button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
}
