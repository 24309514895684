import {
	DateRange,
	DateRangePreset,
	dateAdd,
	getEndOfDay,
	getEndOfMonth,
	getEndOfWeek,
	getEndOfYear,
	getStartOfDay,
	getStartOfMonth,
	getStartOfWeek,
	getStartOfYear,
} from './';

type CustomProps = Omit<DateRange, 'to'> & {
	preset: 'custom';
	max?: Date;
	increment?: number;
};

type PresetProps = Omit<DateRange, 'to'> & {
	preset: Exclude<DateRangePreset, 'custom'>;
	max?: Date;
	increment?: number;
};

type Props = CustomProps | PresetProps;

export default function ({
	from,
	preset = 'day',
	increment = 0,
}: Props): DateRange {
	switch (preset) {
		case 'day':
			return {
				from: getStartOfDay(dateAdd(from, { days: increment })),
				to: getEndOfDay(dateAdd(from, { days: increment })),
				preset,
			};
		case 'week':
			return {
				from: getStartOfWeek(dateAdd(from, { weeks: increment })),
				to: getEndOfWeek(dateAdd(from, { weeks: increment })),
				preset,
			};
		case 'month':
			return {
				from: getStartOfMonth(dateAdd(from, { months: increment })),
				to: getEndOfMonth(dateAdd(from, { months: increment })),
				preset,
			};
		case 'year':
			return {
				from: getStartOfYear(dateAdd(from, { years: increment })),
				to: getEndOfYear(dateAdd(from, { years: increment })),
				preset,
			};
		default:
			throw new Error('Cannot create range for custom preset');
	}
}
