import { useTranslation } from '@frontend/locale';
import { Button, Popover, PopoverContent, PopoverTrigger } from '@frontend/ui';
import { X } from 'lucide-react';
import { useState } from 'react';

export const ButtonWithPopover = ({
	onClickYes,
	children,
}: {
	onClickYes: () => void;
	children: React.ReactNode | React.ReactNode[];
}) => {
	const [open, setOpen] = useState(false);
	const { t } = useTranslation();

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<Button variant="ghost" className="h-7 w-7 p-0">
					<X className="h-5 w-5" />
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-32 px-2 py-4">
				<p className="mb-2 text-center text-sm ">{children}</p>
				<div className="flex justify-center gap-2">
					<Button
						variant="outline"
						className="h-8 w-12"
						onClick={() => setOpen(false)}
					>
						{t('common.no')}
					</Button>
					<Button
						onClick={() => {
							setOpen(false);
							onClickYes();
						}}
						variant="destructive"
						className="h-8 w-12"
					>
						{t('common.yes')}
					</Button>
				</div>
			</PopoverContent>
		</Popover>
	);
};
