import { create } from 'zustand';
import { PremiseMapApp } from '.';

export type PremiseStoreType = {
	app?: PremiseMapApp;
	activeNodeId?: string;
	hoverNodeId?: string;
	isFullScreen: boolean;
	isLoading: boolean;
	floorLabelArray: { label: string; value: number }[];
};

export const usePremiseStore = create<PremiseStoreType>(() => ({
	floorLabelArray: [],
	isFullScreen: false,
	isLoading: true,
}));

export const getPremiseStore = usePremiseStore.getState;
export const setPremiseStore = usePremiseStore.setState;
