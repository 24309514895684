import { useTranslation } from '@frontend/locale';
import { uuid } from '@frontend/util';
import { create } from 'zustand';

type LoaderItem = {
	id: string;
	title: string;
};

type LoaderStoreType = {
	items: LoaderItem[];
};

const useLoaderStore = create<LoaderStoreType>(() => ({
	items: [],
}));

const addLoader = (id: string, title: string) =>
	useLoaderStore.setState((state) => {
		return {
			...state,
			items: [...state.items, { id, title }],
		};
	});

const removeLoader = (id: string) =>
	useLoaderStore.setState((state) => {
		return {
			...state,
			items: state.items.filter((i) => i.id !== id),
		};
	});

const resetLoader = () =>
	useLoaderStore.setState((state) => ({ ...state, items: [] }));

const useLoaderState = () => {
	const { active } = useLoaderStore((state) => ({
		active: state.items,
	}));

	return active[0];
};

const useLoaderSetter = (id: string) => {
	const { t } = useTranslation();

	return {
		addLoader: (title: string = t('common.loading')) => addLoader(id, title),
		removeLoader: () => removeLoader(id),
		resetLoader: () => resetLoader(),
	};
};

export const useScreenLoader = (id: string = uuid()) => {
	const setter = useLoaderSetter(id);
	const active = useLoaderState();

	return {
		active,
		...setter,
	};
};
