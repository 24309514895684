import {
	BatteryDialog,
	DataTable,
	DeviceCard,
	FluidDialog,
	getDeviceColumns,
	useMaintenanceFilterSidebar,
} from '@components';
import { Device, SortBy, SortDirection } from '@frontend/api';
import { useQueryGetDevices } from '@frontend/context';
import { useURLParams } from '@frontend/hook';
import { SortingState } from '@tanstack/react-table';
import { useState } from 'react';

const DEFAULT_PAGE_SIZE = 10;

export const DataTableDevices = () => {
	const [fluidDialogOpen, setFluidDialogOpen] = useState(false);
	const [batteryDialogOpen, setBatteryDialogOpen] = useState(false);
	const [currDevice, setCurrDevice] = useState<Device | undefined>(undefined);

	const {
		filterState: f,
		FilterSidebar,
		ToggleButton,
		BadgesList,
	} = useMaintenanceFilterSidebar();

	type ServerSortType = {
		sortBy?: SortBy;
		sortDirection?: SortDirection;
		pageSize: number;
		pageIndex: number;
	};

	const { getURLParam, setURLParam } = useURLParams<ServerSortType>({
		sortBy: undefined,
		sortDirection: undefined,
		pageSize: DEFAULT_PAGE_SIZE,
		pageIndex: 0,
	});

	const [serverSort, setServerSort] = useState<ServerSortType>({
		...getURLParam(['sortBy', 'sortDirection', 'pageSize', 'pageIndex']),
	});

	const { sortBy, sortDirection, pageSize, pageIndex } = serverSort;

	const { data, isFetching } = useQueryGetDevices({
		...f,
		isOn: f?.status && f?.status === 'on',
		isBattery: f?.powerType && f?.powerType === 'battery',
		pageSize: pageSize,
		pageIndex: pageIndex,
		sortBy: sortBy,
		sortDirection: sortDirection,
		sectionIds: f?.sectionId ? [f?.sectionId] : [],
	});

	const onChangePagination = (pageIndex: number, pageSize: number) => {
		setURLParam('pageSize', pageSize);
		setURLParam('pageIndex', pageIndex);
		setServerSort((prev) => ({ ...prev, pageSize, pageIndex }));
	};

	// TODO: Does not support multi sort
	const onChangeSorting = (sort: SortingState) => {
		const item = sort[0];
		if (!item) return;

		const _sortBy = item.id as SortBy;
		const _sortDirection = item.desc ? 'Asc' : 'Desc';

		setURLParam('sortBy', _sortBy);
		setURLParam('sortDirection', _sortDirection);
		setServerSort((prev) => ({
			...prev,
			sortBy: _sortBy,
			sortDirection: _sortDirection,
		}));
	};

	const handleFluidChange = (device: Device) => {
		setCurrDevice(device);
		setFluidDialogOpen(true);
	};

	const handleBatteryChange = (device: Device) => {
		setCurrDevice(device);
		setBatteryDialogOpen(true);
	};

	return (
		<>
			<DataTable
				onChangePagination={onChangePagination}
				isLoading={isFetching}
				defaultPageSize={pageSize || DEFAULT_PAGE_SIZE}
				pageCount={data?.totalPages}
				columns={getDeviceColumns({
					onClickBatteryChange: handleBatteryChange,
					onClickFluidChange: handleFluidChange,
				})}
				data={data?.items || []}
				totalItems={data?.totalItems || 0}
				onChangeSorting={onChangeSorting}
				navChildren={
					<div className="flex w-full items-center gap-2">
						<ToggleButton />
						<BadgesList />
					</div>
				}
				cardViewTemplate={DeviceCard}
			/>
			{currDevice && (
				<>
					<FluidDialog
						device={currDevice}
						open={fluidDialogOpen}
						setOpen={setFluidDialogOpen}
					/>
					<BatteryDialog
						device={currDevice}
						open={batteryDialogOpen}
						setOpen={setBatteryDialogOpen}
					/>
				</>
			)}

			<FilterSidebar />
		</>
	);
};
