import { APIClient } from '@frontend/api';
import { useQuery } from '@tanstack/react-query';

const getData = async (sectionId: string) =>
	await APIClient.request('/v1/sections/{sectionId}/map', 'get', {
		params: {
			path: {
				sectionId,
			},
		},
	});

export const useQueryGetSectionMap = (sectionId?: string) => {
	return useQuery({
		queryKey: [`section-floors-${sectionId}`],
		queryFn: sectionId ? () => getData(sectionId) : undefined,
		retry: false,
		enabled: !!sectionId,
	});
};
