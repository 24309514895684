import { APIClient, ShiftItem, Shifts } from '@frontend/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

type UseMutPostShiftsProps = {
	invalidateQueryKey?: unknown[];
	onSuccess?: (data: Shifts) => void;
};

export const useMutPostShifts = ({
	invalidateQueryKey,
	onSuccess,
}: UseMutPostShiftsProps) => {
	const queryClient = useQueryClient();

	const postData = async ({
		year,
		week,
		shifts,
	}: {
		year: number;
		week: number;
		shifts: ShiftItem[];
	}) => {
		return await APIClient.request('/v1/shifts/{year}/{week}', 'post', {
			params: {
				path: {
					year: year,
					week: week,
				},
			},
			body: {
				shifts,
			},
		});
	};

	return useMutation({
		mutationKey: ['postShifts'],
		mutationFn: postData,
		onSuccess: (data) => {
			if (invalidateQueryKey)
				queryClient.invalidateQueries({
					queryKey: invalidateQueryKey,
				});
			onSuccess && onSuccess(data);
		},
	});
};
