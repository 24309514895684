import { useTranslation } from '@frontend/locale';
import { DateRangePreset } from '@frontend/util';
import {
	SelectTrigger,
	Select,
	SelectValue,
	SelectContent,
	SelectItem,
	Label,
} from '#ui';

type PresetSelectProps = {
	preset?: DateRangePreset;
	onPresetChanged: (preset: DateRangePreset) => void;
};

export function PresetSelect({ preset, onPresetChanged }: PresetSelectProps) {
	const { t } = useTranslation();

	const datePresets: {
		label: string;
		value: DateRangePreset;
	}[] = [
		{
			label: t('inputs.date-presets.today'),
			value: 'day',
		},
		{
			label: t('inputs.date-presets.this-week'),
			value: 'week',
		},
		{
			label: t('inputs.date-presets.this-month'),
			value: 'month',
		},
		{
			label: t('inputs.date-presets.this-year'),
			value: 'year',
		},
	];

	return (
		<Select
			value={preset}
			onValueChange={(value: DateRangePreset) => {
				onPresetChanged(value);
			}}
		>
			<Label>{t('common.preset')}</Label>
			<SelectTrigger>
				{preset === 'custom' ? (
					t('common.custom')
				) : (
					<SelectValue placeholder={t('inputs.date-presets.select-preset')} />
				)}
			</SelectTrigger>
			<SelectContent position="popper">
				{datePresets.map((item) => (
					<SelectItem key={item.value} value={item.value}>
						{item.label}
					</SelectItem>
				))}
			</SelectContent>
		</Select>
	);
}
