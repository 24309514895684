import { genRandomNumInRange, round } from '@frontend/util';
import { Point } from 'geometric';
import polylabel from 'polylabel';
import { CommandMadeAbsolute, makeAbsolute, parseSVG } from 'svg-path-parser';

export function extractPointsFromDString(d: string) {
	return makeAbsolute(parseSVG(d));
}

export type Edges = {
	tl: Point;
	tr: Point;
	bl: Point;
	br: Point;
	tc: Point;
	bc: Point;
	cl: Point;
	cr: Point;
	center: Point;
};

export function calculateEdgePoints(commands: CommandMadeAbsolute[]): Edges {
	const points: Point[] = commands.map((c, _i) => [round(c.x), round(c.y)]);

	const edges: Edges = {
		tl: [Infinity, Infinity],
		tr: [-Infinity, Infinity],
		bl: [Infinity, -Infinity],
		br: [-Infinity, Infinity],
		tc: [Infinity, Infinity],
		bc: [Infinity, Infinity],
		cl: [Infinity, Infinity],
		cr: [Infinity, Infinity],
		center: [Infinity, Infinity],
	};

	points.forEach((point) => {
		// Top-left
		if (point[0] <= edges.tl[0] && point[1] <= edges.tl[1]) {
			edges.tl = point;
		}

		// Top-right
		if (point[0] >= edges.tr[0] && point[1] <= edges.tr[1]) {
			edges.tr = point;
		}

		// Bottom-left
		if (point[0] <= edges.bl[0] && point[1] >= edges.bl[1]) {
			edges.bl = point;
		}

		// Bottom-right
		if (point[0] >= edges.br[0] && point[1] >= edges.br[1]) {
			edges.br = point;
		}
	});

	const p = polylabel([points], 1);
	edges.center = [p[0], p[1]];

	// Calculate midpoints
	edges.tc = [(edges.tl[0] + edges.tr[0]) / 2, edges.tl[1]];
	edges.bc = [(edges.bl[0] + edges.br[0]) / 2, edges.bl[1]];
	edges.cl = [edges.tl[0], (edges.tl[1] + edges.bl[1]) / 2];
	edges.cr = [edges.tr[0], (edges.tr[1] + edges.br[1]) / 2];

	return edges;
}

export const getRandomDirection = (exclude: (keyof Edges)[]) => {
	const keys: (keyof Edges)[] = [
		'tl',
		'tr',
		'bl',
		'br',
		'tc',
		'bc',
		'cl',
		'cr',
		'center',
	];

	const included = keys.filter((key) => !exclude.includes(key));

	return included[genRandomNumInRange(0, included.length - 1)];
};
