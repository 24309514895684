import { APIClient } from '@frontend/api';
import { useQuery } from '@tanstack/react-query';

export const useQueryGetRoomTypes = (enabled = true) => {
	const getData = async () =>
		await APIClient.request('/v1/room-types', 'get', {});

	return useQuery({
		queryKey: ['room-types'],
		queryFn: getData,
		enabled,
	});
};
