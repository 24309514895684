import { Color, Graphics, GraphicsOptions } from 'pixi.js';
import { calculateEdgePoints, extractPointsFromDString } from '../../utils';

type BaseGraphicProps = {
	dpath?: string;
	svg?: string;
	style?: Partial<BaseGraphicStyle>;
} & GraphicsOptions;

export type BaseGraphicStyle = {
	fillColor: string;
	alpha: Graphics['alpha'];
	strokeColor: string;
	strokeInset: boolean;
	strokeWidth: number;
	strokeAlpha: Graphics['alpha'];
};

export default class BaseGraphic extends Graphics {
	private _defaultStyle: Partial<BaseGraphicStyle> = {
		fillColor: 'transparent',
		strokeAlpha: 1,
	};
	private _svg?: string;
	private _dpath?: string;
	public isCullable = true;
	public currentStyle: Partial<BaseGraphicStyle> = {};

	constructor({ dpath, svg, style, ...rest }: BaseGraphicProps) {
		super(rest);

		const newStyle = { ...this._defaultStyle, ...style };

		if (dpath) {
			this._svg = `<svg><path d="${dpath}"/></svg>`;
			this._dpath = dpath;
			this.svg(this._svg);
		} else if (svg) {
			this._svg = svg;
			this.svg(this._svg);
		}

		this._defaultStyle = newStyle;
		this.setStyle({});
	}

	setStyle(newStyle: BaseGraphicProps['style']) {
		this.clear();
		if (this._dpath) this.setSvg();

		const s = { ...this._defaultStyle, ...this.currentStyle, ...newStyle };

		if (s.fillColor)
			this.fill({
				color: s.fillColor,
			});

		if (s.alpha !== undefined) {
			this.alpha = s.alpha;
		}

		if (s.strokeInset)
			this.stroke({
				color: new Color(s.strokeColor),
				width: s.strokeWidth,
				alpha: s.strokeAlpha,
				alignment: 1,
			});
		else
			this.stroke({
				color: new Color(s.strokeColor),
				width: s.strokeWidth,
				alpha: s.strokeAlpha,
			});

		this.currentStyle = s;
	}

	resetStyle() {
		this.clear();
		this.setStyle(this._defaultStyle);
	}

	setSvg() {
		this.svg(`<svg><path d="${this._dpath}"/></svg>`);
	}

	getEdges() {
		if (!this._dpath) {
			throw new Error('No dpath found, unable to fetch edge points');
		}
		return calculateEdgePoints(this.getPoints());
	}

	getPoints() {
		if (!this._dpath) return [];
		return extractPointsFromDString(this._dpath);
	}
}
