import { APIClient, InferRequestParams } from '@frontend/api';
import { useQuery } from '@tanstack/react-query';

type ShiftTypesQuery = {
	sectionIds: string[];
};

type RequestParams = InferRequestParams<'/v1/shift-types', 'get'>['query'];

export const getShiftTypes = async (query: RequestParams) =>
	await APIClient.request('/v1/shift-types', 'get', {
		params: {
			query,
		},
	});

export const useQueryGetShiftTypes = ({ sectionIds }: ShiftTypesQuery) => {
	return useQuery({
		queryKey: ['shift-types', sectionIds],
		queryFn: () => getShiftTypes({ 'section-id': sectionIds }),
		enabled: sectionIds.length > 0,
	});
};
