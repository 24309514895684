import { ErrorLayout } from '@layouts';
import { AxiosError } from '@frontend/api';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

type ErrorStatuses = 404 | 500 | 401 | 'unknown' | 'ERR_NETWORK';

type ErrorMessage = {
	title: string;
	description: string;
	returnUrl: string | false;
};

type ErrorMessages = {
	[key in ErrorStatuses]: ErrorMessage;
};

const errorMessages: ErrorMessages = {
	404: {
		title: 'Not Found',
		description: 'The page you are looking for does not exist.',
		returnUrl: '/dashboard',
	},
	401: {
		title: 'Unauthorized',
		description: 'You are not authorized to view this page.',
		returnUrl: '/dashboard',
	},
	500: {
		title: 'Internal Server Error',
		description: 'Something went wrong.',
		returnUrl: '/dashboard',
	},
	unknown: {
		title: 'Unknown Error',
		description: 'Something went wrong.',
		returnUrl: '/dashboard',
	},
	ERR_NETWORK: {
		title: 'Connection timed out',
		description:
			'Please check your internet, if it is working properly, please contact your administrator.',
		returnUrl: false,
	},
};

const getErrorMesage = (code: string | undefined) => {
	const errorKeys = Object.keys(errorMessages);

	if (errorKeys.includes(code as string)) {
		return errorMessages[code as ErrorStatuses];
	}
	return errorMessages['unknown'];
};

export const ErrorBoundary = () => {
	const error = useRouteError();

	let code: string | undefined = undefined;
	if (isRouteErrorResponse(error) || error instanceof Response) {
		code = error.status.toString();
	} else if (error instanceof AxiosError) {
		code = error.code || undefined;
	}

	let { title, description, returnUrl } = getErrorMesage(code);

	if (error instanceof Error) {
		description = error.message;
	}

	return (
		<ErrorLayout>
			<div className="flex flex-col justify-center text-center">
				<h1 className="text-primary mb-4 text-3xl font-bold">{title}</h1>
				<p className="text-primary mb-4">{description}</p>
				{returnUrl ? (
					<a className="underline" href={returnUrl}>
						Return to Dashboard
					</a>
				) : null}
			</div>
		</ErrorLayout>
	);
};
