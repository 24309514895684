import { CardTrendSecctionSub, CardTrendSectionMain } from '@components';
import { useQueryGetUsageInPeriod } from '@frontend/context';
import { useTranslation } from '@frontend/locale';
import { Card, CardContent, CardHeader, CardTitle } from '@frontend/ui';
import { DateRangeWithPrev } from '@frontend/util';
import { DosagesLineChart } from '../DosagesLineChart';
import { Spinner } from '../GlobalLoader';

type DosagesCardProps = DateRangeWithPrev & {
	roomTypeIds?: string[];
	sectionIds: string[];
};

export const DosagesCard = ({
	pFrom,
	pTo,
	from,
	to,
	preset,
	roomTypeIds,
	sectionIds,
}: DosagesCardProps) => {
	const { t } = useTranslation();

	const pResult = useQueryGetUsageInPeriod({
		from: pFrom,
		to: pTo,
		roomTypeIds: roomTypeIds || [],
		sectionIds: sectionIds || [],
		roomIds: [],
	});

	const result = useQueryGetUsageInPeriod({
		from: from,
		to: to,
		roomTypeIds: roomTypeIds || [],
		sectionIds: sectionIds || [],
		roomIds: [],
	});

	const isLoading = pResult.isLoading || result.isLoading;

	return (
		<Card>
			<CardHeader>
				<CardTitle>{t('titles.usage-over-time')}</CardTitle>
				<CardTrendSectionMain
					startingValue={pResult.data?.transactions?.length || 0}
					finalValue={result.data?.transactions?.length || 0}
				/>
				<CardTrendSecctionSub
					title={t('titles.usage-average')}
					compareToValue={pResult.data?.usageByTime?.average || 0}
					mainValue={result.data?.usageByTime?.average || 0}
				/>
			</CardHeader>
			<CardContent className="flex  h-80 items-center justify-center">
				{isLoading && <Spinner className="h-20 w-20" />}
				{!isLoading && (
					<DosagesLineChart
						prevTransactions={pResult.data?.transactions || []}
						currTransactions={result.data?.transactions || []}
						from={from}
						to={to}
						pFrom={pFrom}
						pTo={pTo}
						preset={preset}
					/>
				)}
			</CardContent>
		</Card>
	);
};
