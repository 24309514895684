import { BadgeObj, BadgesList } from '@components';
import { FilterSidebarBaseProps, FilterSidebarRecord } from '../store';

type BaseBadgesListProps<T extends FilterSidebarRecord> =
	FilterSidebarBaseProps<
		T,
		{
			badges: {
				[key in keyof Partial<T>]: Pick<BadgeObj, 'value' | 'label'> & {
					preventOnBadgeRemove?: boolean;
				};
			};
			onBadgeRemove?: (key: keyof T) => void;
			className?: string;
		}
	>;

export const BaseBadgesList = <T extends FilterSidebarRecord>({
	useStore,
	badges,
	onBadgeRemove,
	className,
}: BaseBadgesListProps<T>) => {
	const { clearFilterValue } = useStore();
	const keys = Object.keys(badges) as (keyof T)[];

	return (
		<BadgesList
			className={className}
			badges={keys.map((key) => {
				const item = badges[key];
				const newObj: BadgeObj = {
					label: item?.label,
					value: item?.value,
					onRemoveBadge: !item?.preventOnBadgeRemove
						? () => {
								clearFilterValue(key);
								onBadgeRemove && onBadgeRemove(key);
							}
						: undefined,
				};
				return newObj;
			})}
		/>
	);
};
