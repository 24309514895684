import { getThemeColor } from '@frontend/ui';
import BaseGraphic from './BaseGraphic';

export default class WallsGraphic extends BaseGraphic {
	constructor(path: string) {
		super({
			dpath: path,
			style: {
				fillColor: getThemeColor('muted'),
				strokeWidth: 1,
				strokeColor: getThemeColor('muted-foreground'),
			},
		});
	}
}
