import { XAxis, XAxisProps, YAxis, YAxisProps } from 'recharts';

import { chartConfig } from './config';

type AxisProps = {
	layout?: 'horizontal' | 'vertical';
};

export const createXAxis = ({ layout, ...rest }: XAxisProps & AxisProps) => {
	const config = { ...chartConfig.xAxis, ...rest };

	if (layout === 'vertical') {
		config.dataKey = undefined;
		config.type = 'number';
	}

	// @ts-ignore
	return <XAxis {...config} />;
};

export const createYAxis = ({ layout, ...rest }: YAxisProps & AxisProps) => {
	const config = { ...chartConfig.yAxis, ...rest };

	if (layout === 'vertical') {
		config.type = 'category';
	} else {
		config.dataKey = undefined;
	}

	// @ts-ignore
	return <YAxis {...config} />;
};
