import { enGB, unicodeToEmoji } from '@frontend/util';
import { LocaleObj } from './types';
import { unitedkingdom } from './flags';

export default {
	id: 'en',
	emoji: unicodeToEmoji(unitedkingdom),
	label: `English`,
	locale: enGB,

	translation: {
		// Titles
		titles: {
			// Usage
			'usage-over-time': 'usage over time',
			'usage-top-5': 'top 5 rooms with most usage',
			'usage-top-5-over-time': 'top 5 rooms with most usage over time',
			'usage-average': 'average usage',
			'usage-total': 'total usage',
			'usage-by-room': 'usage by room',
			'usage-by-group': 'usage by group',
			'usage-by-shift': 'usage by shift',
			'usage-by-fluid-type': 'usage by fluid',
			'room-activity': 'room activity',
			'usage-by-shift-name': 'expected usage for {{shiftName}} shift',
			'usage-shift-name': '{{shiftName}} shift',
			'expected-usage': 'expected usage',
			'nof-hand-disinfections': 'no. of hand disinfections',
			'dispenser-usage': 'dispenser usage',

			// Shift
			'shift-link': 'work shifts',
			'shift-tab': 'week Shift',
			'no-shifts': 'no shifts found',
			'confirm-delete-shift': 'delete week shift?',

			// Shift Type
			'shift-type-tab': 'shift period',
			'add-shift-type': 'add shift period',
			'edit-shift-type': 'edit shift period',
			'no-shift-types': 'no shift periods found',
			'confirm-delete-shift-type': 'delete shift period?',
			'shift-type': 'shift type',

			// Maintenance
			'maintenance-link': 'maintenance',

			// Maintenance Fluid
			'change-device-fluid': 'change device fluid',
			'device-fluid-changed': 'device fluid changed',
			'level-of-fluid': 'level of fluid',
			'confirm-bag-change': 'have you change the bag?',

			//  Maintenance Battery
			'battery-changed': 'battery changed',
			'battery-runs-out-in': 'battery runs out in',
			'power-type': 'power type',

			// Map View
			'map-view-link': 'map view',

			// Misc
			'room-name': 'room name',
			'already-exists': 'already exists',
			'show-inactive': 'show inactive',
			'serial-number': 'serial number',
			'report-a-problem': 'report a problem',
			'describe-problem': 'describe your problem',
			'search-by-room-name': 'search by room name',
			'dark-mode': 'dark mode',
			'collapse-sidebar': 'collapse sidebar',
			'patient-room': 'patient room',
			'value-not-found': 'no {{value}} found',
			'user-group': 'user group',
			'per-hour': 'per hour',
			'per-day': 'per day',
			'no-data': 'no data',
			'current-period': 'current period',
			'previous-period': 'previous period',
			'room-type': 'room type',
			'open-filter-sidebar': 'Open filter sidebar',
			'logging-out': 'Logging out',
			'logging-in': 'Logging in',

			// Data Table
			'val-of-max': '{{aVal, number}} of {{bVal, number}} results',
			'page-of-max': '{{aVal, number}} of {{bVal, number}} pages',
			'show-per-page': 'show {{val, number}}',
			'card-view': 'card view',
			'list-view': 'list view',
		},

		// Actions
		actions: {
			// Common
			'no-change': {
				title: 'no changes detected',
				desc: 'please make some changes before submitting.',
			},

			// Shift
			'shift-added': {
				title: 'week shift added',
				desc: 'week shift "{{name}}" has been added',
			},
			'shift-updated': {
				title: 'week shift updated',
				desc: 'week shifts have been been updated',
			},
			'shift-deleted': {
				title: 'week shift deleted',
				desc: 'week shift has been deleted',
			},

			// Shift Type
			'shift-type-added': {
				title: 'shift period added',
				desc: 'shift period "{{name}}" has been added',
			},
			'shift-type-updated': {
				title: 'shift period updated',
				desc: 'shift period has been updated',
			},
			'shift-type-deleted': {
				title: 'shift period deleted',
				desc: 'shift period has been deleted',
			},

			// Maintenance
			'maintenance-report-problem': {
				title: 'Report a device problem',
				desc: "Please describe the problem and we'll contact you as soon as possible",
			},
			'maintenance-report-success': {
				title: 'Problem reported',
				desc: 'Your problem has been reported and we will contact you as soon as possible',
			},

			// Maintenance - Fluid
			'maintenance-fluid-updated': {
				title: 'fluid updated',
				desc: 'fluid has been updated for device {{serialNumber}}',
			},

			// Maintenance - Battery
			'maintenance-battery-change': {
				title: 'device battery change',
				desc: 'have you changed battery for device?',
			},
			'maintenance-battery-updated': {
				title: 'Battery changed',
				desc: 'Battery has been changed for device {{serialNumber}}',
			},
		},

		// Common
		common: {
			usage: 'usage',
			start: 'start',
			end: 'end',
			name: 'name',
			department: 'department',
			room: 'room',
			fluid: 'fluid',
			status: 'status',
			battery: 'battery',
			action_one: 'action',
			action_other: 'actions',
			title: 'title',
			message: 'message',
			dashboard: 'dashboard',
			realtime: 'realtime',
			home: 'home',
			setting_one: 'setting',
			setting_other: 'settings',
			logout: 'logout',
			toilet: 'toilet',
			bath: 'bath',
			entrance: 'entrance',
			walkway: 'walkway',
			shift_one: 'shift',
			shift_other: 'shifts',
			floor_one: 'floor',
			floor_other: 'floors',
			dosage_one: 'dosage',
			dosage_other: 'dosages',
			edit: 'edit',
			week: 'week',
			active: 'active',
			cancel: 'cancel',
			submit: 'submit',
			yes: 'yes',
			no: 'no',
			role: 'role',
			profile: 'profile',
			on: 'on',
			off: 'off',
			cable: 'cable',
			current: 'current',
			previous: 'previous',
			search: 'search',
			select: 'select',
			loading: 'loading',
			apply: 'apply',
			other: 'other',
			less: 'less',
			more: 'more',
			all: 'all',
			language: 'language',
			highlight: 'highlight',
			icon: 'icon',
			count: 'count',
			heatmap: 'heatmap',
			from: 'from',
			to: 'to',
			custom: 'custom',
			preset: 'preset',
		},

		// Numbers
		numbers: {
			percentage: '{{val, number}}%',
			lte: 'less than or equal to {{val, number}}',
			lt: 'less than {{val, number}}',
			eq: 'equal to {{val, number}}',
			gt: 'greater than {{val, number}}',
			gte: 'greater than or equal to {{val, number}}',
		},

		// Dates
		dates: {
			relative: {
				default: '{{val, datetime}}',
				'seconds-ago': '{{val, number}} seconds ago',
				'minutes-ago': '{{val, number}} minutes ago',
				'hours-ago': '{{val, number}} hours ago',
				'days-ago': '{{val, number}} days ago',
				'weeks-ago': '{{val, number}} weeks ago',
				'months-ago': '{{val, number}} months ago',
				'years-ago': '{{val, number}} years ago',
				'in-seconds': 'in {{val, number}} seconds',
				'in-minutes': 'in {{val, number}} minutes',
				'in-hours': 'in {{val, number}} hours',
				'in-days': 'in {{val, number}} days',
				'in-weeks': 'in {{val, number}} weeks',
				'in-months': 'in {{val, number}} months',
				'in-years': 'in {{val, number}} years',
			},
			previous: {
				yesterday: 'yesterday',
				'last-week': 'last week',
				'last-month': 'last month',
				'last-year': 'last year',
			},
			current: {
				today: 'today',
				'this-week': 'this week',
				'this-month': 'this month',
				'this-year': 'this year',
			},
		},

		// Inputs
		inputs: {
			'pick-a-date': 'Pick a date',
			'date-presets': {
				'select-preset': 'Select a preset',
				today: 'Today',
				'this-week': 'This week',
				'this-month': 'This month',
				'this-year': 'This year',
			},
			'upload-image': 'Click here to upload image',
			'select-sections': 'Select departments',
			'select-date-range': 'Select date range',
		},

		// App Error
		'app-error': {
			'404': {
				title: 'Page Not Found',
				message: 'Sorry, the page you are looking for could not be found.',
			},
			'401': {
				title: 'Unauthorized Access',
				message:
					"You don't have permission to perform this action. Please log in or contact support for assistance.",
			},
			'500': {
				title: 'Internal Server Error',
				message:
					'Oops! Something went wrong on our end. Please try again later or contact support for assistance.',
			},
			unknown: {
				title: 'Error',
				message:
					'An error has occurred. Please try again later or contact support for assistance.',
			},
		},

		// API Error
		'api-error': {
			'400': {
				title: 'Bad Request',
				message: 'An error occurred while processing the request.',
			},
			'401': {
				title: 'Unauthorized Access',
				message: 'You are not authorized to perform this action.',
			},
			'403': {
				title: 'Access Denied',
				message: 'You do not have permission to perform this action.',
			},
			'404': {
				title: 'Resource Not Found',
				message: 'The requested resource could not be found.',
			},
			'500': {
				title: 'Internal Server Error',
				message: 'An internal server error occurred. Please try again later.',
			},
			unknown: {
				title: 'Unknown Error',
				message: 'An unknown error occurred. Please try again later.',
			},
			'signalR-connection-error': {
				title: 'Connection Error',
				message:
					'An error occurred while connecting to the SignalR server. Please try again later.',
			},
		},
	},
} satisfies LocaleObj;
