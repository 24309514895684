import { useTranslation } from '@frontend/locale';
import { Button, useTheme } from '@frontend/ui';
import { MoonIcon, SunIcon } from 'lucide-react';
import { useState } from 'react';

export function ThemeToggle() {
	const { t } = useTranslation();
	const { toggleTheme, theme } = useTheme();
	const [isDark, setIsDark] = useState(theme === 'dark');

	const onClick = () => {
		toggleTheme();
		setIsDark((prev) => !prev);
	};

	return (
		<>
			<Button
				variant="ghost"
				onClick={onClick}
				title={t('titles.dark-mode')}
				className="w-full justify-start"
			>
				{!isDark ? (
					<SunIcon className="h-4 w-4" />
				) : (
					<MoonIcon className="h-4 w-4" />
				)}
				<span className="ml-2">theme</span>
			</Button>
		</>
	);
}
