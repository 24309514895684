import {
	BaseHeatmapBox,
	BaseHeatmapColumns,
	BaseHeatmapHeader,
	BaseHeatmapRow,
	ChartColorsObj,
} from '@components';
import { useTranslation } from '@frontend/locale';
import { useTheme } from '@frontend/ui';
import { FC, ReactNode } from 'react';

export type HeatmapDataColumn = {
	header: string | number | Date;
	value: number;
};

export type BaseHeatmapData = {
	[rowName: string]: HeatmapDataColumn[];
};

type BaseHeatmapChartProps = {
	data: BaseHeatmapData;
	min: number;
	max: number;
	colorTreshold: number[];
	colors: ChartColorsObj;
	headerFormat: (header: string | number | Date) => string;
	tooltipContent?: (col: HeatmapDataColumn, rowName: string) => ReactNode;
};

export const BaseHeatmapChart: FC<BaseHeatmapChartProps> = ({
	data,
	min,
	max,
	colorTreshold,
	colors,
	headerFormat,
	tooltipContent,
}) => {
	const { t } = useTranslation();
	const { theme } = useTheme();
	const _colors = colors[theme];

	const rows: string[] = Object.keys(data);

	if (!rows.length) return <>{t('titles.no-data')}</>;

	// expect all data to have exactly the same columns
	const headers: (string | number | Date)[] = data[rows[0]].map(
		(column) => column.header,
	);

	return (
		<div className="flex h-full flex-col overflow-auto">
			<div className="relative h-full overflow-auto pr-1">
				<table className="table-fixed border-separate">
					<thead className="bg-background text-muted-foreground sticky  top-0">
						<BaseHeatmapHeader headers={headers} headerFormat={headerFormat} />
					</thead>
					<tbody className="">
						{rows.map((row) => (
							<BaseHeatmapRow key={row} title={row}>
								<BaseHeatmapColumns
									columns={data[row]}
									colorTreshold={colorTreshold}
									colors={_colors}
									min={min}
									max={max}
									tooltipContent={(col) =>
										tooltipContent ? tooltipContent(col, row) : row
									}
								/>
							</BaseHeatmapRow>
						))}
					</tbody>
				</table>
			</div>
			<div className="text-muted-foreground mt-4 flex justify-end gap-1">
				<span className="text-xs">{t('common.less')}</span>
				<div className="flex">
					{_colors.map((color) => (
						<BaseHeatmapBox key={color} color={color} />
					))}
				</div>
				<span className="text-xs">{t('common.more')}</span>
			</div>
		</div>
	);
};
