import { create } from 'zustand';
import { User, UserManager } from 'oidc-client-ts';
import { useShallow } from 'zustand/react/shallow';

type UseAuthStoreType = {
	isAuthenticated: boolean;
	manager?: UserManager;
	user?: User;
};

export const useAuthStore = create<UseAuthStoreType>(() => ({
	isAuthenticated: false,
	manager: undefined,
	user: undefined,
}));

export const setUser = (user: User) =>
	useAuthStore.setState((state) => ({ ...state, user, isAuthenticated: true }));

export const setManager = (manager: UserManager) =>
	useAuthStore.setState((state) => ({ ...state, manager }));

export const useIsAuthenticated = () =>
	useAuthStore(useShallow((state) => state.isAuthenticated));
