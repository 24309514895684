import { APIClient } from '@frontend/api';
import { useQuery } from '@tanstack/react-query';

export const useQueryGetShifts = ({
	sectionIds,
	year,
	week,
	queryKey,
}: {
	sectionIds: string[];
	year: number;
	week: number;
	queryKey?: unknown[];
}) => {
	const getData = async () =>
		APIClient.request(`/v1/shifts/{year}/{week}`, 'get', {
			params: {
				query: {
					'section-id': sectionIds,
				},
				path: {
					year,
					week,
				},
			},
		});

	return useQuery({
		queryKey: queryKey || ['shifts', year, week, sectionIds],
		queryFn: getData,
		retry: false,
	});
};
