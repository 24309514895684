import { TooltipContentProps } from '@components';
import { useTranslation } from '@frontend/locale';
import { DateRange, getPrevDate, isDateValid } from '@frontend/util';
import { ReactNode } from 'react';

type ChartTooltipProps = {
	aggrigateData?: {
		name: string;
		value: number;
	}[];
	aggrigateLabel?: string;
} & TooltipContentProps;

type ChartTooltipInlineDateProps = DateRange & {
	dataKey: string;
} & TooltipContentProps;

export const ChartTooltipDateRange = ({
	dataKey,
	from,
	to,
	preset,
	active,
	payload,
}: ChartTooltipInlineDateProps) => {
	const { t } = useTranslation();

	if (!active || !payload || !payload.length) return null;

	const pPayload = payload[0];
	const cPayload = payload[1];

	const dateStr = cPayload.payload[dataKey];

	if (!isDateValid(dateStr))
		throw new Error(
			'Data key value must but a valid date string! Are you using the correct chart tooltip?',
		);

	const date = new Date(dateStr);
	const pDate = getPrevDate({ from, to, preset, date });

	const hasTime = date.getHours() !== 0;
	const timeFormat = hasTime ? ' HH:mm' : '';

	const pLabel = t('dates.relative.default', {
		val: pDate,
		format: `LLL dd, y${timeFormat}`,
	});
	const cLabel = t('dates.relative.default', {
		val: date,
		format: `LLL dd, y${timeFormat}`,
	});

	return (
		<ChartTooltipContainer>
			<div style={{ color: pPayload.color }}>
				<span className="mr-2">{pLabel}:</span>
				<span>{pPayload.value}</span>
			</div>
			<div style={{ color: cPayload.color }}>
				<span className="mr-2">{cLabel}:</span>
				<span>{cPayload.value}</span>
			</div>
		</ChartTooltipContainer>
	);
};

export const ChartTooltip = ({
	active,
	payload,
	label,
	aggrigateData,
	aggrigateLabel,
}: ChartTooltipProps) => {
	const { t } = useTranslation();
	if (!active || !payload || !payload.length) return null;

	if (label && isDateValid(label)) {
		const date = new Date(label);
		label = t('dates.relative.default', {
			val: date,
			format: 'dd/MM/yyyy ' + (date.getHours() === 0 ? '' : 'HH:mm'),
		});
	}

	const toolTipJsx = payload.map((item, index) => {
		return (
			<div
				key={index + 'customtooltip'}
				style={{
					color: item.color || item.payload.fill,
				}}
				className={`flex justify-between`}
			>
				<span className="mr-4">{item.name}:</span>
				<span>{item.value}</span>
			</div>
		);
	});

	const aggrigateJsx = aggrigateData?.map((item, _i) => {
		return (
			<div
				key={`aggrigate_${_i}`}
				className="text-muted-foreground flex justify-between text-xs"
			>
				<span className="mr-4">{item.name}:</span>
				<span>{item.value}</span>
			</div>
		);
	});

	return (
		<ChartTooltipContainer>
			{label ? <p className="font-bold">{label}</p> : null}
			{toolTipJsx}
			{payload[0].name === aggrigateLabel && aggrigateJsx}
		</ChartTooltipContainer>
	);
};

export const ChartTooltipContainer = ({
	children,
}: {
	children: ReactNode | ReactNode[];
}) => {
	return (
		<div className="animate-in fade-in-50 data-[side=bottom]:slide-in-from-top-1 data-[side=left]:slide-in-from-right-1 data-[side=right]:slide-in-from-left-1 data-[side=top]:slide-in-from-bottom-1 bg-popover text-popover-foreground z-50 overflow-hidden rounded-md border px-3 py-1.5 text-sm shadow-md">
			{children}
		</div>
	);
};
