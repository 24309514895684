import { getLocalStorage, setLocalStorage } from '@frontend/util';
import { User } from 'oidc-client-ts';
import {
	DEFAULT_SCOPES_ARR,
	LOCAL_STORE_OA_KEY,
	OFFLINE_ACCESS_SCOPE,
	OFFLINE_GROUP_NAME,
} from './constants';

export function getURI() {
	const { search, host, protocol, pathname } = window.location;

	const queryParams = new URLSearchParams(search);
	queryParams.delete('state');
	queryParams.delete('session_state');
	queryParams.delete('code');

	const hasParams = queryParams.size > 0;
	const query = hasParams ? `?${queryParams.toString()}` : '';

	return `${protocol}//${host}${pathname}${query}`;
}

export function getScopeString(): string {
	const scopes = [...DEFAULT_SCOPES_ARR];
	if (getLocalStorage(LOCAL_STORE_OA_KEY) === 'true') {
		scopes.push(OFFLINE_ACCESS_SCOPE);
		setLocalStorage(LOCAL_STORE_OA_KEY, 'false');
	}

	return scopes.join(' ');
}

export const shouldRelogForOfflineAccess = (user: User | null | undefined) => {
	if (!user) return false;

	const { profile, scopes } = user;

	const groups = profile.groups as string[];

	const hasStaticScreenGroup = groups.some(
		(group) => group === OFFLINE_GROUP_NAME,
	);

	const hasOfflineAccessScope = scopes.some(
		(scope) => scope === OFFLINE_ACCESS_SCOPE,
	);

	if (hasStaticScreenGroup && !hasOfflineAccessScope) {
		setLocalStorage(LOCAL_STORE_OA_KEY, 'true');
		return true;
	}

	return false;
};

export const hasAuthParams = (location = window.location): boolean => {
	const searchParams = new URLSearchParams(location.search);
	if (
		(searchParams.get('code') || searchParams.get('error')) &&
		searchParams.get('state')
	) {
		return true;
	}

	return false;
};
