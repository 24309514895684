import { ComponentProps } from 'react';
import { Link as RouterLink } from 'react-router-dom';

type LinkProps = {
	children: React.ReactNode;
} & ComponentProps<typeof RouterLink>;

export function Link({ children, className, ...props }: LinkProps) {
	return (
		<RouterLink
			{...props}
			className={`text-md text-muted-foreground hover:text-primary font-medium transition-colors ${className}`}
		>
			{children}
		</RouterLink>
	);
}
