import { z } from 'zod';

export const shiftSchema = z.object({
	shifts: z.array(
		z.object({
			id: z.string(),
			employees: z.number().int().nonnegative(),
		}),
	),
});
