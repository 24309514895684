import { NoRowsFound, TableSpinner } from '@components';
import { Card, CardContent, CardHeader, CardTitle } from '@frontend/ui';
import { cn, uuid } from '@frontend/util';
import { Row, Table as TableType } from '@tanstack/react-table';

const DefaultCard = <TData,>({ row }: { row: Row<TData> }) => {
	// get all columns
	const cells = row.getAllCells();

	return (
		<Card key={uuid()} className={cn('h-52 w-52 overflow-hidden', {})}>
			<CardHeader>
				<CardTitle>Row Card</CardTitle>
			</CardHeader>
			<CardContent className="text-xs">
				{cells.map((cell) => {
					return (
						<div key={uuid()} className="flex gap-2">
							<span>{cell.column.id}</span>
							<span>{String(cell.getValue())}</span>
						</div>
					);
				})}
			</CardContent>
		</Card>
	);
};

type CardViewProps<TData> = {
	table: TableType<TData>;
	template?: React.FC<{ row: Row<TData> }>;
	isLoading?: boolean;
};

export const CardView = <TData,>({
	table,
	template,
	isLoading,
}: CardViewProps<TData>) => {
	return (
		<div className="relative h-full w-full overflow-auto p-2">
			{isLoading && <TableSpinner />}
			{!isLoading && table.getRowModel().rows.length === 0 && <NoRowsFound />}
			<div className="flex flex-wrap gap-2">
				{!isLoading &&
					table.getRowModel().rows.map((row) => {
						if (template) {
							return <div key={uuid()}>{template({ row })}</div>;
						}

						return <DefaultCard key={uuid()} row={row} />;
					})}
			</div>
		</div>
	);
};
