import { Device } from '@frontend/api';
import { Row } from '@tanstack/react-table';
//import { Ping } from '@components';
import { cn } from '@frontend/util';
import { BatteryFull, Cable } from 'lucide-react';
//import { t } from 'i18next';

const IconInfoSpan = ({
	icon,
	value,
}: {
	icon: JSX.Element;
	value: string;
}) => {
	return (
		<div className="inline-block">
			<span className="flex justify-center">
				<span className="flex w-10 justify-center">{icon}</span>
				<span className="w-10 text-center">{value}</span>
			</span>
		</div>
	);
};

export const DeviceCard = ({ row }: { row: Row<Device> }): JSX.Element => {
	const getCellValue = (key: string) => {
		return String(row.getValue(key));
	};

	//const isOn = getCellValue('isOn') === 'true';
	const serialNumber = getCellValue('serialNumber');

	return (
		<div
			className={cn({
				'flex flex-col items-center justify-center rounded-lg border p-2 shadow-sm': true,
				'h-40 w-40': true,
				//'border-green-300 bg-green-50': isOn,
				//'border-red-300 bg-red-50': !isOn,
			})}
		>
			<h2 className="w-full truncate text-center text-lg font-bold">
				{getCellValue('roomName')}
			</h2>
			<span className="text-muted-foreground mb-2 text-xs">{serialNumber}</span>

			<div className="flex flex-col items-center justify-between gap-2">
				{getCellValue('runsOnBattery') === 'true' ? (
					<IconInfoSpan
						icon={<BatteryFull />}
						value={getCellValue('batteryValue')}
					/>
				) : (
					<IconInfoSpan icon={<Cable className="" />} value="-" />
				)}

				{/**
				<IconInfoSpan
					icon={<Droplet className="stroke-sky-500" />}
					value={getCellValue('fluidValue')}
				/>
				**/}

				{/**
				<Ping
					variant={isOn ? 'success' : 'destructive'}
					pulse={!isOn}
					title={isOn ? t('common.on') : t('common.off')}
				/>
				**/}
			</div>
		</div>
	);
};
