import { Roles, usePremise } from '@frontend/context';
import { useTranslation } from '@frontend/locale';
import {
	CalendarDays,
	ExternalLink,
	LayoutDashboard,
	LucideIcon,
	MapIcon,
	Wrench,
} from 'lucide-react';

export type LinkObj = {
	label?: string;
	// if is title, label will be used as a title in the sidebar
	isTitle?: boolean;
	href: string;
	icon?: LucideIcon;
	isExternal?: boolean;
	isNotPartOfRouter?: boolean;
	children?: LinkObj[];
	hideSidebarLink?: boolean;
	hideWhenMinimized?: boolean;
	roles?: Roles[];
};

export type LinkTree = LinkObj[];

export function useLinkTree() {
	const { t } = useTranslation();

	const { sections } = usePremise();

	const keys = Object.keys(sections);

	const realtimeSectionUrls = keys.map((key) => {
		const sec = sections[key];
		return {
			label: sec.name,
			isExternal: !import.meta.env.DEV && import.meta.env.MODE !== 'preview',
			isNotPartOfRouter: true,
			icon: ExternalLink,
			href: `/realtime?sectionId=${sec.id}`,
		};
	});

	const linkTree: LinkTree = [
		{
			label: t('common.dashboard'),
			isTitle: true,
			href: '/',
			children: [
				{
					label: t('common.home'),
					href: '/home',
					icon: LayoutDashboard,
					roles: ['ComplianceOfficer'],
				},
				{
					label: t('titles.shift-link'),
					href: '/shifts',
					icon: CalendarDays,
					roles: ['ComplianceOfficer'],
				},
				{
					label: t('common.setting', { count: 2 }),
					href: '/settings',
					hideSidebarLink: true,
				},
				{
					label: t('titles.maintenance-link'),
					icon: Wrench,
					href: '/maintenance',
					roles: ['ComplianceOfficer', 'Maintenance'],
				},
				{
					label: t('titles.map-view-link'),
					href: '/map-view',
					icon: MapIcon,
					roles: ['ComplianceOfficer'],
				},
			],
			roles: ['ComplianceOfficer', 'Maintenance'],
		},
		{
			label: t('common.realtime'),
			href: 'realtime',
			isTitle: true,
			hideWhenMinimized: true,
			children: [...realtimeSectionUrls],
			roles: ['ComplianceOfficer', 'HealthWorker'],
		},
	];

	function getBreadcrumbs(path: string) {
		const parts = path.split('/').filter(Boolean); // Removes empty strings from the array
		let currentPath = '';
		const breadcrumbs = [];

		for (const part of parts) {
			currentPath += `/${part}`;
			for (const link of linkTree) {
				if (link.href === currentPath) {
					breadcrumbs.push({ label: link.label, href: link.href });
				}
				if (link.children) {
					for (const childLink of link.children) {
						if (childLink.href === currentPath) {
							breadcrumbs.push({
								label: childLink.label,
								href: childLink.href,
							});
						}
					}
				}
			}
		}
		return breadcrumbs;
	}

	return { linkTree, getBreadcrumbs };
}
