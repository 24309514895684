import { LabelValueType } from '@components';
import { useTranslation } from '@frontend/locale';
import {
	Button,
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	Popover,
	PopoverContent,
	PopoverTrigger,
	ScrollArea,
} from '@frontend/ui';
import { cn } from '@frontend/util';
import { Check, ChevronsUpDown } from 'lucide-react';
import { useState } from 'react';

export type ComboboxProps = {
	value: string;
	setValue: (val: LabelValueType) => void;
	opts: LabelValueType[];
};

export const Combobox = ({ value, setValue, opts }: ComboboxProps) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<Button
					variant="outline"
					role="combobox"
					aria-expanded={open}
					className="text-muted-foreground justify-between"
				>
					<span>
						{value
							? opts.find((opt) => opt.value === value)?.label
							: `${t('common.select')}`}
					</span>
					<ChevronsUpDown className="h-6 w-6 shrink-0 opacity-50" />
				</Button>
			</PopoverTrigger>
			<PopoverContent className="p-0">
				<Command>
					<CommandInput placeholder={`${t('common.search')}`} />
					<CommandEmpty>
						{t('titles.value-not-found', { value: '' })}
					</CommandEmpty>
					<CommandGroup>
						<ScrollArea className="h-52">
							{opts.map((opt) => (
								<CommandItem
									key={opt.value?.toString()}
									onSelect={() => {
										setValue(opt);
										setOpen(false);
									}}
								>
									<Check
										className={cn(
											'mr-2 h-4 w-4',
											value === opt.value ? 'opacity-100' : 'opacity-0',
										)}
									/>
									{opt.label}
								</CommandItem>
							))}
						</ScrollArea>
					</CommandGroup>
				</Command>
			</PopoverContent>
		</Popover>
	);
};
