import { DateRange, formatDate } from './';

export default function ({ from, to }: DateRange): [string, string] | [string] {
	const f = 'LLL dd, y';

	if (!to) {
		return [formatDate(from, f)];
	}

	const sf = formatDate(from, f),
		st = formatDate(to, f);

	if (st === sf) return [sf];

	return [sf, st];
}
