import { APIClient, UpdateDeviceFluid } from '@frontend/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

type UseMutPutDeviceFluidProps = {
	id: string;
	invalidateQueryKey?: unknown[];
	onSuccess?: (id: string) => void;
};

export const useMutPutDeviceFluid = ({
	id,
	invalidateQueryKey,
	onSuccess,
}: UseMutPutDeviceFluidProps) => {
	const queryClient = useQueryClient();

	const put = async ({ value, changedBag }: UpdateDeviceFluid) =>
		APIClient.request('/v1/devices/{id}/fluid', 'put', {
			params: {
				path: { id },
			},
			body: {
				value,
				changedBag,
			},
		});

	return useMutation({
		mutationKey: ['device', id, 'fluid'],
		mutationFn: put,
		onSuccess: () => {
			if (invalidateQueryKey)
				queryClient.invalidateQueries({
					queryKey: invalidateQueryKey,
				});
			onSuccess && onSuccess(id);
		},
	});
};
