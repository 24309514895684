import { AddHubCallback, RemoveHubCallback } from '@frontend/hook';
import { createContext } from 'react';

export type SignalRContextValue = {
	addCallback: AddHubCallback;
	removeCallback: RemoveHubCallback;
};

export const SignalRContext = createContext<SignalRContextValue | undefined>(
	undefined,
);
