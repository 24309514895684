import { useTranslation } from '@frontend/locale';
import { Button, Calendar, Popover, PopoverContent, PopoverTrigger } from '#ui';
import {
	DateRange,
	WeekYear,
	getDateRangeUsingWeekYear,
	getEndOfWeek,
	getStartOfWeek,
	getWeekYearFromDate,
} from '@frontend/util';
import { CalendarSearch } from 'lucide-react';
import { useState } from 'react';
import { rangeIncludesDate } from 'react-day-picker';

type DatePickerWeekProps = {
	value?: WeekYear;
	setValue: (value: WeekYear) => void;
	className?: string;
};

export const DatePickerWeek = ({
	value,
	setValue,
	className,
}: DatePickerWeekProps) => {
	const { t } = useTranslation();
	const [range, setRange] = useState<DateRange | undefined>(
		value ? getDateRangeUsingWeekYear(value) : undefined,
	);

	const [isOpen, setIsOpen] = useState(false);

	return (
		<Popover open={isOpen}>
			<PopoverTrigger asChild>
				<Button
					variant="outline"
					className={className}
					onClick={() => setIsOpen((prev) => !prev)}
				>
					<CalendarSearch className="mr-2 h-4 w-4" />

					{value ? `Week ${value.week} - ${value.year}` : 'Select a week'}
				</Button>
			</PopoverTrigger>
			<PopoverContent
				className="w-auto p-0"
				onInteractOutside={() => setIsOpen(false)}
			>
				<Calendar
					selected={range}
					modifiers={{
						selected: range,
						range_start: range?.from,
						range_end: range?.to,
						range_middle: (date: Date) =>
							range ? rangeIncludesDate(range, date, true) : false,
					}}
					onDayClick={(day, modifiers) => {
						if (modifiers.selected) {
							setRange(undefined); // clear the selection if the day is already selected
							return;
						}
						const from = getStartOfWeek(day);
						const to = getEndOfWeek(day);
						setRange({
							from,
							to,
							preset: 'week',
						});
						setValue(getWeekYearFromDate(day));
					}}
					components={{
						WeekNumber: ({ week }) => (
							<td className="flex items-center justify-center w-8 text-xs text-muted-foreground">
								{week.weekNumber}
							</td>
						),
					}}
					showWeekNumber
					formatters={{
						// Add `W` prefix to week number
						formatWeekNumber: (weekNumber) =>
							`${t('common.week')} - ${weekNumber}`,
					}}
				/>
			</PopoverContent>
		</Popover>
	);
};
