export function mapTimestampToChartPosition(
	timestamp: number,
	chartStartDate: number,
	chartEndDate: number,
	chartWidth: number,
) {
	// Normalize the timestamp to a range between 0 and 1
	const normalizedTimestamp =
		(timestamp - chartStartDate) / (chartEndDate - chartStartDate);

	// Map the normalized timestamp to the chart's width
	const position = normalizedTimestamp * chartWidth;

	return position;
}

export function getClosestIndex(
	position: number,
	totalWidth: number,
	totalLabels: number,
) {
	const spacing = totalWidth / (totalLabels - 1);
	return Math.round(position / spacing);
}
