import { DateRange } from '@frontend/util';
import { StoreApi, UseBoundStore, create } from 'zustand';

export type FilterSidebarRecord = Record<
	string,
	string | number | Date | DateRange | string[] | undefined
>;

type FilterSideBarStore<T extends FilterSidebarRecord> = {
	collapsed: boolean;
	setCollapsed: (collapsed: boolean) => void;
	defaultValues: T;
	filterValues: T;
	setFilterValue: <K extends keyof T>(key: K, value: T[K]) => void;
	clearFilterValues: () => void;
	clearFilterValue: <K extends keyof T>(key: K) => void;
};

export type UseStoreType<T extends FilterSidebarRecord> = UseBoundStore<
	StoreApi<FilterSideBarStore<T>>
>;

export type FilterSidebarFC<T extends FilterSidebarRecord, P> = ({
	useStore,
}: {
	useStore: UseStoreType<T>;
} & P) => JSX.Element;

export type FilterSidebarBaseProps<T extends FilterSidebarRecord, P> = {
	useStore: UseStoreType<T>;
} & P;

export const createFilterSidebarStore = <T extends FilterSidebarRecord>(
	filterValues: T,
	defaultValues: T,
) =>
	create<FilterSideBarStore<T>>((set) => ({
		collapsed: true,
		setCollapsed: (collapsed: boolean) =>
			set((state) => ({ ...state, collapsed })),
		defaultValues: defaultValues,
		filterValues: { ...filterValues },
		setFilterValue: (key: keyof T, value: T[keyof T]) => {
			set((state) => ({
				...state,
				filterValues: { ...state.filterValues, [key]: value },
			}));
		},
		// TODO: El problem, the default values must have declared as undefined for optional types
		// maybe we can instead of defaultValues, use a function to get the default values
		clearFilterValues: () =>
			set((state) => {
				return {
					...state,
					filterValues: { ...state.defaultValues },
				};
			}),
		clearFilterValue: (key: keyof T) =>
			set((state) => ({
				...state,
				filterValues: {
					...state.filterValues,
					[key]: state.defaultValues[key],
				},
			})),
	}));
