import { ReactNode, useEffect, useState } from 'react';
import { useAuth, useQueryGetRoomTypes, useQueryGetSections } from '../';
import { PremiseAll, PremiseContext } from './PremiseContext';

type PremiseProviderProps = {
	children: ReactNode | ReactNode[];
};

export const PremiseProvider = ({ children }: PremiseProviderProps) => {
	const { isAuthenticated } = useAuth();
	const [state, setState] = useState<PremiseAll>({
		sections: {},
		premises: {},
		floors: {},
		rooms: {},
		roomTypes: [],
	});

	const dataSections = useQueryGetSections(isAuthenticated);
	const dataRoomTypes = useQueryGetRoomTypes(isAuthenticated);

	useEffect(() => {
		if (!dataSections.data || !dataRoomTypes.data) return;

		const sections = dataSections.data.items;

		const newState: PremiseAll = {
			sections: {},
			premises: {},
			floors: {},
			rooms: {},
			roomTypes: dataRoomTypes.data.items,
		};

		for (const section of sections) {
			newState.sections[section.id] = section;

			const premises = section.premises;

			for (const premise of premises) {
				newState.premises[premise.id] = premise;

				const floors = premise.floors;

				for (const floor of floors) {
					newState.floors[floor.id] = floor;

					const rooms = floor.rooms;

					for (const room of rooms) {
						newState.rooms[room.id] = room;
					}
				}
			}
		}

		setState(newState);
	}, [
		dataSections.error,
		dataSections.data,
		dataRoomTypes.error,
		dataRoomTypes.data,
	]);

	const getRoomById = (id: string) => state.rooms[id];

	// App should always have sections
	if (Object.keys(state.sections).length === 0) return 'No section found';

	return (
		<PremiseContext.Provider value={{ ...state, getRoomById }}>
			{children}
		</PremiseContext.Provider>
	);
};
