import { usePremise, useQueryGetUserGroups } from '@frontend/context';
import { useURLParams } from '@frontend/hook';
import { useTranslation } from '@frontend/locale';
import { useEffect, useState } from 'react';
import { useFilterSidebar } from '../FilterSidebar';
import { getStartOfDay } from '@frontend/util';

export type ShiftsFilterType = {
	sectionId: string;
	sectionName: string;
	selectedDate: Date;
	userGroupId: string;
	userGroupName: string;
};

export const useShiftFilterSidebar = () => {
	const { t } = useTranslation();
	const { sections } = usePremise();
	const sectionOpts = Object.values(sections).map((section) => ({
		label: section.name,
		value: section.id,
	}));

	const { data } = useQueryGetUserGroups();

	const userGroupOpts =
		data?.items.map((group) => ({
			label: group.name,
			value: group.id,
		})) || [];

	const { getURLParam, setURLParam, defaultValues } =
		useURLParams<ShiftsFilterType>({
			selectedDate: getStartOfDay(new Date()),
			sectionId: sectionOpts[0]?.value,
			sectionName: sectionOpts[0]?.label,
			userGroupId: userGroupOpts[0]?.value || 'loading',
			userGroupName: userGroupOpts[0]?.label || 'loading',
		});
	const [filterState, setFilterState] = useState<ShiftsFilterType | undefined>(
		undefined,
	);

	const urlParams = getURLParam([
		'sectionId',
		'sectionName',
		'selectedDate',
		'userGroupId',
		'userGroupName',
	]);

	const { Sidebar, Select, DatePicker, ToggleButton, Badges } =
		useFilterSidebar<ShiftsFilterType>(urlParams, defaultValues);

	const applyFilter = (filters: Partial<ShiftsFilterType>) => {
		const obj: ShiftsFilterType = {
			...urlParams,
			...filters,
		};

		if (filters.sectionId) {
			const section = sections[filters.sectionId];
			obj.sectionName = section.name;
		}

		if (filters.userGroupId) {
			const userGroup = data?.items.find(
				(group) => group.id === filters.userGroupId,
			);
			if (!userGroup) console.error('User group not found');
			obj.userGroupName = userGroup?.name || '';
		}

		const keys = Object.keys(obj) as (keyof ShiftsFilterType)[];

		keys.forEach((key) => {
			const val = obj[key];
			setURLParam(key, val);
		});

		setFilterState(obj);
	};

	useEffect(() => {
		if (sectionOpts.length > 0 && data && data.items.length > 0)
			applyFilter({});
	}, [sections, data]);

	const BadgesList = () => (
		<Badges
			className="my-2"
			badges={{
				sectionName: {
					label: t('common.department'),
					value: filterState?.sectionName,
					preventOnBadgeRemove: true,
				},
				userGroupName: {
					label: t('titles.user-group'),
					value: filterState?.userGroupName,
					preventOnBadgeRemove: true,
				},
			}}
		/>
	);

	const FilterSidebar = () => {
		return (
			<Sidebar onApply={(vals) => applyFilter(vals)}>
				<Select
					label={t('common.department')}
					name="sectionId"
					selectFirst={true}
					opts={sectionOpts || []}
				/>
				<Select
					label={t('titles.user-group')}
					name="userGroupId"
					selectFirst={true}
					opts={userGroupOpts || []}
				/>
				<DatePicker
					type="week"
					label={t('inputs.pick-a-date')}
					name="selectedDate"
				/>
			</Sidebar>
		);
	};

	return { filterState, FilterSidebar, ToggleButton, applyFilter, BadgesList };
};
