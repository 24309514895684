import { APIClient, UpdateShift } from '@frontend/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

type UseMutPutShiftsProps = {
	invalidateQueryKey?: unknown[];
	onSuccess?: () => void;
};

export const useMutPutShifts = ({
	invalidateQueryKey,
	onSuccess,
}: UseMutPutShiftsProps) => {
	const queryClient = useQueryClient();

	const postData = async (shifts: UpdateShift[]) => {
		return await APIClient.request('/v1/shifts', 'put', {
			body: {
				shifts,
			},
		});
	};

	return useMutation({
		mutationKey: ['postShifts'],
		mutationFn: postData,
		onSuccess: () => {
			if (invalidateQueryKey)
				queryClient.invalidateQueries({
					queryKey: invalidateQueryKey,
				});
			onSuccess && onSuccess();
		},
	});
};
