import { getStartOfDay } from './';

export default function (time: string) {
	const [hours, minutes] = time.split(':');

	const date = getStartOfDay(new Date());
	date.setHours(Number(hours));
	date.setMinutes(Number(minutes));

	return date;
}
