import { getScopeString, getURI } from './utils';
import { OidcClientSettings, WebStorageStateStore } from 'oidc-client-ts';

export const OFFLINE_GROUP_NAME = '/static-screens';
export const LOCAL_STORE_OA_KEY = 'InitOfflineAccessV2';
export const DEFAULT_SCOPES_ARR = ['openid', 'profile', 'groups', 'email'];
export const OFFLINE_ACCESS_SCOPE = 'offline_access';
export const REFRESH_OFFSET_MULTIPLIER = 0.85;
export const LOCAL_STORAGE_AUTH_KEY = 'RESANI_AUTH_TOKEN';
export const AUTH_RETRY_TIME = 45 * 1000;

export const BASE_SETTINGS: OidcClientSettings = {
	client_id: '',
	authority: '',
	redirect_uri: getURI(),
	post_logout_redirect_uri: getURI(),
	scope: getScopeString(),
	stateStore: new WebStorageStateStore({ store: window.localStorage }),
};
