import { t } from '@frontend/locale';

export type ValidAPIErrors =
	| '404'
	| '401'
	| '500'
	| '400'
	| '403'
	| 'ERR_BAD_REQUEST'
	| 'unknown'
	| 'signalR-connection-error';

export type APIError = {
	title: string;
	message: string;
};

type APIErrors = {
	[error in ValidAPIErrors]: APIError;
};

const errorMessages: APIErrors = {
	'404': {
		title: t('api-error.404.title'),
		message: t('api-error.404.message'),
	},
	'401': {
		title: t('api-error.401.title'),
		message: t('api-error.401.message'),
	},
	'500': {
		title: t('api-error.500.title'),
		message: t('api-error.500.message'),
	},
	'400': {
		title: t('api-error.400.title'),
		message: t('api-error.400.message'),
	},
	ERR_BAD_REQUEST: {
		title: t('api-error.400.title'),
		message: t('api-error.400.message'),
	},
	'403': {
		title: t('api-error.403.title'),
		message: t('api-error.403.message'),
	},
	unknown: {
		title: t('api-error.unknown.title'),
		message: t('api-error.unknown.message'),
	},
	'signalR-connection-error': {
		title: t('api-error.signalR-connection-error.title'),
		message: t('api-error.signalR-connection-error.message'),
	},
};

export const getAPIErrorMessage = (error?: string | undefined): APIError => {
	const keys = Object.keys(errorMessages) as ValidAPIErrors[];

	for (const k of keys) {
		if (k === error) return errorMessages[k];
	}

	return errorMessages['unknown'];
};
