import { APIClient } from '@frontend/api';
import { useQuery } from '@tanstack/react-query';

export function useQueryGetUserGroups() {
	const getData = async () =>
		await APIClient.request('/v1/user-groups', 'get', {});

	return useQuery({
		queryKey: ['user-groups'],
		queryFn: getData,
	});
}
