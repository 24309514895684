import { Container, ContainerOptions, Graphics } from 'pixi.js';
import { LODContainer, PremiseText } from '.';
import BaseGraphic from './graphics/BaseGraphic';

type PremiseContainerProps = {} & ContainerOptions;

export default class FlexContainer extends Container {
	constructor(props?: PremiseContainerProps) {
		super(props);
	}

	alignChildren({
		dir = 'col',
		gap = 0,
	}: {
		dir?: 'col' | 'row';
		gap?: number;
	}) {
		let currPos = 0;

		const filtered = this.children.filter((c) => c.visible);

		filtered.forEach((child) => {
			if (
				child instanceof BaseGraphic ||
				child instanceof FlexContainer ||
				child instanceof LODContainer ||
				child instanceof PremiseText ||
				child instanceof Graphics
			) {
				const c = child;

				const size = dir === 'col' ? c.height : c.width;

				if (dir === 'col') {
					child.position.set(child.x, currPos);
				} else {
					child.position.set(currPos, child.y);
				}

				currPos += size + gap;
			}
		});
	}

	xCenterSelf() {
		if (this.parent) {
			this.position.x = (this.parent.width - this.width) / 2;
		}
	}

	yCenterSelf() {
		if (this.parent) {
			this.position.y = (this.parent.height - this.height) / 2;
		}
	}

	xCenterChildren() {
		const bounds = this.getLocalBounds();
		const filtered = this.children;

		filtered.forEach((child) => {
			const b = child.getLocalBounds();
			const calc = (bounds.width - b.width) / 2;
			child.pivot.set(b.x, b.y);
			child.position.set(calc * 0.75, child.y);
		});
	}

	yCenterChildren() {
		const bParent = this.getBounds();

		this.children.forEach((child) => {
			child.position.y = bParent.height - child.getBounds().height / 2;
		});
	}
}
