// import { useMutPutDeviceBattery } from '@hooks';
import { Device } from '@frontend/api';
import { useTranslation } from '@frontend/locale';
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogTitle,
	Button,
} from '@frontend/ui';
import { Dispatch, SetStateAction } from 'react';

export const BatteryDialog = ({
	open,
	setOpen,
	device,
}: {
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	device: Device;
}) => {
	const { t } = useTranslation();

	//const put = useMutPutDeviceBattery(device.serialNumber);

	//const onSubmit = () => {
	//	put.mutate(undefined, {
	//		onSettled: () => {
	//			setOpen(false);
	//		},
	//	});
	//};

	return (
		<AlertDialog open={open} onOpenChange={() => setOpen(!open)}>
			<AlertDialogContent>
				<AlertDialogTitle>
					{t('actions.maintenance-battery-change.title')}
				</AlertDialogTitle>
				<AlertDialogDescription>
					<span>
						{t('actions.maintenance-battery-change.desc')}{' '}
						<span className="font-bold">{device.serialNumber}</span>?
					</span>
				</AlertDialogDescription>
				<AlertDialogFooter>
					<AlertDialogCancel>{t('common.no')}</AlertDialogCancel>
					<AlertDialogAction asChild>
						<Button variant="default">{t('common.yes')}</Button>
					</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	);
};
