import { deepClone, isDateValid, parseFromUTC, parseToUTC } from '../';
import { createBrowserHistory } from 'history';

export type URLDefaultTypes =
	| string
	| number
	| Date
	| Array<string>
	| boolean
	| undefined;

export type URLRecord = Record<string, URLDefaultTypes>;

export class URLParamManager<T extends URLRecord> {
	public defaultValues: T;
	private _history = createBrowserHistory();

	constructor(defaults: T) {
		this.defaultValues = deepClone(defaults);
		this.getURLParam = this.getURLParam.bind(this);
		this.setURLParam = this.setURLParam.bind(this);
	}

	getURLParam<K extends keyof T>(keys: K[]): { [key in K]: T[key] } {
		const search = this._getSearchParams();
		const defaults = deepClone(this.defaultValues);

		for (const k of keys) {
			const param = search.getAll(k.toString());
			const value = this._parseValueFromURL<T[K]>(param, defaults[k]);
			defaults[k] = value;
		}

		return defaults;
	}

	setURLParam<K extends keyof T>(key: K, value: T[K] | undefined) {
		const search = this._getSearchParams();

		if (value === this.defaultValues[key]) {
			this._deleteParam(key);
		} else if (Array.isArray(value)) {
			this._deleteParam(key);
			for (const item of value) {
				const arr = search.getAll(key.toString());

				if (!arr.includes(item))
					search.append(key.toString(), this._seralizeForURL(value));
			}
		} else {
			search.set(key.toString(), this._seralizeForURL(value));
		}

		const url = window.location.origin + window.location.pathname;
		this._history.replace(url + '?' + search.toString());
	}

	private _getSearchParams() {
		return new URLSearchParams(window.location.search);
	}

	private _deleteParam<K extends keyof T>(key: K) {
		const search = this._getSearchParams();
		search.delete(key.toString());
	}

	private _seralizeForURL(value: URLDefaultTypes): string {
		if (value === undefined) return '';
		else if (value instanceof Date) {
			return parseToUTC(value).toISOString();
		} else if (Array.isArray(value)) {
			return value.join(',');
		} else if (typeof value === 'number') {
			return value.toString();
		}
		return value as string;
	}

	private _parseValueFromURL<T extends URLDefaultTypes>(
		value: string[],
		defaultValue: T,
	): T {
		if (value.length === 0) return defaultValue;

		const isArray = value.length > 1;

		if (isArray) {
			return value as T;
		}

		const v = value[0];

		if (!isNaN(Number(v))) {
			return Number(v) as T;
		} else if (isDateValid(new Date(v))) {
			return parseFromUTC(v) as T;
		} else if (v === 'true' || v === 'false') {
			return (v === 'true') as T;
		} else {
			return v as T;
		}
	}
}
