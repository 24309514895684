import { useTranslation } from '@frontend/locale';
import { Spinner } from '../GlobalLoader';

export const TableSpinner = () => {
	const { t } = useTranslation();

	return (
		<div className="flex h-full w-full flex-col items-center justify-center gap-4 text-2xl font-bold">
			<span>
				<Spinner className="h-14 w-14" />
			</span>
			<span>{t('common.loading')}</span>
		</div>
	);
};
