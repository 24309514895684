import { cn } from '@frontend/util';
import { HTMLAttributes } from 'react';

type TypographyProps<T extends HTMLElement> = HTMLAttributes<T>;

export function TypographyH1({
	className,
	children,
	...props
}: TypographyProps<HTMLHeadingElement>) {
	return (
		<h1
			className={cn(
				'scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl',
				className,
			)}
			{...props}
		>
			{children}
		</h1>
	);
}

export function TypographyH2({
	className,
	children,
	...props
}: TypographyProps<HTMLHeadingElement>) {
	return (
		<h2
			className={cn(
				'scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight first:mt-0',
				className,
			)}
			{...props}
		>
			{children}
		</h2>
	);
}

export function TypographyH3({
	className,
	children,
	...props
}: TypographyProps<HTMLHeadingElement>) {
	return (
		<h3
			className={cn(
				'scroll-m-20 text-2xl font-semibold tracking-tight',
				className,
			)}
			{...props}
		>
			{children}
		</h3>
	);
}

export function TypographyH4({
	className,
	children,
	...props
}: TypographyProps<HTMLHeadingElement>) {
	return (
		<h4
			className={cn(
				'scroll-m-20 text-xl font-semibold tracking-tight',
				className,
			)}
			{...props}
		>
			{children}
		</h4>
	);
}

export function TypographyP({
	className,
	children,
	...props
}: TypographyProps<HTMLParagraphElement>) {
	return (
		<p
			className={cn('leading-7 [&:not(:first-child)]:mt-6', className)}
			{...props}
		>
			{children}
		</p>
	);
}

export function TypographyBlockquote({
	className,
	children,
	...props
}: TypographyProps<HTMLQuoteElement>) {
	return (
		<blockquote
			className={cn('mt-6 border-l-2 pl-6 italic', className)}
			{...props}
		>
			{children}
		</blockquote>
	);
}

export function TypographyInlineCode({
	className,
	children,
	...props
}: TypographyProps<HTMLElement>) {
	return (
		<code
			className={cn(
				'bg-muted relative rounded px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold',
				className,
			)}
			{...props}
		>
			{children}
		</code>
	);
}

export function TypographyLead({
	className,
	children,
	...props
}: TypographyProps<HTMLParagraphElement>) {
	return (
		<p className={cn('text-muted-foreground text-xl', className)} {...props}>
			{children}
		</p>
	);
}

export function TypographyLarge({
	className,
	children,
	...props
}: TypographyProps<HTMLDivElement>) {
	return (
		<div className={cn('text-lg font-semibold', className)} {...props}>
			{children}
		</div>
	);
}

export function TypographySmall({
	className,
	children,
	...props
}: TypographyProps<HTMLElement>) {
	return (
		<small
			className={cn('text-sm font-medium leading-none', className)}
			{...props}
		>
			{children}
		</small>
	);
}

export function TypographyMuted({
	className,
	children,
	...props
}: TypographyProps<HTMLParagraphElement>) {
	return (
		<p className={cn('text-muted-foreground text-sm', className)} {...props}>
			{children}
		</p>
	);
}
