import { LabelValueType } from '@components';
import {
	Label,
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@frontend/ui';
import { cn, uuid } from '@frontend/util';
import { useEffect } from 'react';
import { FilterSidebarBaseProps, FilterSidebarRecord } from '../store';

type ExtendedLabelValueType<T, K extends keyof T> = LabelValueType & {
	value?: T[K];
};

type BaseSelectProps<T extends FilterSidebarRecord> = FilterSidebarBaseProps<
	T,
	{
		name: keyof T;
		label: string;
		opts: ExtendedLabelValueType<T, keyof T>[];
		selectFirst?: boolean;
		onSelect?: (value: T[keyof T]) => void;
	}
>;

export const BaseSelect = <T extends FilterSidebarRecord>({
	name,
	label,
	opts,
	selectFirst,
	onSelect,
	useStore,
}: BaseSelectProps<T>) => {
	const { filterValues, setFilterValue } = useStore();

	const selected = filterValues[name];

	if (selected !== undefined && typeof selected !== 'string') {
		throw new Error('Filter value must be a string');
	}

	useEffect(() => {
		if (selectFirst && selected === undefined && opts.length > 0) {
			setFilterValue(name, opts[0].value as T[keyof T]);
		}
	}, [selected]);

	return (
		<div>
			<Label>{label}</Label>
			<Select
				// TODO: Can we make this more type-safe?
				value={selected as string}
				onValueChange={(val) => {
					onSelect && onSelect(val as T[keyof T]);
					setFilterValue(name, val as T[keyof T]);
				}}
			>
				<SelectTrigger className={cn('bg-background h-9 w-full')}>
					<SelectValue />
				</SelectTrigger>
				<SelectContent>
					<SelectGroup>
						{opts.map((opt) => {
							return (
								<SelectItem
									key={uuid()}
									value={opt.value !== undefined ? (opt.value as string) : ''}
								>
									{opt.label}
								</SelectItem>
							);
						})}
					</SelectGroup>
				</SelectContent>
			</Select>
		</div>
	);
};
