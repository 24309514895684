import { SelectRole } from '@components';
import { useTranslation } from '@frontend/locale';
import {
	Button,
	Card,
	CardContent,
	CardHeader,
	CardTitle,
	Label,
} from '@frontend/ui';

export const Settings = () => {
	const { t } = useTranslation();

	return (
		<Card className="max-w-xl">
			<CardHeader>
				<CardTitle>{t('common.profile')}</CardTitle>
			</CardHeader>
			<CardContent className="flex flex-col">
				<div>
					<Label>{t('common.role')}</Label>
					<SelectRole />
				</div>
				<div className="mt-4 flex justify-end">
					<Button>{t('common.submit')}</Button>
				</div>
			</CardContent>
		</Card>
	);
};
