import { ErrorDisplay, Navbar, SidebarAndContent } from '@components';
import { useAuth } from '@frontend/context';
import { useScreenLoader } from '@frontend/ui';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export const DashboardLayout = () => {
	const { hasRole, getRoles } = useAuth();
	const { active, removeLoader } = useScreenLoader('auth');
	const navigate = useNavigate();
	const loc = useLocation();
	const roles = getRoles();

	useEffect(() => {
		removeLoader();
	}, [active]);

	const VALID_ROLES = ['Maintenance', 'HealthWorker', 'ComplianceOfficer'];
	const parsedRoles = roles.filter((role) => VALID_ROLES.includes(role));

	const isMaintenanceOnly = hasRole('Maintenance') && parsedRoles.length === 1;
	const isHealthWorkerOnly =
		hasRole('HealthWorker') && parsedRoles.length === 1;

	if (parsedRoles.length === 0) {
		// have to use all of these properties to get the error boundary to work
		// TODO: Improve?
		throw {
			data: '',
			internal: false,
			status: 401,
			statusText: 'Unauthorized',
		};
	}

	useEffect(() => {
		if (isMaintenanceOnly)
			if (loc.pathname !== '/dashboard/maintenance') navigate('maintenance');
	}, [loc]);

	if (isHealthWorkerOnly) {
		return (
			<SidebarAndContent>
				<Navbar />
				<div className="flex h-full w-full flex-col overflow-auto p-4">
					<ErrorDisplay
						title="Unauthorized"
						description="You are not authorized to view this section."
						returnUrl={false}
					/>
				</div>
			</SidebarAndContent>
		);
	}

	if (isMaintenanceOnly) {
		return (
			<SidebarAndContent>
				<Navbar />
				<div className="flex h-full w-full flex-col overflow-auto p-4">
					<Outlet />
				</div>
			</SidebarAndContent>
		);
	}

	return (
		<SidebarAndContent>
			<Navbar />
			<div className="flex h-full w-full flex-col overflow-auto p-4">
				<Outlet />
			</div>
		</SidebarAndContent>
	);
};
