import { ChartLabel, ChartLegend, ChartSerie, ChartTooltip } from '@components';
import { t } from '@frontend/locale';
import { Theme, useTheme } from '@frontend/ui';
import {
	Cell,
	Legend,
	Pie,
	PieChart,
	ResponsiveContainer,
	Tooltip,
} from 'recharts';
import * as TWColors from 'tailwindcss/colors';

export type PieChartSerieKeys = 'value';

export type BasePieChartProps = {
	labels: ChartLabel[];
	series: ChartSerie[];
	hasLegend?: boolean;
	hasTooltip?: boolean;
	colors?: {
		[key in Theme]: string[];
	};
	aggrigateAfter?: number;
	aggrigateLabel?: string;
};

export function BasePieChart({
	labels,
	series,
	hasLegend = true,
	hasTooltip = true,
	colors = {
		light: [
			TWColors['red']['500'],
			TWColors['green']['500'],
			TWColors['sky']['500'],
			TWColors['purple']['500'],
			TWColors['yellow']['500'],
			TWColors['gray']['500'],
		],
		dark: [
			TWColors['red']['500'],
			TWColors['green']['500'],
			TWColors['sky']['500'],
			TWColors['purple']['500'],
			TWColors['yellow']['500'],
			TWColors['gray']['500'],
		],
	},
	// after how many slices should we aggrigate the rest
	aggrigateAfter = 5,
	aggrigateLabel = t('common.other'),
}: BasePieChartProps) {
	const { theme } = useTheme();

	aggrigateAfter =
		aggrigateAfter > series.length ? series.length : aggrigateAfter;

	if (series.length === 0)
		return (
			<div className="flex h-full w-full items-center justify-center">
				<div className="text-medium bg-muted flex h-44 w-44 items-center justify-center rounded-full lg:h-80 lg:w-80">
					{t('titles.no-data')}
				</div>
			</div>
		);

	const sliceJsx = series.map((slice, _i) => {
		const color = colors[theme][_i];
		return <Cell key={`${slice}-${_i}`} fill={color} />;
	});

	const merged = series.map((item, _i) => {
		return {
			name: labels[_i] as string,
			value: item.value,
		};
	});

	// get top by aggrigateAfter
	const sorted = merged.sort((a, b) => (a.value < b.value ? 1 : -1));

	// initial visible series
	const visibleSeries = sorted.splice(0, aggrigateAfter);

	// combine all other series if exists
	if (sorted.length) {
		const combined = sorted.reduce(
			(acc, curr) => {
				acc.value += curr.value;
				return acc;
			},
			{ value: 0, name: aggrigateLabel },
		);

		visibleSeries.push(combined);
	}

	return (
		<div className="h-44  w-full lg:h-80">
			<ResponsiveContainer
				width="100%"
				height="100%"
				className="text-[0.6rem] sm:text-xs md:text-base"
			>
				<PieChart margin={{ top: 0, bottom: 0, left: 0, right: 0 }}>
					<Pie
						data={visibleSeries}
						dataKey="value"
						innerRadius={0}
						labelLine={true}
						label
						legendType="line"
					>
						{sliceJsx}
					</Pie>
					{hasLegend && (
						<Legend
							align="right"
							layout="vertical"
							verticalAlign="middle"
							content={<ChartLegend className="flex-col" />}
						/>
					)}
					{hasTooltip && (
						<Tooltip
							cursor={{
								fill: 'rgba(0,0,0,0.1)',
							}}
							content={
								<ChartTooltip
									aggrigateData={sorted}
									aggrigateLabel={aggrigateLabel}
								/>
							}
						/>
					)}
				</PieChart>
			</ResponsiveContainer>
		</div>
	);
}
