import { APIClient } from '@frontend/api';
import { useMutation } from '@tanstack/react-query';

type UseMutPostMessageProps = {
	onSuccess?: () => void;
};

export const useMutPostMessage = ({ onSuccess }: UseMutPostMessageProps) => {
	const post = async ({
		title,
		content,
		images,
	}: {
		title: string;
		content: string;
		images: File[];
	}) => {
		const formData = new FormData();
		formData.append('Title', title);
		formData.append('Content', content);

		images.forEach((image, index) => {
			const extension = image.name.split('.').pop();
			formData.append('Images', image, `image-${index}.${extension}`);
		});

		return APIClient.request('/v1/messages', 'post', {
			body: formData,
			contentType: 'multipart/form-data',
		});
	};

	return useMutation({
		mutationKey: ['postMessage'],
		mutationFn: post,
		onSuccess: () => {
			onSuccess && onSuccess();
		},
	});
};
