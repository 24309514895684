import { useTranslation } from '@frontend/locale';
import {
	Button,
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuTrigger,
	Label,
} from '@frontend/ui';
import { uuid } from '@frontend/util';
import { ChevronDown } from 'lucide-react';
import { useEffect } from 'react';
import { FilterSidebarBaseProps, FilterSidebarRecord } from '../store';

type BaseSelectMultiProps<T extends FilterSidebarRecord> =
	FilterSidebarBaseProps<
		T,
		{
			name: keyof T;
			label?: string;
			opts: { label: string; value: string }[];
			selectFirst?: boolean;
		}
	>;

export function BaseSelectMulti<T extends FilterSidebarRecord>({
	name,
	label,
	opts,
	selectFirst,
	useStore,
}: BaseSelectMultiProps<T>) {
	const { t } = useTranslation();
	const { filterValues, setFilterValue } = useStore();

	let selected = filterValues[name] as string[];

	if (selected && !Array.isArray(selected)) selected = [selected];

	useEffect(() => {
		if (selectFirst && selected === undefined && opts.length > 0) {
			setFilterValue(name, [opts[0].value] as T[keyof T]);
		}
	}, [selected]);

	return (
		<>
			{label && <Label>{label}</Label>}
			<DropdownMenu modal={false}>
				<DropdownMenuTrigger asChild>
					<Button
						variant="outline"
						className="text-muted-foreground justify-between"
					>
						<span>{t('common.select')}...</span>
						<ChevronDown className="h-6 w-6" />
					</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent className="w-56">
					{opts.map((opt) => {
						const checked = selected.includes(opt.value);
						return (
							<DropdownMenuCheckboxItem
								key={uuid()}
								checked={checked}
								onCheckedChange={(checked) => {
									// prevent last item from being unchecked
									if (selected.length === 1 && !checked) return;

									if (checked && opt.value) {
										const arr = [...selected, opt.value] as T[keyof T];
										setFilterValue(name, arr);
									} else {
										const arr = selected.filter(
											(v) => v !== opt.value,
										) as T[keyof T];
										setFilterValue(name, arr);
									}
								}}
							>
								{opt.label}
							</DropdownMenuCheckboxItem>
						);
					})}
				</DropdownMenuContent>
			</DropdownMenu>
		</>
	);
}
