import { getLocalStorage, setLocalStorage } from '@frontend/util';
import { createContext, useContext, useState } from 'react';

export type Theme = 'light' | 'dark';

export type ThemeContextValue = {
	theme: Theme;
	toggleTheme: () => void;
};

export type ThemeContextProviderProps = {
	children: React.ReactNode;
};

export const ThemeContext = createContext<ThemeContextValue | undefined>(
	undefined,
);

const themeStore = getLocalStorage('theme') as Theme | undefined;

export const ThemeProvider = ({ children }: ThemeContextProviderProps) => {
	const [theme, setTheme] = useState<Theme>(themeStore ?? 'light');

	const toggleTheme = () => {
		setTheme((prevTheme) => {
			const newTheme = prevTheme === 'light' ? 'dark' : 'light';
			setLocalStorage('theme', newTheme);
			return newTheme;
		});
	};

	return (
		<ThemeContext.Provider value={{ theme, toggleTheme }}>
			{children}
		</ThemeContext.Provider>
	);
};

export const useTheme = () => {
	const context = useContext(ThemeContext);
	if (context === undefined) {
		throw new Error(
			'useThemeContext must be used within a ThemeContextProvider',
		);
	}
	return context;
};
