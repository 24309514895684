import { ChartMargin } from '@components';
import { AreaChart } from 'lucide-react';
import { PropsWithChildren } from 'react';
import {
	BarChart,
	LineChart,
	PieChart,
	ResponsiveContainer,
	ScatterChart,
} from 'recharts';
import { chartConfig } from './config';

type BaseChartType = 'bar' | 'line' | 'pie' | 'area' | 'scatter';

type AcceptedDataType = {
	[key: string]: string | number | Date;
};

type BaseChartContainerProps = {
	type: BaseChartType;
	data?: AcceptedDataType[];
	height?: number | string;
	width?: number | string;
	margin?: ChartMargin;
	layout?: 'horizontal' | 'vertical';
};

type ChartCompObjMap = {
	line: typeof LineChart;
	bar: typeof BarChart;
	pie: typeof PieChart;
	area: typeof AreaChart;
	scatter: typeof ScatterChart;
};

const obj: ChartCompObjMap = {
	line: LineChart,
	bar: BarChart,
	pie: PieChart,
	area: AreaChart,
	scatter: ScatterChart,
};

export const BaseChartContainer = ({
	type,
	data,
	height,
	width,
	margin = chartConfig.margin,
	layout,
	children,
}: PropsWithChildren<BaseChartContainerProps>) => {
	const Comp = obj[type];

	return (
		<ResponsiveContainer height={height} width={width}>
			<Comp data={data} margin={margin} layout={layout}>
				{children}
			</Comp>
		</ResponsiveContainer>
	);
};
