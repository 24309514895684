import { DosagesByRoomHeatmapChart, Spinner } from '@components';
import { useQueryGetUsageInPeriod } from '@frontend/context';
import { useTranslation } from '@frontend/locale';
import { Card, CardContent, CardHeader, CardTitle } from '@frontend/ui';
import { DateRange } from '@frontend/util';
import { useRef } from 'react';

type DosagesByRoomHeatmapCardProps = DateRange & {
	sectionIds: string[];
	roomTypeIds?: string[];
};

export const DosagesByRoomHeatmapCard = ({
	from,
	to,
	preset,
	sectionIds,
	roomTypeIds,
}: DosagesByRoomHeatmapCardProps) => {
	const { t } = useTranslation();
	const ref = useRef<HTMLDivElement>(null);

	const { isLoading, data } = useQueryGetUsageInPeriod({
		from,
		to,
		roomTypeIds: roomTypeIds || [],
		sectionIds: sectionIds || [],
		roomIds: [],
	});

	return (
		<Card className="">
			<CardHeader>
				<CardTitle>{t('titles.room-activity')}</CardTitle>
			</CardHeader>
			<CardContent className="flex h-96 items-center justify-center " ref={ref}>
				{isLoading && <Spinner className="h-20 w-20" />}
				{!isLoading && (
					<DosagesByRoomHeatmapChart
						transactions={data?.transactions}
						from={from}
						to={to}
						preset={preset}
					/>
				)}
			</CardContent>
		</Card>
	);
};
