import { DialogFormField, deviceSchema } from '@components';
import { Device } from '@frontend/api';
import { useMutPutDeviceFluid } from '@frontend/context';
import { useTranslation } from '@frontend/locale';
import {
	Button,
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	Form,
	useToast,
} from '@frontend/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

export const FluidDialog = ({
	open,
	setOpen,
	device,
}: {
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	device: Device;
}) => {
	const { t } = useTranslation();
	const { toast } = useToast();
	type Infered = z.infer<typeof deviceSchema>;

	const form = useForm<Infered>({
		resolver: zodResolver(deviceSchema),
		defaultValues: {
			value: 100,
			changedBag: true,
		},
	});

	const put = useMutPutDeviceFluid({
		id: device.serialNumber,
		invalidateQueryKey: ['devices'],
		onSuccess: (id) => {
			toast({
				title: t('actions.maintenance-fluid-updated.title'),
				description: t('actions.maintenance-fluid-updated.desc', {
					serialNumber: id,
				}),
				variant: 'default',
			});
		},
	});

	const onSubmit = (data: Infered) => {
		put.mutate(data, {
			onSettled() {
				form.reset();
				setOpen(false);
			},
		});
	};

	return (
		<Dialog open={open} onOpenChange={() => setOpen(!open)}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>{t('titles.change-device-fluid')}</DialogTitle>
					<span className="text-muted-foreground text-sm">
						{device.serialNumber} - {device.room.name}
					</span>
				</DialogHeader>
				<Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)}>
						<DialogFormField
							control={form.control}
							name="value"
							label={t('titles.level-of-fluid')}
							type="slider"
						/>
						<DialogFormField
							control={form.control}
							name="changedBag"
							label={t('titles.confirm-bag-change')}
							isCheckbox={true}
						/>
						<DialogFooter className="mt-4 flex justify-between">
							<Button
								variant="ghost"
								onClick={() => {
									form.reset();
									setOpen(false);
								}}
								type="reset"
							>
								{t('common.cancel')}
							</Button>
							<Button isLoading={put.isPending} type="submit">
								{t('common.submit')}
							</Button>
						</DialogFooter>
					</form>
				</Form>
			</DialogContent>
		</Dialog>
	);
};
