import { cn } from '@frontend/util';
import { HTMLAttributes, memo } from 'react';

type RLogoProps = HTMLAttributes<SVGSVGElement>;

function Logo({ className, ...props }: RLogoProps) {
	return (
		<svg className={cn('', className)} viewBox="0 0 27 33" {...props}>
			<path d="M19.344 28.006h7.477v4.044h-7.87c-1.212 0-2.15-.183-2.756-.547-.635-.365-1.15-1.095-1.574-2.19l-4.45-11.16H4.934V32.05H0V0h15.287c3.33 0 5.873.79 7.599 2.341 1.756 1.551 2.603 3.801 2.603 6.72 0 2.646-.787 4.805-2.361 6.508-1.574 1.703-4.178 2.585-7.78 2.615l3.996 9.822zM4.934 14.504h9.808c1.998 0 3.421-.456 4.269-1.368.878-.912 1.302-2.25 1.302-4.014 0-1.611-.455-2.858-1.363-3.77-.908-.912-2.3-1.338-4.207-1.338H4.934v10.49z"></path>
		</svg>
	);
}

export const RLogo = memo(Logo);
