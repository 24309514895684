import { BaseHeatmapBox, HeatmapDataColumn } from '@components';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@frontend/ui';
import { getColorIndex, getPctInRange, uuid } from '@frontend/util';
import { FC, ReactNode } from 'react';

type BaseHeatmapColumnsProps = {
	columns: HeatmapDataColumn[];
	tooltipContent: (col: HeatmapDataColumn) => ReactNode;
	colorTreshold: number[];
	colors: string[];
	min: number;
	max: number;
};

type BaseHeatmapColumnProps = {
	column: HeatmapDataColumn;
	color: string;
	tooltipContent: (col: HeatmapDataColumn) => ReactNode;
};

const BaseHeatmapColumn: FC<BaseHeatmapColumnProps> = ({
	column,
	color,
	tooltipContent,
}) => (
	<td>
		<TooltipProvider
			skipDelayDuration={0}
			delayDuration={0}
			disableHoverableContent={true}
		>
			<Tooltip>
				<TooltipTrigger asChild>
					<BaseHeatmapBox color={color} />
				</TooltipTrigger>
				<TooltipContent>
					{tooltipContent ? tooltipContent(column) : column.header.toString()}
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	</td>
);

export const BaseHeatmapColumns: FC<BaseHeatmapColumnsProps> = ({
	columns,
	tooltipContent,
	colorTreshold,
	colors,
	min,
	max,
}) => (
	<>
		{columns.map((column) => {
			const value = getPctInRange(min, max, column.value);
			const colorIndex = getColorIndex(colorTreshold, value) || 0;

			return (
				<BaseHeatmapColumn
					key={uuid()}
					column={column}
					tooltipContent={tooltipContent}
					color={colors[colorIndex]}
				/>
			);
		})}
	</>
);
