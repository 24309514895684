import { Button } from '@frontend/ui';
import { FilterX } from 'lucide-react';
import { FilterSidebarBaseProps, FilterSidebarRecord } from '../store';

type BaseClearButtonProps<T extends FilterSidebarRecord> =
	FilterSidebarBaseProps<
		T,
		{
			onClear?: (values: T) => void;
		}
	>;

export const BaseClearButton = <T extends FilterSidebarRecord>({
	useStore,
	onClear,
}: BaseClearButtonProps<T>) => {
	const { clearFilterValues, defaultValues } = useStore();
	return (
		<Button
			onClick={() => {
				clearFilterValues();
				onClear && onClear(defaultValues);
			}}
			className=""
			variant="outline"
		>
			<FilterX />
		</Button>
	);
};
