import { formatDate, isDateValid } from '@frontend/util';
import i18n, { InitOptions, InterpolationOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next, I18nextProvider } from 'react-i18next';

import {
	getLocaleFromLocalStorage,
	getLocaleFromString,
	locales,
	setDateLocale,
} from './locales';

const format: InterpolationOptions['format'] = (
	value,
	type,
	lng,
	formatParams,
) => {
	if (isDateValid(value) && type === 'datetime') {
		if (!formatParams?.format) throw new Error('Missing date format');
		if (!lng) throw new Error('Missing language');

		const locale = getLocaleFromString(lng);
		return formatDate(value, formatParams?.format?.toString() || 'dd/MM/yyyy', {
			locale: locale.locale,
		});
	}
	return value;
};

const defaultOptions: InitOptions = {
	debug: false,
	fallbackLng: 'en',
	interpolation: {
		escapeValue: false,
		format,
	},
	resources: {
		en: {
			translation: locales.en.translation,
		},
		nb: {
			translation: locales.nb.translation,
		},
		nn: {
			translation: locales.nn.translation,
		},
		se: {
			translation: locales.se.translation,
		},
	},
};

export function initI18n() {
	i18n.use(LanguageDetector).use(initReactI18next).init(defaultOptions);

	i18n.on('languageChanged', (lng) => {
		document.documentElement.setAttribute('lang', lng);
	});

	setDateLocale(getLocaleFromLocalStorage());
}

export { i18n, I18nextProvider };
