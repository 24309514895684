import { Combobox } from '@components';
import { Label } from '@frontend/ui';
import { FilterSidebarBaseProps, FilterSidebarRecord } from '../store';

type BaseComboboxProps<T extends FilterSidebarRecord> = FilterSidebarBaseProps<
	T,
	{
		name: keyof T;
		label: string;
		opts: { label: string; value: string }[];
		selectFirst?: boolean;
		onSelect?: (value: T[keyof T]) => void;
	}
>;

export const BaseCombobox = <T extends FilterSidebarRecord>({
	name,
	label,
	opts,
	useStore,
}: BaseComboboxProps<T>) => {
	const { filterValues, setFilterValue } = useStore();

	const selected = filterValues[name];

	return (
		<>
			<Label>{label}</Label>
			<Combobox
				opts={opts}
				value={selected as string}
				setValue={(val) => {
					setFilterValue(name, val.value as T[keyof T]);
				}}
			/>
		</>
	);
};
