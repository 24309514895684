import { BasePieChart, ChartLabel, ChartSerie } from '@components';
import { Transaction } from '@frontend/api';
import { usePremise } from '@frontend/context';
import { useEffect, useState } from 'react';

export type DosagesPieChartProps = {
	transactions: Transaction[];
};

export function DosagesPieChart({ transactions }: DosagesPieChartProps) {
	const { getRoomById } = usePremise();

	const [chartData, setChartData] = useState<{
		labels: ChartLabel[];
		series: ChartSerie[];
	}>({
		labels: [],
		series: [],
	});

	useEffect(() => {
		const labels: string[] = [];
		const series: ChartSerie[] = [];

		transactions.forEach((transaction) => {
			const { roomId } = transaction;
			const room = getRoomById(roomId);

			if (!room) return;

			const roomName = room.name;

			const serieIndex = labels.indexOf(roomName);

			if (serieIndex < 0) {
				labels.push(roomName);
				series.push({
					value: 1,
				});
			} else {
				series[serieIndex].value += 1;
			}
		});

		setChartData({ labels, series });
	}, [transactions, getRoomById]);

	return <BasePieChart {...chartData} />;
}
