import { getThemeColor } from '@frontend/ui';
import { setPremiseStore } from '../../';
import BaseGraphic from './BaseGraphic';

export class ZoneGraphic extends BaseGraphic {
	private isHovered = false;
	private isActive = false;
	private isHeatmapEnabled = false;
	private heatmapColor = 'transparent';

	constructor(path: string, nodeId: string) {
		super({
			dpath: path,
			style: {
				strokeWidth: 0,
				strokeInset: true,
				fillColor: 'transparent',
			},
		});

		this.setupEventHandlers(nodeId);
	}

	setupEventHandlers(nodeId: string) {
		this.on('mouseenter', () => {
			setPremiseStore({ hoverNodeId: nodeId });
		});

		this.on('mouseleave', () => {
			setPremiseStore({ hoverNodeId: nodeId });
		});

		this.on('pointerdown', () => {
			setPremiseStore({ activeNodeId: nodeId });
		});
	}

	toggleActive(is: boolean) {
		this.isActive = is;
		this.updateStyles();
	}

	toggleHover(is: boolean) {
		this.isHovered = is;
		this.updateStyles();
	}

	updateStyles() {
		if (this.isActive) {
			this.isHovered = false;
			this.setBaseStyle();
			this.setActiveStyle();
		} else if (this.isHovered) {
			this.setHoverStyle();
		} else {
			this.setBaseStyle();
		}

		if (this.isHeatmapEnabled) {
			this.setHeatmapColor(this.heatmapColor); // Applies on top of other styles
		}
	}

	toggleHeatmapColor(color: string = 'defaultHeatmapColor') {
		this.isHeatmapEnabled = !this.isHeatmapEnabled;
		this.heatmapColor = color; // You can pass a different color to change the heatmap color
		this.updateStyles();
	}

	setHoverStyle() {
		this.setStyle({
			fillColor: this.heatmapColor,
			strokeColor: getThemeColor('primary'),
			strokeWidth: 5,
			strokeAlpha: 0.5,
		});
	}

	setActiveStyle() {
		this.setStyle({
			fillColor: this.heatmapColor,
			strokeColor: getThemeColor('primary'),
			strokeWidth: 5,
			strokeAlpha: 1,
		});
	}

	setHeatmapColor(color: string) {
		this.heatmapColor = color;
		this.setStyle({
			fillColor: color, // Apply heatmap color
		});
	}

	removeHeatmapColor() {
		this.heatmapColor = 'transparent';
		this.updateStyles();
	}

	setBaseStyle() {
		this.setStyle({
			strokeWidth: 0,
			strokeColor: 'transparent',
			strokeAlpha: 0,
			fillColor: this.heatmapColor,
		});
	}
}
