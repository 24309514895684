import { sub } from 'date-fns/sub';
import getDateAlters from './getDateAlters';
import { DateRange } from './types';

export default function ({
	from,
	to,
	preset,
	date,
}: DateRange & { date: Date }) {
	const alter = getDateAlters({ from, to, preset });
	return sub(date, alter);
}
