import { AxiosError } from '@frontend/api';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';

const onError = (err: unknown) => {
	if (err instanceof AxiosError) {
		const code = err.response?.status || err.request?.status || err.code;

		// bit of a hack, but this is the only way to prevent the toast from popup up
		// when quering shifts that don't exist as that returns a 404 and axios
		// throws an error
		if (err?.response?.data.title === 'Not Found' && code === 404) {
			return;
		}
		// const errorMessage = getAPIErrorMessage(code.toString());
		// toast({
		// 	title: errorMessage.title,
		// 	description: errorMessage.message,
		// 	variant: 'destructive',
		// });
	}
};

const queryClient = new QueryClient({
	queryCache: new QueryCache({
		onError,
	}),
	mutationCache: new MutationCache({
		onError,
	}),
});

export { queryClient };
