import { useEffect, useState } from 'react';

type CountdownProps = {
	ms: number;
};

export const Countdown = ({ ms }: CountdownProps) => {
	const [seconds, setSeconds] = useState<number>(ms / 1000);
	useEffect(() => {
		const interval = setInterval(() => {
			setSeconds((prev) => prev - 1);
			if (seconds === 0) {
				clearInterval(interval);
			}
		}, 1000);
		return () => clearInterval(interval);
	}, [ms]);
	return <>{seconds}</>;
};
