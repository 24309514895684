import { getDay, isWithinInterval } from 'date-fns';
import { endOfDay } from 'date-fns/endOfDay';
import { endOfMonth } from 'date-fns/endOfMonth';
import { endOfWeek } from 'date-fns/endOfWeek';
import { endOfYear } from 'date-fns/endOfYear';
import { startOfDay } from 'date-fns/startOfDay';
import { startOfMonth } from 'date-fns/startOfMonth';
import { startOfWeek } from 'date-fns/startOfWeek';
import { startOfYear } from 'date-fns/startOfYear';

export const getStartOfDay = (date: Date) => {
	return startOfDay(date);
};

export const getEndOfDay = (date: Date) => {
	return endOfDay(date);
};

export const getStartOfWeek = (date: Date) => {
	return startOfWeek(date);
};

export const getEndOfWeek = (date: Date) => {
	return endOfWeek(date);
};

export const getStartOfMonth = (date: Date) => {
	return startOfMonth(date);
};

export const getEndOfMonth = (date: Date) => {
	return endOfMonth(date);
};

export const getStartOfYear = (date: Date) => {
	return startOfYear(date);
};

export const getEndOfYear = (date: Date) => {
	return endOfYear(date);
};

export const isDateInRange = isWithinInterval;
export const getDayOfWeek = getDay;
