import { SectionListItem } from '@frontend/api';
import { usePremise } from '@frontend/context';
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@frontend/ui';
import { cn } from '@frontend/util';
import { useEffect, useState } from 'react';

export const SelectSection = ({
	value = '',
	onChange,
	selectFirst = true,
	className,
}: {
	value?: string;
	onChange: (section: SectionListItem) => void;
	selectFirst?: boolean;
	className?: string;
}) => {
	const [selected, setSelected] = useState<string>(value);

	const { sections } = usePremise();

	useEffect(() => {
		const values = Object.values(sections);
		if (values.length && selectFirst && !value) {
			setSelected(values[0].id);
			onChange(values[0]);
		}
	}, [sections]);

	return (
		<Select
			value={selected}
			onValueChange={(val) => {
				setSelected(val);
				const section = sections[val];

				if (section) {
					onChange(section);
				}
			}}
		>
			<SelectTrigger className={cn('bg-background h-9 w-full', className)}>
				<SelectValue />
			</SelectTrigger>
			<SelectContent>
				<SelectGroup>
					{Object.keys(sections).map((section) => {
						const item = sections[section];
						return (
							<SelectItem key={item.id} value={item.id}>
								{item.name}
							</SelectItem>
						);
					})}
				</SelectGroup>
			</SelectContent>
		</Select>
	);
};
