import { uuid } from '@frontend/util';
import { FC, PropsWithChildren } from 'react';

type HeatmapRowProps = PropsWithChildren<{
	title: string;
}>;

export const BaseHeatmapRow: FC<HeatmapRowProps> = ({ title, children }) => (
	<tr key={uuid()} className="group border border-transparent">
		<td
			width={10}
			className="text-eclipse text-muted-foreground w-[150px] max-w-[50px] truncate pr-2  text-right"
		>
			{title}
		</td>
		{children}
	</tr>
);
