import { Button } from '@frontend/ui';
import { formatDate, getDateFromWeekYear, getEndOfWeek } from '@frontend/util';
import { ChevronLeft, ChevronRight } from 'lucide-react';

export const WeekSelector = ({
	week,
	year,
	onClick,
}: {
	week: number;
	year: number;
	onClick?: (week: number, year: number) => void;
}) => {
	const setUrlParams = (week: number, year: number) => {
		onClick && onClick(week, year);
	};

	return (
		<div className="flex flex-1 items-center justify-between">
			<Button
				className=""
				variant="ghost"
				onClick={() => {
					const prevWeek = week === 1 ? 52 : week - 1;
					const prevYear = prevWeek === 52 ? year - 1 : year;
					setUrlParams(prevWeek, prevYear);
				}}
			>
				<ChevronLeft />
			</Button>
			<p className="mx-2 w-3/4 text-center text-xs sm:text-base">
				{formatDate(getDateFromWeekYear({ week, year }), 'dd MMM yy')}
				<span className="mx-2">-</span>
				{formatDate(
					getEndOfWeek(getDateFromWeekYear({ week, year })),
					'dd MMM yy',
				)}
			</p>
			<Button
				onClick={() => {
					const nextWeek = week === 52 ? 1 : week + 1;
					const nextYear = nextWeek === 1 ? year + 1 : year;
					setUrlParams(nextWeek, nextYear);
				}}
				variant="ghost"
				className=""
			>
				<ChevronRight />
			</Button>
		</div>
	);
};
