import { AxiosRequestConfig, setAxiosConfig } from '@frontend/api';
import { QueryClientConfig, QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './queryClient';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

type APIProviderProps = {
	queryConfig?: Partial<QueryClientConfig['defaultOptions']>;
	clientConfig?: Partial<AxiosRequestConfig>;
	queryDebugger?: boolean;
	children: React.ReactNode | React.ReactNode[];
};

export const APIProvider = ({
	queryConfig,
	clientConfig,
	queryDebugger,
	children,
}: APIProviderProps) => {
	if (clientConfig) setAxiosConfig(clientConfig);

	if (queryConfig) queryClient.setDefaultOptions(queryConfig);

	return (
		<QueryClientProvider client={queryClient}>
			{queryDebugger && <ReactQueryDevtools initialIsOpen={false} />}
			{children}
		</QueryClientProvider>
	);
};
