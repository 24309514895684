import { Countdown, useToast } from '#ui';

export const useRetryToast = (seconds: number = 45) => {
	const { toast } = useToast();

	const retryToast = (callback?: () => void) => {
		const ms = seconds * 1000;
		const { dismiss } = toast({
			title: 'Network Error',
			variant: 'destructive',
			description: (
				<>
					Retrying in <Countdown ms={ms} /> seconds
				</>
			),
			duration: ms,
		});

		callback &&
			setTimeout(() => {
				dismiss();
				callback();
			}, ms);
	};

	return retryToast;
};
