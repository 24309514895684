export default function unicodeToEmoji(unicode: string): string {
	// Remove all 'U+' prefixes and split into parts
	const parts = unicode.toUpperCase().replace(/U\+/g, '').split(/\s+/);

	// Validate input
	if (!parts.every((part) => /^[0-9A-F]+$/.test(part))) {
		throw new Error('Invalid Unicode code point');
	}

	// Convert to number(s) and create emoji
	const emojiCodePoints = parts.map((part) => {
		const codePoint = parseInt(part, 16);
		if (codePoint > 0x10ffff) {
			throw new Error('Code point out of valid Unicode range');
		}
		return codePoint;
	});

	return String.fromCodePoint(...emojiCodePoints);
}
