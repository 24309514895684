import { create } from 'zustand';

type UseAppStoreType = {
	online: boolean;
};

export const useAppStore = create<UseAppStoreType>(() => ({
	online: true,
}));

export const setOnline = (online: boolean) =>
	useAppStore.setState((state) => ({ ...state, online }));
