import { Application } from 'pixi.js';

import { getScreenSize } from '@frontend/ui';
import { defaultOptions } from './config';
import {
	PremiseMap,
	PremiseTicker,
	PremiseViewport,
	updateMapCordsQueryParams,
} from './modules';

export class PremiseMapApp extends Application {
	public options = defaultOptions;
	public container: HTMLDivElement;

	public viewport?: PremiseViewport;
	public premiseTicker = new PremiseTicker(this);
	public premiseMap = new PremiseMap(this);
	public isDirty = true;

	constructor(container: HTMLDivElement) {
		super();

		const box = container.getBoundingClientRect();
		const devicePixels = window.devicePixelRatio || 1;

		this.options = {
			...this.options,
			width: box.width,
			height: box.height,
			resizeTo: container,
			resolution: devicePixels,
			antialias: true,
			autoDensity: true,
		};

		this.container = container;
	}

	async init() {
		await super.init(this.options);

		if (this.options.isDeadApp) {
			this.clean();
			return;
		}

		this.initViewport();
		this.initDebugger();
		this.initLOD();
		this.initTextScaling();
		this.premiseMap.addToViewport();

		this.premiseTicker.add(() => {
			this.render();
		});

		this.container.appendChild(this.canvas);
	}

	initViewport() {
		this.viewport = new PremiseViewport(this);

		this.stage.addChild(this.viewport);
	}

	initLOD() {
		const inRange = (lodLevel: number, range: [number, number]) => {
			const [min, max] = range;
			if (max === 0) return lodLevel >= min;
			else return lodLevel >= min && lodLevel <= max;
		};

		this.premiseTicker.add(() => {
			if (this.isDirty) {
				const vpScale = this.viewport?.scale.y || 1;

				// LOD Containers
				this.premiseMap.LODContainers.forEach((c) => {
					if (inRange(vpScale, c.lodRange)) c.visible = true;
					else c.visible = false;
				});

				// set cords for url params
				updateMapCordsQueryParams();

				// Culling
				//this.viewport?.cull();

				// Scaling labels
				this.scaleText();

				this.isDirty = false;
			}
		});
	}

	initTextScaling() {
		if (!this.viewport)
			throw new Error(
				'No viewport found, have you inited it before running this function for text scaling?',
			);

		this.viewport.on('zoomed', () => {
			this.scaleText();
		});
	}

	scaleText() {
		const vpScale = this.viewport?.scale.y || 1;
		const size = getScreenSize();

		let scaleAdjustor = 1;

		if (['xs', 'sm', 'md'].includes(size)) {
			scaleAdjustor = 0.65;
		}

		const scale = scaleAdjustor / vpScale;

		this.premiseMap.labels.forEach((l) => l.scale.set(scale));
	}

	initDebugger() {
		// Enable Debugger for
		// https://github.com/bfanger/pixi-inspector
		if (this.options.enableDebugger)
			// @ts-ignore
			globalThis.__PIXI_APP__ = this;
	}

	clean() {
		if (!this.renderer) {
			this.options.isDeadApp = true;
			return;
		}

		this.premiseTicker?.destroy();
		this.destroy({ removeView: true });
	}
}
