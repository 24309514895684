import { DatePickerBasic, DatePickerWeek } from '@frontend/ui';
import { Label } from '@frontend/ui';
import { getDateFromWeekYear, getWeekYearFromDate } from '@frontend/util';
import { FilterSidebarBaseProps, FilterSidebarRecord } from '../store';

type BaseDatePickerProps<T extends FilterSidebarRecord> =
	FilterSidebarBaseProps<
		T,
		{
			name: keyof T;
			label: string;
			type?: 'basic' | 'week';
		}
	>;

export const BaseDatePicker = <T extends FilterSidebarRecord>({
	name,
	label,
	type = 'basic',
	useStore,
}: BaseDatePickerProps<T>) => {
	const { filterValues, setFilterValue } = useStore();

	// TODO: Can we make this more type-safe?
	const selected = filterValues[name] as Date | undefined;

	if (selected !== undefined && !(selected instanceof Date)) {
		throw new Error('Filter value must be a date');
	}

	return (
		<div className="flex flex-col gap-2">
			<Label>{label}</Label>
			{type === 'basic' && (
				<DatePickerBasic
					date={selected}
					setDate={(value) => setFilterValue(name, value as T[keyof T])}
				/>
			)}
			{type === 'week' && (
				<DatePickerWeek
					value={selected && getWeekYearFromDate(selected)}
					setValue={(value) =>
						setFilterValue(
							name,
							getDateFromWeekYear({ ...value }) as T[keyof T],
						)
					}
				/>
			)}
		</div>
	);
};
