import { Graphics } from 'pixi.js';
import { FillMap, IconName, getIconSvg } from './assets';

export class Icon extends Graphics {
	constructor({
		name,
		fillMap = { primary: 'border', secondary: 'background' },
	}: {
		name: IconName;
		fillMap?: FillMap;
	}) {
		super();

		const svg = getIconSvg(name, fillMap);

		this.svg(svg);

		this.scale.set(0.75);
	}
}
