import { FluidSlider, shiftTypeSchema } from '@components';
import { ShiftType } from '@frontend/api';
import { useMutPostShiftType, useMutPutShiftType } from '@frontend/context';
import { useTranslation } from '@frontend/locale';
import {
	Button,
	Checkbox,
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
	Input,
	Textarea,
	useToast,
} from '@frontend/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import {
	Dispatch,
	HTMLInputTypeAttribute,
	SetStateAction,
	useEffect,
} from 'react';
import { Control, FieldValues, Path, useForm } from 'react-hook-form';
import { z } from 'zod';

export function ShiftTypeDialog({
	sectionId,
	open = false,
	setOpen,
	onClose,
	shiftType,
	shiftTypesArr,
}: {
	sectionId: string;
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	onClose: () => void;
	shiftType?: ShiftType;
	shiftTypesArr: {
		names: string[];
		starts: string[];
		ends: string[];
	};
}) {
	const { t } = useTranslation();
	type Infered = z.infer<typeof shiftTypeSchema>;

	const form = useForm<Infered>({
		resolver: zodResolver(shiftTypeSchema),
		defaultValues: {
			name: shiftType?.name ?? '',
			start: shiftType?.start.slice(0, -3) ?? '',
			end: shiftType?.end.slice(0, -3) ?? '',
			sectionId,
		},
	});

	const { isDirty } = form.formState;

	const { toast } = useToast();

	const post = useMutPostShiftType({
		invalidateQueryKey: ['shift-types'],
		onSuccess: (data) => {
			toast({
				title: t('actions.shift-type-added.title'),
				description: t('actions.shift-type-added.desc', {
					name: data.name,
				}),
			});
		},
	});
	const put = useMutPutShiftType({
		invalidateQueryKey: ['shift-types'],
		onSuccess: () => {
			toast({
				title: t('actions.shift-type-updated.title'),
				description: t('actions.shift-type-updated.desc'),
			});
		},
	});

	const resetForm = (shiftType: ShiftType | undefined) => {
		form.reset({
			name: shiftType?.name ?? '',
			start: shiftType?.start.slice(0, -3) ?? '',
			end: shiftType?.end.slice(0, -3) ?? '',
			sectionId,
		});
	};

	useEffect(() => {
		resetForm(shiftType);
	}, [shiftType]);

	const onSubmit = (data: Infered) => {
		const editing = !!shiftType;

		const { names, starts, ends } = shiftTypesArr;

		if (!isDirty) {
			return toast({
				title: t('actions.no-change.title'),
				description: t('actions.no-change.desc'),
				variant: 'destructive',
			});
		}

		if (editing) {
			names.splice(names.indexOf(shiftType.name), 1);
			starts.splice(starts.indexOf(shiftType.start), 1);
			ends.splice(ends.indexOf(shiftType.end), 1);
		}

		let isValid = true;
		if (shiftTypesArr.names.includes(data.name)) {
			form.setError('name', {
				message: t('titles.already-exists', {
					val: data.name,
				}),
			});
			isValid = false;
		}

		if (shiftTypesArr.starts.includes(data.start)) {
			form.setError('start', {
				message: t('titles.already-exists', {
					val: data.start,
				}),
			});
			isValid = false;
		}

		if (shiftTypesArr.ends.includes(data.end)) {
			form.setError('end', {
				message: t('titles.already-exists', {
					val: data.end,
				}),
			});
			isValid = false;
		}

		if (!isValid) {
			return;
		}

		if (editing) {
			put.mutate({
				...data,
				id: shiftType.id,
			});
		} else {
			post.mutate(data);
		}

		toggleDialog();
	};

	const toggleDialog = () => {
		setOpen(!open);
		resetForm(undefined);
		onClose();
	};

	return (
		<Dialog open={open} onOpenChange={toggleDialog}>
			<DialogContent className="w-80">
				<Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)}>
						<DialogHeader className="mb-4">
							<DialogTitle>
								{shiftType
									? t('titles.edit-shift-type')
									: t('titles.add-shift-type')}
							</DialogTitle>
						</DialogHeader>
						<DialogFormField
							control={form.control}
							name="name"
							label={t('common.name')}
						/>
						<DialogFormField
							control={form.control}
							name="start"
							label={t('common.start')}
							type="time"
						/>
						<DialogFormField
							control={form.control}
							name="end"
							label={t('common.end')}
							type="time"
						/>
						<DialogFooter className="mt-4 flex justify-between">
							<Button onClick={toggleDialog} variant="ghost" type="reset">
								{t('common.cancel')}
							</Button>
							<Button type="submit">{t('common.submit')}</Button>
						</DialogFooter>
					</form>
				</Form>
			</DialogContent>
		</Dialog>
	);
}

// TODO: MOVE THIS AND REFACTORINO
export function DialogFormField<TFieldValues extends FieldValues>({
	control,
	name,
	label,
	isCheckbox = false,
	type = 'text',
	disabled,
	placeholder,
}: {
	control: Control<TFieldValues>;
	name: keyof TFieldValues;
	label?: string;
	isCheckbox?: boolean;
	type?: HTMLInputTypeAttribute | 'slider' | 'textarea';
	disabled?: boolean;
	placeholder?: string;
}) {
	if (type === 'textarea') {
		return (
			<FormField
				control={control}
				name={name as Path<TFieldValues>}
				render={({ field }) => (
					<FormItem className="mb-4">
						{label && <FormLabel>{label}</FormLabel>}
						<FormControl>
							<Textarea
								rows={10}
								{...field}
								disabled={disabled ?? false}
								placeholder={placeholder ?? ''}
							/>
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
		);
	}

	if (type === 'slider') {
		return (
			<FormField
				control={control}
				name={name as Path<TFieldValues>}
				render={({ field }) => (
					<FormItem className="mb-4">
						{label && <FormLabel>{label}</FormLabel>}
						<FormControl>
							<div>
								<FluidSlider
									initialVal={field.value}
									onChange={field.onChange}
									disabled={disabled ?? false}
								/>
							</div>
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
		);
	}

	if (isCheckbox) {
		return (
			<FormField
				control={control}
				name={name as Path<TFieldValues>}
				render={({ field }) => (
					<FormItem>
						{label && <FormLabel>{label}</FormLabel>}
						<FormControl>
							<div>
								<Checkbox
									checked={field.value}
									onCheckedChange={field.onChange}
									disabled={disabled ?? false}
								/>
							</div>
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
		);
	}

	return (
		<FormField
			control={control}
			name={name as Path<TFieldValues>}
			render={({ field }) => (
				<FormItem className="mb-4">
					{label && <FormLabel>{label}</FormLabel>}
					<FormControl>
						<Input type={type} {...field} disabled={disabled ?? false} />
					</FormControl>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
}
