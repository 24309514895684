import { ChartColorsObj, ChartMargin } from '@components';
import { ComponentProps } from 'react';
import { CartesianGrid, XAxisProps, YAxisProps } from 'recharts';

import { twColors } from '@frontend/ui';

type ChartDefaults = {
	xAxis: XAxisProps;
	yAxis: YAxisProps;
	cartesianGrid: ComponentProps<typeof CartesianGrid>;
	colors: ChartColorsObj;
	margin: ChartMargin;
};

export const chartConfig: ChartDefaults = {
	xAxis: {
		className: 'fill-muted-foreground stroke-foreground',
		stroke: '',
		tick: {},
	},
	yAxis: {
		className: 'fill-muted-foreground stroke-foreground',
		stroke: '',
		tick: {},
	},
	cartesianGrid: {
		vertical: false,
		strokeDasharray: '3 3',
	},
	colors: {
		dark: [twColors['gray']['400'], twColors['gray']['50']],
		light: [twColors['gray']['400'], twColors['gray']['800']],
	},
	margin: { top: 5, right: 5, bottom: 5, left: 5 },
};
