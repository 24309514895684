import {
	BaseApplyButton,
	BaseBadgesList,
	BaseClearButton,
	BaseCloseButton,
	BaseFilterSidebar,
	BaseToggleButton,
} from './controls';
import { injectSidebarStore } from './inject';
import {
	BaseCombobox,
	BaseDatePicker,
	BaseDatePickerRange,
	BaseRadioGroup,
	BaseSelect,
	BaseSelectMulti,
	BaseSlider,
	BaseText,
} from './inputs';
import { FilterSidebarRecord, createFilterSidebarStore } from './store';

export const useFilterSidebar = <T extends FilterSidebarRecord>(
	filterValues: T,
	defaultValues: T,
) => {
	const useStore = createFilterSidebarStore(filterValues, defaultValues);

	return {
		ToggleButton: injectSidebarStore(BaseToggleButton, useStore),
		CloseButton: injectSidebarStore(BaseCloseButton, useStore),
		ApplyButton: injectSidebarStore(BaseApplyButton, useStore),
		ClearButton: injectSidebarStore(BaseClearButton, useStore),
		Badges: injectSidebarStore(BaseBadgesList, useStore),
		Sidebar: injectSidebarStore(BaseFilterSidebar, useStore),
		RadioGroup: injectSidebarStore(BaseRadioGroup, useStore),
		Select: injectSidebarStore(BaseSelect, useStore),
		Combobox: injectSidebarStore(BaseCombobox, useStore),
		DatePicker: injectSidebarStore(BaseDatePicker, useStore),
		Slider: injectSidebarStore(BaseSlider, useStore),
		DatePickerRange: injectSidebarStore(BaseDatePickerRange, useStore),
		Text: injectSidebarStore(BaseText, useStore),
		SelectMulti: injectSidebarStore(BaseSelectMulti, useStore),
		useStore,
	};
};
