import { nb, unicodeToEmoji } from '@frontend/util';
import { LocaleObj } from './types';
import { norway } from './flags';

export default {
	id: 'nb',
	emoji: unicodeToEmoji(norway),
	label: `Norsk`,
	locale: nb,

	translation: {
		// Titles
		titles: {
			// Usage
			'usage-over-time': 'bruk over tid',
			'usage-top-5': 'topp 5 rom med mest bruk',
			'usage-top-5-over-time': 'topp 5 rom med mest bruk over tid',
			'usage-average': 'gjennomsnittlig bruk',
			'usage-total': 'total bruk',
			'usage-by-room': 'bruk per rom',
			'usage-by-group': 'bruk per gruppe',
			'usage-by-shift': 'bruk per skift',
			'usage-by-fluid-type': 'bruk per væske',
			'room-activity': 'romaktivitet',
			'usage-by-shift-name': 'forventet bruk for {{shiftName}} skift',
			'usage-shift-name': '{{shiftName}} skift',
			'expected-usage': 'forventet bruk',
			'nof-hand-disinfections': 'antall hånddesinfiseringer',
			'dispenser-usage': 'dispenserbruk',

			// Shift
			'shift-link': 'arbeidsskift',
			'shift-tab': 'ukeskift',
			'no-shifts': 'ingen skift funnet',
			'confirm-delete-shift': 'slette ukeskift?',

			// Shift Type
			'shift-type-tab': 'skiftperiode',
			'add-shift-type': 'legg til skiftperiode',
			'edit-shift-type': 'rediger skiftperiode',
			'no-shift-types': 'ingen skiftperioder funnet',
			'confirm-delete-shift-type': 'slette skiftperiode?',
			'shift-type': 'skiftperiode',

			// Maintenance
			'maintenance-link': 'vedlikehold',

			// Maintenance Fluid
			'change-device-fluid': 'endre enhetsvæske',
			'device-fluid-changed': 'enhetsvæske endret',
			'level-of-fluid': 'nivå av væske',
			'confirm-bag-change': 'Har du byttet væskebeholder?',

			// Maintenance Battery
			'battery-changed': 'batteri byttet',
			'battery-runs-out-in': 'batteriet går tomt om',
			'power-type': 'strømkilde',

			// Map View
			'map-view-link': 'kartvisning',

			// Misc
			'room-name': 'romnavn',
			'already-exists': 'eksisterer allerede',
			'show-inactive': 'vis inaktive',
			'serial-number': 'serienummer',
			'report-a-problem': 'rapporter et problem',
			'describe-problem': 'beskriv problemet ditt',
			'search-by-room-name': 'søk etter romnavn',
			'dark-mode': 'nattmodus',
			'collapse-sidebar': 'skjul sidepanelet',
			'patient-room': 'pasientrom',
			'value-not-found': 'ingen {{value}} funnet',
			'user-group': 'brukergruppe',
			'per-hour': 'per time',
			'per-day': 'per dag',
			'no-data': 'ingen data',
			'current-period': 'nåværende periode',
			'previous-period': 'forrige periode',
			'room-type': 'romtype',
			'open-filter-sidebar': 'Juster filter',
			'logging-out': 'Logger ut',
			'logging-in': 'Logger inn',

			// Data Table
			'val-of-max': '{{aVal, number}} av {{bVal, number}} resultater',
			'page-of-max': '{{aVal, number}} av {{bVal, number}} sider',
			'show-per-page': 'vis {{val, number}}',
			'card-view': 'kortvisning',
			'list-view': 'listevisning',
		},

		// Actions
		actions: {
			// Common
			'no-change': {
				title: 'ingen endringer oppdaget',
				desc: 'vennligst gjør noen endringer før du sender inn.',
			},

			// Shift
			'shift-added': {
				title: 'ukeskift lagt til',
				desc: 'ukeskift "{{name}}" har blitt lagt til',
			},
			'shift-updated': {
				title: 'ukeskift oppdatert',
				desc: 'ukeskift har blitt oppdatert',
			},
			'shift-deleted': {
				title: 'ukeskift slettet',
				desc: 'ukeskift har blitt slettet',
			},

			// Shift Type
			'shift-type-added': {
				title: 'skiftperiode lagt til',
				desc: 'skiftperiode "{{name}}" har blitt lagt til',
			},
			'shift-type-updated': {
				title: 'skiftperiode oppdatert',
				desc: 'skiftperiode har blitt oppdatert',
			},
			'shift-type-deleted': {
				title: 'skiftperiode slettet',
				desc: 'skiftperiode har blitt slettet',
			},

			// Maintenance
			'maintenance-report-problem': {
				title: 'Rapporter et enhetsproblem',
				desc: 'Vennligst beskriv problemet så kontakter vi deg så snart som mulig',
			},
			'maintenance-report-success': {
				title: 'Problem rapportert',
				desc: 'Problemet ditt har blitt rapportert, og vi vil kontakte deg så snart som mulig',
			},

			// Maintenance - Fluid
			'maintenance-fluid-updated': {
				title: 'væske oppdatert',
				desc: 'væske har blitt oppdatert for enhet {{serialNumber}}',
			},

			// Maintenance - Battery
			'maintenance-battery-change': {
				title: 'skifte enhetsbatteri',
				desc: 'har du skiftet batteri på enheten enheten?',
			},
			'maintenance-battery-updated': {
				title: 'Batteri skiftet',
				desc: 'Batteri har blitt skiftet på enheten enhet {{serialNumber}}',
			},
		},

		// Common
		common: {
			usage: 'bruk',
			start: 'start',
			end: 'slutt',
			name: 'navn',
			department: 'avdeling',
			room: 'rom',
			fluid: 'væske',
			status: 'status',
			battery: 'batteri',
			action_one: 'handling',
			action_other: 'handlinger',
			title: 'tittel',
			message: 'melding',
			dashboard: 'dashbord',
			realtime: 'sanntid',
			home: 'hjem',
			setting_one: 'innstilling',
			setting_other: 'innstillinger',
			logout: 'logg ut',
			toilet: 'toalett',
			bath: 'bad',
			entrance: 'inngang',
			walkway: 'gangvei',
			shift_one: 'skift',
			shift_other: 'skift',
			floor_one: 'etasje',
			floor_other: 'etasjer',
			dosage_one: 'dose',
			dosage_other: 'doser',
			edit: 'rediger',
			week: 'uke',
			active: 'aktiv',
			cancel: 'avbryt',
			submit: 'send inn',
			yes: 'ja',
			no: 'nei',
			role: 'rolle',
			profile: 'profil',
			on: 'på',
			off: 'av',
			cable: 'kabel',
			current: 'nåværende',
			previous: 'forrige',
			search: 'søk',
			select: 'velg',
			loading: 'laster',
			apply: 'bruk',
			other: 'annet',
			less: 'mindre',
			more: 'mer',
			all: 'alle',
			language: 'språk',
			highlight: 'uthev',
			icon: 'ikon',
			count: 'antall',
			heatmap: 'varmekart',
			from: 'fra',
			to: 'til',
			custom: 'skikk',
			preset: 'forvalg',
		},

		// Numbers
		numbers: {
			percentage: '{{val, number}}%',
			lte: 'mindre enn eller lik {{val, number}}',
			lt: 'mindre enn {{val, number}}',
			eq: 'lik {{val, number}}',
			gt: 'større enn {{val, number}}',
			gte: 'større enn eller lik {{val, number}}',
		},

		// Dates
		dates: {
			relative: {
				default: '{{val, datetime}}',
				'seconds-ago': '{{val, number}} sekunder siden',
				'minutes-ago': '{{val, number}} minutter siden',
				'hours-ago': '{{val, number}} timer siden',
				'days-ago': '{{val, number}} dager siden',
				'weeks-ago': '{{val, number}} uker siden',
				'months-ago': '{{val, number}} måneder siden',
				'years-ago': '{{val, number}} år siden',
				'in-seconds': 'om {{val, number}} sekunder',
				'in-minutes': 'om {{val, number}} minutter',
				'in-hours': 'om {{val, number}} timer',
				'in-days': 'om {{val, number}} dager',
				'in-weeks': 'om {{val, number}} uker',
				'in-months': 'om {{val, number}} måneder',
				'in-years': 'om {{val, number}} år',
			},
			previous: {
				yesterday: 'i går',
				'last-week': 'forrige uke',
				'last-month': 'forrige måned',
				'last-year': 'i fjor',
			},
			current: {
				today: 'i dag',
				'this-week': 'denne uken',
				'this-month': 'denne måneden',
				'this-year': 'dette året',
			},
		},

		// Inputs
		inputs: {
			'pick-a-date': 'Velg en dato',
			'date-presets': {
				'select-preset': 'Velg en forhåndsinnstilling',
				today: 'I dag',
				'this-week': 'Denne uken',
				'this-month': 'Denne måneden',
				'this-year': 'Dette året',
			},
			'upload-image': 'Klikk her for å laste opp bilde',
			'select-sections': 'Velg avdelinger',
			'select-date-range': 'Velg datoperiode',
		},

		// App Error
		'app-error': {
			'404': {
				title: 'Siden ble ikke funnet',
				message: 'Beklager, siden du leter etter kunne ikke bli funnet.',
			},
			'401': {
				title: 'Uautorisert tilgang',
				message:
					'Du har ikke tillatelse til å utføre denne handlingen. Vennligst logg inn eller kontakt support for hjelp.',
			},
			'500': {
				title: 'Intern serverfeil',
				message:
					'Oi! Noe gikk galt hos oss. Vennligst prøv igjen senere eller kontakt support for hjelp.',
			},
			unknown: {
				title: 'Feil',
				message:
					'En feil har oppstått. Vennligst prøv igjen senere eller kontakt support for hjelp.',
			},
		},

		// API Error
		'api-error': {
			'400': {
				title: 'Ugyldig forespørsel',
				message: 'Det oppstod en feil under behandling av forespørselen.',
			},
			'401': {
				title: 'Uautorisert tilgang',
				message: 'Du har ikke tillatelse til å utføre denne handlingen.',
			},
			'403': {
				title: 'Adgang nektet',
				message: 'Du har ikke tillatelse til å utføre denne handlingen.',
			},
			'404': {
				title: 'Ressurs ikke funnet',
				message: 'Den forespurte ressursen ble ikke funnet.',
			},
			'500': {
				title: 'Intern serverfeil',
				message: 'En intern serverfeil oppstod. Vennligst prøv igjen senere.',
			},
			unknown: {
				title: 'Ukjent feil',
				message: 'En ukjent feil oppstod. Vennligst prøv igjen senere.',
			},
			'signalR-connection-error': {
				title: 'Tilkoblingsfeil',
				message:
					'Det oppstod en feil under tilkobling til SignalR-serveren. Vennligst prøv igjen senere.',
			},
		},
	},
} satisfies LocaleObj;
