import { APIClient, UpdateShiftType } from '@frontend/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

type UseMutPutShiftTypeProps = {
	invalidateQueryKey?: unknown[];
	onSuccess?: () => void;
};

export const useMutPutShiftType = ({
	invalidateQueryKey,
	onSuccess,
}: UseMutPutShiftTypeProps) => {
	const queryClient = useQueryClient();

	const getData = async (data: UpdateShiftType) => {
		data.start = data.start + ':00';
		data.end = data.end + ':00';
		await APIClient.request('/v1/shift-types/{id}', 'put', {
			params: {
				path: {
					id: data.id,
				},
			},
			body: {
				...data,
			},
		});
	};

	return useMutation({
		mutationKey: ['putShiftType'],
		mutationFn: getData,
		onSuccess: () => {
			if (invalidateQueryKey)
				queryClient.invalidateQueries({
					queryKey: invalidateQueryKey,
				});
			onSuccess && onSuccess();
		},
	});
};
