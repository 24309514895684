import { ThemeColorKey, getThemeColorValue } from '@frontend/ui';
import { hslToHex } from '@frontend/util';

export type IconName =
	| 'bed-double'
	| 'door-enter'
	| 'wc'
	| 'foot-steps'
	| 'fork-and-knife'
	| 'stairs'
	| 'living-room'
	| 'dispenser'
	| 'elevator'
	| 'storage'
	| 'washing-machine'
	| 'office'
	| 'gym'
	| 'staff'
	| 'pill'
	| 'meeting';

type FillKey = 'primary' | 'secondary';

type IconPathName = `paths-${IconName}`;

type Path = {
	d: string;
	fill: FillKey;
};

type MapIconPathList = Record<IconPathName, Path[]>;
export type FillMap = Record<FillKey, ThemeColorKey>;

const getColor = (color?: ThemeColorKey) =>
	color ? hslToHex(getThemeColorValue(color)) : 'red';

export const getIconSvg = (name: IconName, fills: FillMap) => {
	const icon = iconsPaths[`paths-${name}`];
	return `<svg>${icon.map((i) => `<path fill="${getColor(fills[i.fill])}" d="${i.d}"/>`)}</svg>`;
};

const iconsPaths: MapIconPathList = {
	'paths-bed-double': [
		{
			d: 'M3.078 9.052h2.205V7.516c0-.997.456-1.535 1.276-1.535h3.235c.82 0 1.276.538 1.276 1.535v1.536h1.969V7.516c0-.997.455-1.535 1.321-1.535h3.035c.866 0 1.322.538 1.322 1.535v1.536h2.205v-3.06c0-1.984-.856-2.992-2.424-2.992H5.502C3.944 3 3.078 4.008 3.078 5.992v3.06ZM1 20.103c0 .55.292.897.747.897h.51c.447 0 .739-.348.739-.897v-1.591c.1.034.392.056.601.056h16.815c.21 0 .492-.023.592-.056v1.591c0 .55.292.897.738.897h.52c.447 0 .738-.348.738-.897v-6.377c0-2.029-.92-3.138-2.588-3.138H3.588C1.92 10.588 1 11.698 1 13.726v6.377Z',
			fill: 'primary',
		},
	],
	'paths-wc': [
		{
			d: 'M3.765 23v-8.25H2V8.7c0-.605.23-1.123.692-1.553a2.353 2.353 0 0 1 1.66-.647h3.53a2.35 2.35 0 0 1 1.663.647c.46.431.691.949.69 1.553v6.05H8.471V23H3.765ZM6.118 5.4a2.347 2.347 0 0 1-1.662-.646c-.46-.43-.69-.948-.691-1.554-.001-.606.23-1.123.691-1.553A2.362 2.362 0 0 1 6.118 1a2.34 2.34 0 0 1 1.662.647c.463.432.693.95.69 1.553-.002.603-.232 1.12-.69 1.554a2.315 2.315 0 0 1-1.662.646ZM14.94 23v-6.6h-3.53l3-8.415c.158-.477.447-.843.869-1.1a2.69 2.69 0 0 1 1.426-.385c.529 0 1.004.128 1.427.385.423.257.712.623.867 1.1l3 8.415h-3.53V23H14.94Zm1.765-17.6c-.647 0-1.2-.215-1.661-.646-.46-.43-.691-.948-.692-1.554 0-.606.23-1.123.692-1.553A2.362 2.362 0 0 1 16.705 1a2.34 2.34 0 0 1 1.663.647c.463.432.693.95.69 1.553-.002.603-.232 1.12-.69 1.554a2.315 2.315 0 0 1-1.662.646Z',
			fill: 'primary',
		},
	],
	'paths-door-enter': [
		{
			d: 'm13.605 0-2.293 2.294a3.667 3.667 0 0 0-.168 5.009l.165.176 2.59 2.593a3.667 3.667 0 0 0 4.213.7l.214-.114v8.898h2.444a1.222 1.222 0 0 1 .143 2.435L20.77 22H1.22a1.221 1.221 0 0 1-.142-2.436l.142-.008V2.444A2.445 2.445 0 0 1 3.48.006L3.665 0h9.941Zm-.777 12.222a1.833 1.833 0 1 0 0 3.666 1.833 1.833 0 0 0 0-3.666Zm2.802-10.79a1.221 1.221 0 0 1 1.829 1.614l-.102.115-.504.503h3.917a1.222 1.222 0 0 1 .142 2.437l-.142.008h-3.922l.508.51a1.223 1.223 0 0 1-1.613 1.828l-.116-.102-2.59-2.594a1.223 1.223 0 0 1-.1-1.614l.101-.115 2.59-2.59h.002Z',
			fill: 'primary',
		},
	],
	'paths-foot-steps': [
		{
			d: 'M6.024 17.186c-1.06 0-1.922-.383-2.568-1.143-.647-.76-1.071-1.889-1.318-3.53-.364-2.42-.003-4.459 1.016-5.742.6-.755 1.388-1.192 2.277-1.262.762-.061 1.9.248 3 2.062.689 1.136 1.213 2.646 1.437 4.141.272 1.805.07 3.134-.618 4.062-.545.737-1.384 1.193-2.494 1.36a4.984 4.984 0 0 1-.732.052Zm1.836 6.316a3.25 3.25 0 0 1-1.812-.58 3.74 3.74 0 0 1-1.571-2.372c-.134-.687-.054-1.233.244-1.67.485-.71 1.354-.87 2.273-1.04l.296-.055c.211-.04.428-.094.638-.146.787-.194 1.6-.395 2.272.082.45.32.703.861.78 1.656.085.917-.112 1.833-.555 2.578-.478.802-1.194 1.332-2.016 1.494-.18.035-.365.053-.549.053Zm9.617-10.816c-.244 0-.489-.02-.73-.056-1.11-.164-1.949-.621-2.495-1.36-.687-.927-.89-2.256-.617-4.061.224-1.497.746-3.005 1.432-4.137 1.094-1.808 2.234-2.122 3-2.066.886.065 1.673.495 2.273 1.247 1.027 1.283 1.39 3.328 1.025 5.758-.247 1.64-.665 2.763-1.317 3.53-.652.767-1.51 1.145-2.57 1.145Zm-1.836 6.316c-.184 0-.367-.018-.547-.053-.823-.162-1.54-.692-2.016-1.494-.444-.745-.64-1.66-.554-2.578.075-.797.328-1.337.779-1.656.671-.477 1.485-.276 2.272-.082.21.052.426.105.638.146l.295.055c.92.17 1.79.328 2.273 1.04.299.437.379.984.245 1.67a3.74 3.74 0 0 1-1.571 2.372c-.569.382-1.196.58-1.814.58Z',
			fill: 'primary',
		},
	],
	'paths-fork-and-knife': [
		{
			d: 'M5.406 2.4H5.39a.6.6 0 0 0-.459.227.6.6 0 0 0-.124.282C4.788 3.022 4.2 6.714 4.2 8.4c0 1.14.53 2.156 1.356 2.814.3.241.444.503.444.721.002-.021 0 .022 0 0-.032.312-.185 2.38-.325 3.822-.138 1.422-.275 2.94-.275 3.443a2.4 2.4 0 0 0 4.8 0c0-.504-.137-2.02-.275-3.443-.102-1.052-.213-2.77-.325-3.822 0-.22.144-.48.444-.721A3.6 3.6 0 0 0 11.4 8.4c0-1.69-.592-5.399-.607-5.492a.6.6 0 0 0-.581-.508h-.024.001a.606.606 0 0 0-.601.606v4.8a.594.594 0 0 1-1.188.025V3a.6.6 0 0 0-1.2 0v4.8l.001.038a.594.594 0 0 1-1.188-.032V3.007a.607.607 0 0 0-.607-.607ZM13.2 7.8a5.4 5.4 0 0 1 5.4-5.4.6.6 0 0 1 .6.6v8.374c.094 1.078.209 2.425.298 3.504.148 1.808.302 3.793.302 4.322a2.4 2.4 0 0 1-4.8 0c0-.528.154-2.514.302-4.322.075-.914.186-2.224.243-2.878H15a1.8 1.8 0 0 1-1.8-1.8V7.8Z',
			fill: 'primary',
		},
	],
	'paths-living-room': [
		{
			d: 'M12.5 7c0-1.11.89-2 2-2H18c1.1 0 2 .9 2 2v2.16c-1.16.41-2 1.51-2 2.81V14h-5.5V7ZM6 11.96V14h5.5V7c0-1.11-.89-2-2-2H6c-1.1 0-2 .9-2 2v2.15c1.16.41 2 1.52 2 2.81Zm14.66-1.93c-.98.16-1.66 1.09-1.66 2.09V15H5v-3a2 2 0 0 0-4 0v5c0 1.1.9 2 2 2v2h2v-2h14v2h2v-2c1.1 0 2-.9 2-2v-5c0-1.21-1.09-2.18-2.34-1.97Z',
			fill: 'primary',
		},
	],
	'paths-dispenser': [
		{
			d: 'M17 8c-.417 0-.77-.146-1.062-.437A1.45 1.45 0 0 1 15.5 6.5c0-.283.142-.658.425-1.125A7.92 7.92 0 0 1 17 4c.433.45.792.908 1.075 1.375.283.467.425.842.425 1.125 0 .417-.146.771-.437 1.063A1.442 1.442 0 0 1 17 8Zm2.5 7c-.7 0-1.292-.242-1.775-.725C17.242 13.792 17 13.2 17 12.5c0-.583.258-1.304.775-2.162A15.061 15.061 0 0 1 19.5 8c.633.7 1.208 1.48 1.725 2.338.517.859.775 1.58.775 2.162 0 .7-.242 1.292-.725 1.775-.483.483-1.075.725-1.775.725ZM6 22c-.55 0-1.02-.196-1.412-.587A1.93 1.93 0 0 1 4 20v-8c0-1.5.475-2.8 1.425-3.9A5.823 5.823 0 0 1 9 6.1V4H7V2h6a4.8 4.8 0 0 1 1.6.263c.5.175.967.421 1.4.737l-1.45 1.45a3.892 3.892 0 0 0-.737-.325A2.629 2.629 0 0 0 13 4h-2v2.1c1.433.233 2.625.9 3.575 2C15.525 9.2 16 10.5 16 12v8a1.93 1.93 0 0 1-.587 1.413A1.92 1.92 0 0 1 14 22H6Z',
			fill: 'primary',
		},
		{
			d: 'M11 18H9v-2H7v-2h2v-2h2v2h2v2h-2v2Z',
			fill: 'secondary',
		},
	],
	'paths-elevator': [
		{
			d: 'M5 21c-.55 0-1.02-.196-1.412-.587A1.93 1.93 0 0 1 3 19V5c0-.55.196-1.02.588-1.412A1.93 1.93 0 0 1 5 3h14c.55 0 1.021.196 1.413.588.392.392.588.863.587 1.412v14a1.93 1.93 0 0 1-.587 1.413A1.92 1.92 0 0 1 19 21H5Z',
			fill: 'primary',
		},
		{
			d: 'M10 18H7v-4H6v-2.5c0-.55.197-1.02.588-1.412A1.923 1.923 0 0 1 8 9.5h1c.55 0 1.022.197 1.413.588.391.391.587.862.587 1.412V14h-1v4Zm-.612-9.863A1.208 1.208 0 0 1 8.5 8.5c-.35 0-.646-.12-.887-.362a1.21 1.21 0 0 1-.363-.888c0-.35.12-.646.362-.887A1.22 1.22 0 0 1 8.5 6a1.2 1.2 0 0 1 .887.362c.241.243.362.539.363.888 0 .35-.12.645-.362.887ZM18 11h-5l2.5-4 2.5 4Zm0 2-2.5 4-2.5-4h5Z',
			fill: 'secondary',
		},
	],
	'paths-storage': [
		{
			d: 'M1.44 2.88h21.12v2.4H1.44v-2.4Zm1.44 3.36v15.84h18.24V6.24H2.88Z',
			fill: 'primary',
		},
		{
			d: 'M8.16 10.56h7.2V9.12h-7.2v1.44Z',
			fill: 'secondary',
		},
	],
	'paths-washing-machine': [
		{
			d: 'M3 10c0-3.771 0-5.657 1.172-6.828C5.343 2 7.229 2 11 2h2c3.771 0 5.657 0 6.828 1.172C21 4.343 21 6.229 21 10v4c0 3.771 0 5.657-1.172 6.828C18.657 22 16.771 22 13 22h-2c-3.771 0-5.657 0-6.828-1.172C3 19.657 3 17.771 3 14v-4Z',
			fill: 'primary',
		},
		{
			d: 'M15.43 9.396c1.145.85 2.32 3.012 2.32 4.604a5.636 5.636 0 0 1-1.684 4.066A5.75 5.75 0 0 1 6.25 14a5.752 5.752 0 0 1 9.18-4.604ZM7.47 5.47A.75.75 0 0 1 8 5.25h8a.75.75 0 1 1 0 1.5H8a.75.75 0 0 1-.53-1.28Z',
			fill: 'secondary',
		},
		{
			d: 'M8.115 14.208c-.113.073-.228.147-.344.218A4.25 4.25 0 0 0 16.25 14v-.005c-.133.023-.27.045-.404.065l-.015.003c-.162.025-.322.05-.486.079-.7.126-1.267.075-1.758-.106-.457-.168-.801-.436-1.055-.633l-.024-.018c-.275-.214-.455-.347-.678-.43-.203-.074-.493-.123-.973-.036-.704.126-1.333.426-1.915.769-.248.147-.48.296-.707.443l-.12.077Z',
			fill: 'primary',
		},
	],
	'paths-office': [
		{
			d: 'M23.25 12a.75.75 0 0 1-.75.75h-1.563A4.508 4.508 0 0 1 16.5 16.5h-3.75v2.25H15a3 3 0 0 1 3 3 .75.75 0 1 1-1.5 0 1.5 1.5 0 0 0-1.5-1.5h-2.25v1.5a.75.75 0 1 1-1.5 0v-1.5H9a1.5 1.5 0 0 0-1.5 1.5.75.75 0 1 1-1.5 0 3 3 0 0 1 3-3h2.25V16.5H7.5a4.508 4.508 0 0 1-4.437-3.75H1.5a.75.75 0 1 1 0-1.5h2.25a.75.75 0 0 1 .75.75 3 3 0 0 0 3 3h9a3 3 0 0 0 3-3 .75.75 0 0 1 .75-.75h2.25a.75.75 0 0 1 .75.75ZM7.5 13.5h9a1.5 1.5 0 0 0 1.485-1.712l-1.286-9A1.507 1.507 0 0 0 15.214 1.5H8.786a1.507 1.507 0 0 0-1.485 1.288l-1.286 9A1.5 1.5 0 0 0 7.5 13.5Z',
			fill: 'primary',
		},
	],
	'paths-gym': [
		{
			d: 'M4.5 3A1.5 1.5 0 0 0 3 4.5v15A1.5 1.5 0 0 0 4.5 21h15a1.5 1.5 0 0 0 1.5-1.5v-15A1.5 1.5 0 0 0 19.5 3h-15Z',
			fill: 'primary',
		},
		{
			d: 'M14.5 8H16v8h-1.5V8Zm4 1.5H17v5h1.5v-2h.5v-1h-.5v-2Zm-9 6.5H8V8h1.5v8Zm-4-1.5H7v-5H5.5v2H5v1h.5v2Zm8-2h-3v-1h3v1Z',
			fill: 'secondary',
		},
	],
	'paths-staff': [
		{
			d: 'M15 8.425a3.001 3.001 0 1 1-6.002-.003A3.001 3.001 0 0 1 15 8.425Z',
			fill: 'primary',
		},
		{
			d: 'M12.003 1c-1.36 0-3.261.535-4.638 1.891L9 5.827c.357-.448.813-.81 1.334-1.06a3.861 3.861 0 0 1 1.669-.377c1.233 0 2.33.574 3.028 1.467l1.604-3.033C15.444 1.54 13.307 1 12.003 1ZM8.135 16.146h.753L7.812 19.78h8.404l-1.07-3.633h.753l1.078 3.633h2.473l-1.688-5.504c-.264-.909-1.42-2.508-3.415-2.557l-4.587-.004c-2.04.01-3.22 1.641-3.488 2.56l-1.72 5.505H7.06l1.075-3.633ZM1 20.937h22V23H1v-2.063Z',
			fill: 'primary',
		},
		{
			d: 'M12.453 3.156h.762v-.901h-.762v-.753h-.914v.753h-.761v.9h.76v.75h.915v-.75Z',
			fill: 'secondary',
		},
	],
	'paths-pill': [
		{
			d: 'M19.31 4.691a5.5 5.5 0 0 0-7.78 0l-6.84 6.84a5.5 5.5 0 1 0 7.78 7.78l6.84-6.84a5.5 5.5 0 0 0 0-7.78Z',
			fill: 'primary',
		},
		{
			d: 'm15.18 15.181 3.42-3.42a4.428 4.428 0 0 0 1.32-3.191 4.5 4.5 0 0 0-7.68-3.17L8.82 8.821l6.36 6.36Z',
			fill: 'secondary',
		},
	],
	'paths-stairs': [
		{
			d: 'M5 21c-.55 0-1.02-.196-1.412-.587A1.93 1.93 0 0 1 3 19V5c0-.55.196-1.02.588-1.412A1.93 1.93 0 0 1 5 3h14c.55 0 1.021.196 1.413.588.392.392.588.863.587 1.412v14a1.93 1.93 0 0 1-.587 1.413A1.92 1.92 0 0 1 19 21H5Z',
			fill: 'primary',
		},
		{
			d: 'M10.425 18H6v-2h2.425v-3.35H11V9.325h2.575V6H18v2h-2.425v3.35H13v3.325h-2.575V18Z',
			fill: 'secondary',
		},
	],
	'paths-meeting': [
		{
			d: 'M12 6.021A3.02 3.02 0 0 0 8.979 3H6.021a3.02 3.02 0 1 0 0 6.042H6.6V10.5S12 9.771 12 6.021ZM9.25 14a2 2 0 1 1-3.999.001A2 2 0 0 1 9.25 14Zm-2 3C5.832 17 3 17.715 3 19.134V21h8.5v-1.866C11.5 17.714 8.668 17 7.25 17Zm9.5-1a2 2 0 1 0 .001-3.999A2 2 0 0 0 16.75 16Zm0 1c-1.418 0-4.25.715-4.25 2.134V21H21v-1.866C21 17.714 18.169 17 16.75 17Zm-.729-13.5A3.02 3.02 0 0 0 13 6.521C13 10.271 17.8 11 17.8 11V9.542h.179a3.021 3.021 0 1 0 0-6.042h-1.958Z',
			fill: 'primary',
		},
	],
};
