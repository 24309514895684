import { getLocalStorage, setDefaultOptions } from '@frontend/util';
import en from './_en';
import nb from './_nb';
import { LocaleObj } from './types';

export type ValidLocale = 'en' | 'nb' | 'nn' | 'se';
export type LocaleInfo = Pick<LocaleObj, 'id' | 'label' | 'locale' | 'emoji'>;

export const locales: Record<ValidLocale, LocaleObj> = {
	en,
	nb,
	nn: { ...nb, id: 'nn', label: 'Nynorsk' },
	se: { ...nb, id: 'se', label: 'Sámi' },
};

// HELPERS

export function getLocaleFromString(id?: string): LocaleObj {
	const keys = Object.keys(locales) as ValidLocale[];

	for (const k of keys) {
		if (k === id || k === id?.split('-')[0]) return locales[k];
	}

	console.warn(
		`Invalid locale ${id}, locale file not found, defaulting to en.`,
	);

	return locales.en;
}

export function getLocaleFromLocalStorage() {
	const i18nLang = getLocalStorage('i18nextLng');
	return i18nLang?.split('-')[0];
}

export function getCurrentLocale() {
	return getLocaleFromString(getLocaleFromLocalStorage());
}

export function getAllLocaleInfo(): LocaleInfo[] {
	return Object.values(locales).map((l) => ({
		id: l.id,
		label: l.label,
		locale: l.locale,
		emoji: l.emoji,
	}));
}

export function setDateLocale(id?: string) {
	const locale = getLocaleFromString(id).locale;
	setDefaultOptions({ locale });
}
