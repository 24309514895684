import { useTheme } from '@frontend/ui';
import { useEffect, useRef, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { FloorNode } from '.';
import { PremiseMapApp, getMapURLParams, usePremiseStore } from './pixi';

type PremiseMapV5Props = {
	nodeFloors: FloorNode[];
	zonesIsHover?: boolean;
};

export const PremiseMapV5 = ({
	nodeFloors,
	zonesIsHover = false,
}: PremiseMapV5Props) => {
	const container = useRef<HTMLDivElement>(null);
	const [resize, setResie] = useState<[number, number]>([0, 0]);
	const { theme } = useTheme();
	const { app, hoverNodeId, activeNodeId, isFullScreen } = usePremiseStore(
		useShallow((state) => ({
			app: state.app,
			hoverNodeId: state.hoverNodeId,
			activeNodeId: state.activeNodeId,
			isFullScreen: state.isFullScreen,
		})),
	);

	useEffect(() => {
		if (!container.current) return;

		const _app = new PremiseMapApp(container.current);
		_app.init().then(() => {
			usePremiseStore.setState({ app: _app });
		});

		const resize = () => {
			const box = container.current!.getBoundingClientRect();
			setResie([box.width, box.height]);
		};

		window.addEventListener('resize', resize);

		return () => {
			_app.clean();
			window.removeEventListener('resize', resize);
		};
	}, [resize, container.current, isFullScreen, theme]);

	useEffect(() => {
		if (!nodeFloors || !app) return;

		// TODO: Implement multi premise view

		usePremiseStore.setState({
			floorLabelArray: nodeFloors.map((f, i) => ({
				label: f.name,
				value: i,
			})),
		});

		app.premiseMap.render(nodeFloors);
		app.premiseMap.alignChildren({ gap: 200 });
		app.premiseMap.xCenterChildren();
		app.viewport?.scaleToCanvas();
		app.viewport?.updateCullChildren();
		app.viewport?.scaleToCanvasOrCords();
		app.scaleText();

		usePremiseStore.setState({
			isLoading: false,
		});

		const { showHeatmap, showCounts, showNames, showIcons } = getMapURLParams();
		app.premiseMap.toggleRoomNames(showNames !== 0);
		app.premiseMap.toggleRoomCounts(showCounts !== 0);
		app.premiseMap.toggleRoomIcon(showIcons !== 0);
		if (showHeatmap === 1) {
			app.premiseMap.showHeatmap();
		}

		return () => {
			app.premiseMap.clean();
		};
	}, [nodeFloors, app]);

	useEffect(() => {
		if (zonesIsHover) app?.premiseMap.setHoverNode(hoverNodeId);
	}, [app, hoverNodeId]);

	useEffect(() => {
		if (!activeNodeId) return;
		if (zonesIsHover) app?.premiseMap.setActiveNode(activeNodeId);
	}, [app, activeNodeId]);

	return (
		<div
			ref={container}
			className="absolute h-full w-full overflow-hidden overscroll-none"
		/>
	);
};
