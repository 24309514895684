import { DosagesByRoomChart, Spinner } from '@components';
import { useQueryGetUsageInPeriod } from '@frontend/context';
import { useTranslation } from '@frontend/locale';
import { Card, CardContent, CardHeader, CardTitle } from '@frontend/ui';
import { DateRange } from '@frontend/util';

type DosagesByRoomProps = DateRange & {
	sectionIds: string[];
	roomTypeIds?: string[];
};

export const DosagesByRoomCard = ({
	from,
	to,
	preset,
	sectionIds,
	roomTypeIds,
}: DosagesByRoomProps) => {
	const { t } = useTranslation();
	const { isLoading, data } = useQueryGetUsageInPeriod({
		from,
		to,
		roomTypeIds: roomTypeIds || [],
		sectionIds: sectionIds || [],
		roomIds: [],
	});

	return (
		<Card>
			<CardHeader>
				<CardTitle>{t('titles.usage-top-5-over-time')}</CardTitle>
			</CardHeader>
			<CardContent className="flex h-80 items-center justify-center">
				{isLoading && <Spinner className="h-20 w-20" />}
				{!isLoading && (
					<DosagesByRoomChart
						preset={preset}
						transactions={data?.transactions}
						from={from}
						to={to}
					/>
				)}
			</CardContent>
		</Card>
	);
};
