import { differenceInCalendarDays } from 'date-fns/differenceInCalendarDays';
import { differenceInCalendarYears } from 'date-fns/differenceInCalendarYears';
import { differenceInCalendarMonths } from 'date-fns/differenceInCalendarMonths';
import type { Duration } from 'date-fns';
import { DateRange } from './';

export default function ({ from, to, preset }: DateRange): {
	[key in keyof Duration]: number;
} {
	switch (preset) {
		case 'day':
			return { days: 1 };
		case 'week':
			return { days: 7 };
		case 'month':
			return { months: to ? differenceInCalendarMonths(to, from) + 1 : 0 };
		case 'year':
			return { years: to ? differenceInCalendarYears(to, from) + 1 : 0 };
		case 'custom':
			return { days: to ? differenceInCalendarDays(to, from) + 1 : 0 };
	}
}
