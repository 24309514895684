import { usePremise } from '@frontend/context';
import { useURLParams } from '@frontend/hook';
import { getCurrDateReadable, useTranslation } from '@frontend/locale';
import { DateRange, getDateRange, getFormatForRange } from '@frontend/util';
import { useEffect, useState } from 'react';
import { useFilterSidebar } from '../FilterSidebar';

export type MapViewFilterType = DateRange & {
	sectionId: string;
	sectionName: string;
	range?: DateRange;
};

export const useMapViewFilterSidebar = () => {
	const { t } = useTranslation();
	const { sections } = usePremise();
	const sectionOpts = Object.values(sections).map((section) => ({
		label: section.name,
		value: section.id,
	}));

	const { setURLParam, getURLParam, defaultValues } = useURLParams<
		Omit<MapViewFilterType, 'range'>
	>({
		sectionId: sectionOpts[0]?.value,
		sectionName: sectionOpts[0]?.label,
		...getDateRange({ from: new Date(), preset: 'day' }),
	});

	const urlParams = getURLParam([
		'sectionId',
		'sectionName',
		'preset',
		'from',
		'to',
	]);

	const [filterState, setFilterState] = useState<
		Omit<MapViewFilterType, 'range'> | undefined
	>(urlParams);

	const { Sidebar, Select, DatePickerRange, ToggleButton, Badges } =
		useFilterSidebar<MapViewFilterType>(
			{
				...urlParams,
				range: {
					from: urlParams.from,
					to: urlParams.to,
					preset: urlParams.preset,
				},
			},
			{
				...defaultValues,
				range: {
					from: defaultValues.from,
					to: defaultValues.to,
					preset: defaultValues.preset,
				},
			},
		);

	const applyFilter = (filters: Partial<MapViewFilterType>) => {
		const obj: MapViewFilterType = {
			...urlParams,
			...filters,
			...filters.range,
		};

		if (filters.sectionId) {
			const section = sections[filters.sectionId];
			obj.sectionName = section.name;
		}

		const keys = Object.keys(obj) as (keyof MapViewFilterType)[];

		keys.forEach((key) => {
			if (key === 'range') return;
			const val = obj[key];
			setURLParam(key, val);
		});

		setFilterState(obj);
	};

	useEffect(() => {
		if (sectionOpts.length > 0) applyFilter({});
	}, [sections]);

	const FilterSidebar = () => (
		<Sidebar onApply={(vals) => applyFilter(vals)}>
			<Select
				label={t('common.department')}
				name="sectionId"
				selectFirst={true}
				opts={sectionOpts}
			/>
			<DatePickerRange name="range" label={'Select Date'} />
		</Sidebar>
	);

	const BadgesList = () => (
		<Badges
			badges={{
				sectionName: {
					label: t('common.department'),
					value: filterState?.sectionName,
					preventOnBadgeRemove: true,
				},
				range: {
					value: () => {
						const { from, to, preset } = filterState || urlParams;

						if (preset !== undefined && preset !== 'custom')
							return getCurrDateReadable(preset);

						const [sf, st] = getFormatForRange({ from, to, preset });

						return `${sf} - ${st}`;
					},
					preventOnBadgeRemove: true,
				},
			}}
		/>
	);

	return {
		ToggleButton,
		FilterSidebar,
		applyFilter,
		filterState,
		BadgesList,
	};
};
