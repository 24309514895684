// 100% 50% 50% => [100,50,50]
export default function (hslString: string) {
	return hslString.split(' ').map((s) => {
		const clean = s.replace('%', '');
		const num = parseInt(clean);
		if (isNaN(num)) throw new Error(`Invalid HSL string: ${hslString}`);

		return num;
	});
}
