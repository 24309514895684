import { Link, TypographyMuted } from '@frontend/ui';
import { useLinkTree } from '@hooks';
import { useLocation } from 'react-router-dom';

export function Breadcrumbs() {
	const { getBreadcrumbs } = useLinkTree();
	const match = useLocation();

	const breadcrumbs = getBreadcrumbs(match.pathname);

	return (
		<div className="flex py-2">
			{breadcrumbs.map((breadcrumb, index) => {
				return (
					<TypographyMuted key={index}>
						<Link to={breadcrumb.href}>{breadcrumb.label}</Link>
						{breadcrumbs.length - 1 > index ? (
							<span className="mx-2">/</span>
						) : null}
					</TypographyMuted>
				);
			})}
		</div>
	);
}
