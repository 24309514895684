import { format } from 'date-fns/format';

type FormatDate = Parameters<typeof format>;

export default function formatDate(
	date: FormatDate[0],
	formatStr: FormatDate[1],
	opts?: FormatDate[2],
) {
	return format(date, formatStr, opts);
}
