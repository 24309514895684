import { useTranslation } from '@frontend/locale';
import { Button } from '@frontend/ui';
import { Grid2x2, Rows3 } from 'lucide-react';

type ViewSwitcherProps = {
	state: 'table' | 'card';
	setState: (view: 'table' | 'card') => void;
};

export const ViewSwitcher = ({ state, setState }: ViewSwitcherProps) => {
	const { t } = useTranslation();
	return (
		<>
			<Button
				title={t('titles.card-view')}
				className="p-2"
				variant={state === 'card' ? 'default' : 'outline'}
				onClick={() => setState('card')}
			>
				<Grid2x2 />
			</Button>

			<Button
				title={t('titles.list-view')}
				className="p-2"
				variant={state === 'table' ? 'default' : 'outline'}
				onClick={() => setState('table')}
			>
				<Rows3 className="h-6 w-6" />
			</Button>
		</>
	);
};
