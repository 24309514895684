import { throttle } from '@frontend/util';
import { Viewport } from 'pixi-viewport';
import { Container } from 'pixi.js';
import { getMapURLParams } from '.';
import { PremiseMapApp } from '..';
import { DisplayObjectWithCulling, Simple } from './cull';

export default class PremiseViewport extends Viewport {
	private _app: PremiseMapApp;
	private _cull = new Simple();

	constructor(app: PremiseMapApp) {
		super({
			screenWidth: app.options.width,
			screenHeight: app.options.height,
			worldWidth: app.options.width,
			worldHeight: app.options.height,
			events: app.renderer.events,
			allowPreserveDragOutside: true,
		});

		this._app = app;

		this.drag()
			.pinch()
			.wheel({ smooth: 15, wheelZoom: true, trackpadPinch: true });

		this.on(
			'moved',
			throttle(() => {
				this._app.isDirty = true;
			}, 100),
		);
	}

	private zoomFn(deltaY = 1, zoomStrength = 0.2) {
		const centerX = this.center.x;
		const centerY = this.center.y;
		const scaleAdjustCalc = this.scale.y * zoomStrength;

		if (deltaY > 0) {
			this.setZoom(this.scale.y + scaleAdjustCalc);
		} else {
			this.setZoom(this.scale.y - scaleAdjustCalc);
		}

		this.moveCenter(centerX, centerY);
		this._app.isDirty = true;
	}

	zoomIn() {
		this.zoomFn();
	}

	zoomOut() {
		this.zoomFn(-1);
	}

	zoomTo(scale: number) {
		this.setZoom(scale);
	}

	moveTo(x: number, y: number, center = false) {
		if (center) {
			this.moveCenter(x, y);
		} else {
			this.position.set(x, y);
		}
		this._app.isDirty = true;
	}

	scaleToCanvas(padding = 0.05) {
		const child = this.children[0];
		if (!(child instanceof Container)) {
			console.error('Child is not a PIXI.Container');
			return;
		}

		const scale =
			Math.min(
				this.screenWidth / child.width,
				this.screenHeight / child.height,
			) - padding;
		this.zoomTo(scale);

		const centeredX = (this.screenWidth - child.width * scale) / 2;
		const centeredY = (this.screenHeight - child.height * scale) / 2;

		this.moveTo(centeredX, centeredY);
	}

	zoomReset() {
		this.scaleToCanvas();
	}

	updateCullChildren() {
		const cullable: DisplayObjectWithCulling[] = [];

		const cont = this._app.premiseMap;
		cullable.push(...(cont.children as DisplayObjectWithCulling[]));

		this._cull.addList(cullable);
	}

	zoomToFloor(floorIndex: number) {
		const floor = this._app.premiseMap.children[floorIndex];
		if (!floor) {
			console.warn('unable to find floor, skiping zoom');
			return;
		}

		const padding = 0.05;

		const bounds = floor.getLocalBounds();

		// Adjust target screen dimensions to account for padding
		const targetWidth = this._app.screen.width;
		const targetHeight = this._app.screen.height;

		// Calculate the desired scale to fit the object within the adjusted viewport dimensions
		const scale =
			Math.min(targetWidth / bounds.width, targetHeight / bounds.height) -
			padding;

		this.zoomTo(scale);

		// Calculate the center position of the container adjusted by the new scale
		const x = floor.x + bounds.width / 2;
		const y = floor.y + bounds.height / 2;

		// Move the viewport to center on the calculated position
		this.moveTo(x, y, true);
	}

	scaleToCanvasOrCords() {
		const cords = getMapURLParams();

		if (cords.posX && cords.posY && cords.scale) {
			this.zoomTo(cords.scale);
			this.moveTo(cords.posX, cords.posY);
			return;
		}

		this.scaleToCanvas();
	}

	public cull() {
		// does not work well
		// It culls whole containers rather then individual objects
		// Best thing to do is to do our our impl in the future
		this._cull.cull(this.getVisibleBounds());
	}
}
