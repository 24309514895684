import { ButtonWithPopover, ShiftTypeDialog } from '@components';
import { ShiftType } from '@frontend/api';
import {
	useMutDeleteShiftType,
	useQueryGetShiftTypes,
} from '@frontend/context';
import { useTranslation } from '@frontend/locale';
import {
	Button,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
	useToast,
} from '@frontend/ui';
import { CheckIcon, Edit, Plus, XIcon } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';

type ShiftTypeTableProps = {
	sectionIds: string[];
};

export function ShiftTypeTable({ sectionIds }: ShiftTypeTableProps) {
	const { t } = useTranslation();
	const { toast } = useToast();

	const [dialogOpen, setDialogOpen] = useState(false);
	const [editing, setEditing] = useState<ShiftType | undefined>(undefined);

	const empty = {
		names: [],
		starts: [],
		ends: [],
	};

	const [typesArr, setTypesArr] = useState<{
		names: string[];
		starts: string[];
		ends: string[];
	}>({ ...empty });

	const { data, refetch } = useQueryGetShiftTypes({ sectionIds: sectionIds });
	const _delete = useMutDeleteShiftType({
		invalidateQueryKey: ['shift-types'],
		onSuccess: () => {
			toast({
				title: t('actions.shift-type-deleted.title'),
				description: t('actions.shift-type-deleted.desc'),
			});
		},
	});

	useEffect(() => {
		if (data) {
			const items = data.items;

			const arr: typeof typesArr = {
				names: [],
				starts: [],
				ends: [],
			};

			items.forEach((item) => {
				if (editing && editing.id === item.id) return;
				arr.names.push(item.name);
				arr.starts.push(item.start.slice(0, -3));
				arr.ends.push(item.end.slice(0, -3));
			});

			setTypesArr((prev) => {
				return {
					...prev,
					names: arr.names,
					starts: arr.starts,
					ends: arr.ends,
				};
			});
		}
	}, [data, editing]);

	const sorted =
		data?.items.sort((a, b) => {
			if (a.start < b.start) {
				return -1;
			}
			if (a.start > b.start) {
				return 1;
			}
			return 0;
		}) || [];

	const onAdd = useCallback(() => {
		setDialogOpen(true);
		if (editing) {
			setEditing(undefined);
		}
	}, [setDialogOpen, setEditing]);

	const onEdit = useCallback(
		(shiftType: ShiftType) => {
			setDialogOpen(true);
			setEditing(shiftType);
		},
		[setDialogOpen, setEditing],
	);

	const onDelete = useCallback(
		(shiftType: ShiftType) => {
			_delete.mutate(shiftType.id);
			refetch();
		},
		[_delete],
	);

	return (
		<>
			<Table>
				<TableHeader>
					<TableRow>
						<TableHead className="w-5">
							<Button variant="default" onClick={onAdd} className="h-7 w-7 p-0">
								<Plus className="h-5 w-5" />
								<span className="sr-only">{t('titles.add-shift-type')}</span>
							</Button>
						</TableHead>
						<TableHead>{t('common.name')}</TableHead>
						<TableHead>{t('common.start')}</TableHead>
						<TableHead>{t('common.end')}</TableHead>
						<TableHead className="hidden">{t('common.active')}?</TableHead>
						<TableHead>{t('common.edit')}</TableHead>
					</TableRow>
				</TableHeader>
				<TableBody>
					{sorted.length ? (
						sorted.map((shiftType, index) => (
							<TableRow key={index}>
								<TableCell className="w-5">
									<ButtonWithPopover onClickYes={() => onDelete(shiftType)}>
										{t('titles.confirm-delete-shift-type')}
									</ButtonWithPopover>
								</TableCell>
								<TableCell className="w-96">{shiftType.name}</TableCell>
								<TableCell className="w-14">
									{shiftType.start.slice(0, -3)}
								</TableCell>
								<TableCell className="w-14">
									{shiftType.end.slice(0, -3)}
								</TableCell>
								<TableCell className="hidden w-10">
									{shiftType.isActive ? <CheckIcon /> : <XIcon />}
								</TableCell>
								<TableCell className="w-5">
									<Button
										className="h-7 w-7 p-0"
										onClick={() => onEdit(shiftType)}
									>
										<Edit className="h-4 w-4" />
									</Button>
								</TableCell>
							</TableRow>
						))
					) : (
						<TableRow>
							<TableCell className="text-center" colSpan={6}>
								{t('titles.no-shift-types')}
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
			<ShiftTypeDialog
				// TODO: can handle this better
				sectionId={sectionIds[0]}
				open={dialogOpen}
				setOpen={setDialogOpen}
				onClose={() => setEditing(undefined)}
				shiftType={editing}
				shiftTypesArr={typesArr}
			/>
		</>
	);
}
