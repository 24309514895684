import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@frontend/ui';

import { Roles, VALID_ROLES, useAuth } from '@frontend/context';
import { insertSpacesBeforeUpperCase } from '@frontend/util';
import { useEffect, useState } from 'react';

export const SelectRole = () => {
	const { getCurrentRole } = useAuth();

	const [selected, setSelected] = useState<string | undefined>(undefined);

	useEffect(() => {
		setSelected(getCurrentRole());
	}, [getCurrentRole]);

	return (
		<Select value={selected} onValueChange={(val: Roles) => setSelected(val)}>
			<SelectTrigger>
				<SelectValue />
			</SelectTrigger>
			<SelectContent>
				<SelectGroup>
					{VALID_ROLES.map((role) => (
						<SelectItem key={role} value={role}>
							{insertSpacesBeforeUpperCase(role)}
						</SelectItem>
					))}
				</SelectGroup>
			</SelectContent>
		</Select>
	);
};
