import { useTranslation } from '@frontend/locale';
import { cn } from '@frontend/util';
import { Frown } from 'lucide-react';
import { PropsWithChildren } from 'react';
import { Spinner } from '../GlobalLoader';

type PremiseMapContainerProps = PropsWithChildren<{
	isLoading?: boolean;
	noFloors?: boolean;
	isFullScreen?: boolean;
}>;

export const PremiseMapContainer = ({
	isLoading,
	noFloors,
	isFullScreen,
	children,
}: PremiseMapContainerProps) => {
	const { t } = useTranslation();
	return (
		<div
			className={cn({
				'bg-background fixed left-0 top-0 z-20 h-screen w-screen overflow-hidden':
					isFullScreen,
				'relative h-full w-full overflow-hidden overscroll-none rounded-lg border':
					!isFullScreen,
			})}
		>
			{isLoading && (
				<div className="bg-background flex h-full w-full items-center justify-center">
					<Spinner />
				</div>
			)}
			{!isLoading && noFloors ? (
				<div className="bg-background flex h-full w-full flex-col items-center justify-center gap-4">
					<Frown className="h-14 w-14" />
					<h2 className="text-xl">{t('titles.no-data')}</h2>
				</div>
			) : (
				children
			)}
		</div>
	);
};
