import { Calendar, Popover, PopoverContent, PopoverTrigger, Button } from '#ui';
import { XIcon } from 'lucide-react';
import { DateRange } from 'react-day-picker';

type CalendarPopoverProps = {
	open: boolean;
	range: DateRange;
	setOpen: (open: boolean) => void;
	onSelect: (range: DateRange | undefined, triggerDate: Date) => void;
};

export function CalendarPopover({
	open,
	setOpen,
	range,
	onSelect,
}: CalendarPopoverProps) {
	return (
		<Popover open={open}>
			<PopoverTrigger className="absolute left-0 top-0 h-10 w-10"></PopoverTrigger>
			<PopoverContent side="left" align="start" className="p-0 w-auto">
				<div className="flex justify-end items-center mr-2 mt-2">
					<Button
						onClick={() => setOpen(false)}
						variant="ghost"
						className="p-2"
					>
						<XIcon className="stroke-muted-foreground h-5 w-5" />
					</Button>
				</div>
				<Calendar
					autoFocus
					className="p-2"
					mode="range"
					defaultMonth={range?.from || new Date()}
					selected={range}
					onSelect={(date, triggerDate) => {
						onSelect(date, triggerDate);
					}}
					numberOfMonths={1}
					pagedNavigation
				/>
			</PopoverContent>
		</Popover>
	);
}
