import { Navigate, RouteObject } from 'react-router-dom';

import { DashboardLayout } from '@layouts';
import {
	Dashboard,
	ErrorBoundary,
	Maintenance,
	MapView,
	Realtime,
	Settings,
	Shifts,
} from '@pages';
import { Suspense, memo } from 'react';
import { App } from '../App';

const LazyDashboard = memo(() => (
	<Suspense>
		<Dashboard />
	</Suspense>
));

export const routes: RouteObject[] = [
	{
		path: '/',
		element: <App />,
		errorElement: <ErrorBoundary />,
		children: [
			{
				path: '/',
				element: <Navigate to="home" />,
			},
			{
				path: '/dashboard',
				element: <Navigate to="home" />,
			},
			{
				element: <DashboardLayout />,
				children: [
					{ path: 'home', element: <LazyDashboard /> },
					{ path: 'shifts', element: <Shifts /> },
					{ path: 'maintenance', element: <Maintenance /> },
					{ path: 'settings', element: <Settings /> },
					{ path: 'map-view', element: <MapView /> },
				],
			},
			import.meta.env.DEV
				? {
						path: 'realtime',
						element: <Realtime />,
					}
				: {},
		],
	},
];
