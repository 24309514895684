import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import { paths } from './generated';

const axiosInstance = axios.create({});

// Any specific changes to how request is made can be done here
axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
	// deconstruct query for axios
	const { query, ...rest } = config.params ?? {};

	// if query exists, add it to the params
	if (query) {
		config.params = { ...query, ...rest };
	}

	// for each key in param.paths replace the path with the value in url
	// e.g. /v1/shift-types/{id} -> /v1/shift-types/1
	// this is done because axios does not support path parameters
	if (config.url && config.params?.path) {
		const keys = Object.keys(config.params?.path ?? {});
		keys.forEach((key) => {
			config.url = config.url?.replace(`{${key}}`, config.params?.path[key]);
		});

		// remove path from query Params
		delete config.params.path;
	}

	return config;
});

// Any specific changes to how response is handled can be done here
axiosInstance.interceptors.response.use((response) => response);

export type Path = keyof paths;
type PathMethod<P extends Path> = keyof paths[P];
export type RequestParams<
	P extends Path,
	M extends PathMethod<P>,
> = paths[P][M] extends
	| { parameters: infer Params }
	| { parameters?: infer Params }
	? Params
	: undefined;

export type InferRequestParams<
	P extends Path,
	M extends PathMethod<P>,
> = RequestParams<P, M>;

export type RequestBodyType<
	P extends Path,
	M extends PathMethod<P>,
> = paths[P][M] extends {
	requestBody?: {
		content:
			| { 'application/json': infer Body }
			| { 'multipart/form-data': infer Body };
	};
}
	? Body
	: undefined;

export type ResponseType<
	P extends Path,
	M extends PathMethod<P>,
> = paths[P][M] extends {
	responses: { 200: { content: { 'application/json': infer Schema } } };
}
	? Schema
	: undefined;

export type InferResponseType<
	P extends Path,
	M extends PathMethod<P>,
> = ResponseType<P, M>;

type RequestOpts<P extends Path, M extends PathMethod<P>> = {
	params?: RequestParams<P, M>;
	body?: RequestBodyType<P, M> | FormData;
	contentType?: 'application/json' | 'multipart/form-data';
	additionalParams?: Record<string, string | number | string[]>;
};

export const APIClient = {
	async request<P extends Path, M extends PathMethod<P>>(
		url: P,
		method: M,
		opts: RequestOpts<P, M>,
	): Promise<ResponseType<P, M>> {
		const isFormData = opts.body instanceof FormData;

		const response = await axiosInstance.request<ResponseType<P, M>>({
			url: url,
			method: method as string | undefined,
			params: opts.params,
			data: opts.body,
			timeout: 5000,
			headers: {
				// Set 'Content-Type' if body is not FormData
				...(isFormData
					? {}
					: { 'Content-Type': opts.contentType ?? 'application/json' }),
			},
		});

		return response.data as ResponseType<P, M>;
	},
	client: axiosInstance,
};

// Helpers
export const setAxiosConfig = (config: AxiosRequestConfig) => {
	Object.assign(axiosInstance.defaults, config);
};

export const setAxiosHeader = (key: string, value: string) => {
	axiosInstance.defaults.headers.common[key] = value;
};

export const setAuthHeader = (token: string) =>
	setAxiosHeader('AUTHORIZATION', `Bearer ${token}`);
