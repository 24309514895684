import { useMutPostMessage } from '@frontend/context';
import { useTranslation } from '@frontend/locale';
import {
	Button,
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	Form,
	InputImg,
	useToast,
} from '@frontend/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { Dispatch, SetStateAction, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { DialogFormField } from './ShiftType';

const problemSchema = z.object({
	title: z.string().min(1),
	content: z.string().min(1),
});

export const ProblemDialog = ({
	open,
	setOpen,
}: {
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
	const { t } = useTranslation();
	const { toast } = useToast();
	const [images, setImages] = useState<File[]>([]);
	const form = useForm({
		resolver: zodResolver(problemSchema),
		defaultValues: {
			title: '',
			content: '',
		},
	});

	const post = useMutPostMessage({
		onSuccess: () => {
			toast({
				title: t('actions.maintenance-report-success.title'),
				description: t('actions.maintenance-report-success.desc'),
			});
		},
	});

	const handleImages = (images: File) => {
		setImages((prev) => [...prev, images]);
	};

	const handleSubmit = ({
		title,
		content,
	}: {
		title: string;
		content: string;
	}) => {
		post.mutate(
			{
				title,
				content,
				images,
			},
			{
				onSettled() {
					toggleDialog();
				},
			},
		);
	};

	const toggleDialog = () => {
		setOpen((prev) => !prev);
		form.reset();
		setImages([]);
	};

	return (
		<>
			<Dialog open={open} onOpenChange={() => toggleDialog()}>
				<DialogContent>
					<Form {...form}>
						<form onSubmit={form.handleSubmit(handleSubmit)}>
							<DialogHeader className="mb-2">
								<DialogTitle>
									{t('actions.maintenance-report-problem.title')}
								</DialogTitle>
								<DialogDescription>
									{t('actions.maintenance-report-problem.desc')}
								</DialogDescription>
							</DialogHeader>
							<DialogFormField
								control={form.control}
								name="title"
								label={t('common.title')}
							/>
							<DialogFormField
								control={form.control}
								type="textarea"
								name="content"
								label={t('common.message')}
								placeholder={t('titles.describe-problem')}
							/>
							<InputImg
								label={t('inputs.upload-image')}
								onChange={handleImages}
							/>

							<DialogFooter className="mt-4 flex justify-between">
								<Button
									variant="ghost"
									onClick={() => toggleDialog()}
									type="reset"
								>
									{t('common.cancel')}
								</Button>
								<Button type="submit" isLoading={post.isPending}>
									{t('common.submit')}
								</Button>
							</DialogFooter>
						</form>
					</Form>
				</DialogContent>
			</Dialog>
		</>
	);
};
