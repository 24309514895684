import { cn } from '@frontend/util';
import { forwardRef } from 'react';

type BaseHeatmapBoxProps = {
	color: string;
	className?: string;
};

export const BaseHeatmapBox = forwardRef<HTMLDivElement, BaseHeatmapBoxProps>(
	({ color, className, ...rest }, ref) => (
		<div
			ref={ref}
			{...rest}
			className={cn('mr-1 h-4  w-4 cursor-pointer rounded-[3px]', className)}
			style={{ backgroundColor: color }}
		></div>
	),
);
