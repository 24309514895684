import {
	DateRange,
	DateRangePreset,
	getDateFromWeekYear,
	getDateRange,
} from './';

export default function ({
	week,
	year,
	preset = 'week',
}: {
	week: number;
	year: number;
	preset?: DateRangePreset;
}): DateRange {
	const parsed = getDateFromWeekYear({ week, year });

	return getDateRange({ from: parsed, preset });
}
