import { createContext } from 'react';

import {
	FloorListItem,
	PremiseListItem,
	RoomListItem,
	RoomType,
	SectionListItem,
} from '@frontend/api';

export type PremiseAll = {
	sections: { [id: string]: SectionListItem };
	premises: { [id: string]: PremiseListItem };
	floors: { [id: string]: FloorListItem };
	rooms: { [id: string]: RoomListItem };
	roomTypes: RoomType[];
};

export type PremiseContextValue = {
	getRoomById: (roomId: string) => RoomListItem | undefined;
} & PremiseAll;

const PremiseContext = createContext<PremiseContextValue | undefined>(
	undefined,
);

PremiseContext.displayName = 'Premise Context';

export { PremiseContext };
