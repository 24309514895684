import { Skeleton } from '#ui';
import { cn } from '@frontend/util';
import { forwardRef } from 'react';

const Card = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
	({ className, ...props }, ref) => (
		<div
			ref={ref}
			className={cn(
				'bg-card text-card-foreground rounded-lg border shadow-sm',
				className,
			)}
			{...props}
		/>
	),
);
Card.displayName = 'Card';

const CardHeader = forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
	<div
		ref={ref}
		className={cn('flex flex-col space-y-1.5 p-6', className)}
		{...props}
	/>
));
CardHeader.displayName = 'CardHeader';

const CardTitle = forwardRef<
	HTMLParagraphElement,
	React.HTMLAttributes<HTMLHeadingElement>
>(({ className, children, ...props }, ref) => (
	<h2
		ref={ref}
		className={cn(
			'text-lg font-semibold leading-none tracking-tight',
			className,
		)}
		{...props}
	>
		{children}
	</h2>
));
CardTitle.displayName = 'CardTitle';

const CardDescription = forwardRef<
	HTMLParagraphElement,
	React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
	<p
		ref={ref}
		className={cn('text-muted-foreground text-sm', className)}
		{...props}
	/>
));
CardDescription.displayName = 'CardDescription';

const CardContent = forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
	<div ref={ref} className={cn('p-6 pt-0', className)} {...props} />
));
CardContent.displayName = 'CardContent';

const CardFooter = forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
	<div
		ref={ref}
		className={cn(' flex items-center p-6 pt-0', className)}
		{...props}
	/>
));
CardFooter.displayName = 'CardFooter';

const CardSkeleton = forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
	<Card
		ref={ref}
		className={cn(
			'bg-card text-card-foreground rounded-lg border shadow-sm',
			className,
		)}
		{...props}
	>
		<CardHeader>
			<Skeleton className="h-6 w-1/2" />
		</CardHeader>
		<CardContent>{props.children}</CardContent>
	</Card>
));

CardSkeleton.displayName = 'CardSkeleton';

export {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardSkeleton,
	CardTitle,
};
