export default function (tresholds: number[], value?: number) {
	// 0 or undefined
	if (!value) return 0;

	for (let i = 0; i < tresholds.length; i++) {
		const t0 = tresholds[i - 1];
		const t1 = tresholds[i];

		if (t1 === undefined) continue;
		else if (value >= t0 && value <= t1) return i;
		else if (value >= t1 && i === tresholds.length - 1)
			return tresholds.length - 1;
	}
}
