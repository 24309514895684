import { cn, uuid } from '@frontend/util';
import { FC } from 'react';

type BaseHeatmapHeaderProps = {
	headers: (string | number | Date)[];
	headerFormat: (header: string | number | Date) => string;
};

export const BaseHeatmapHeader: FC<BaseHeatmapHeaderProps> = ({
	headers,
	headerFormat,
}) => {
	const formattedHeaders = [
		headers[0],
		headers[Math.floor(headers.length / 2)],
		headers[headers.length - 1],
	].map((header) => ({
		colSpan: headers.length / 3,
		text: headerFormat(header),
	}));

	return (
		<tr>
			<th></th>
			{formattedHeaders.map((header, index) => (
				<th
					key={uuid()}
					colSpan={header.colSpan}
					className={cn({
						'font-bold': true,
						'text-left': index === 0,
						'text-center': index === 1,
						'text-right': index === 2,
						'border-b': true,
					})}
				>
					{header.text}
				</th>
			))}
		</tr>
	);
};
