import { ChartLabel, ChartSerie } from '@components';
import {
	DateRange,
	dateFormats,
	genRandomNumInRange,
	getDiffInMin,
	formatDate,
	dateAdd,
} from '@frontend/util';

export const genSerieKeyObj = (keys: string[]) => {
	const obj: Record<string, number> = {};
	keys.forEach((key) => {
		obj[key] = 0;
	});
	return obj satisfies ChartSerie;
};

export const genEmptyChartData = ({
	keys,
	from,
	to,
	preset,
	hasDummyData = false,
}: DateRange & {
	keys: string[];
	hasDummyData?: boolean;
}): {
	labels: ChartLabel[];
	series: ChartSerie[];
	positionMap: { [key: string]: number };
} => {
	const labels: ChartLabel[] = [];
	const series: ChartSerie[] = [];

	let current = from,
		index = 0;

	const dummyDataSet: number[][] = [];

	const positionMap: { [key: string]: number } = {};
	const diff = Math.round(getDiffInMin({ from, to }) / 24);

	while (current <= to) {
		labels.push(current);

		const point = genSerieKeyObj(keys);

		if (hasDummyData) {
			keys.forEach((key, _i) => {
				const dataSet = dummyDataSet[_i] || generateGraphData(100);

				if (!dummyDataSet[_i]) dummyDataSet.push(dataSet);

				point[key] = dataSet[index];
			});
		}

		series.push(point);

		positionMap[formatDate(current, dateFormats[preset].short)] = index;

		switch (preset) {
			case 'day':
				current = dateAdd(current, { minutes: 60 });
				break;
			case 'week':
				current = dateAdd(current, { days: 1 });
				break;
			case 'month':
				current = dateAdd(current, { days: 1 });
				break;
			default: // Assuming this is 'year'
				current = dateAdd(current, { minutes: diff });

				break;
		}

		index++;
	}

	return {
		labels,
		series,
		positionMap,
	};
};

function generateGraphData(
	count: number,
	amplitude = genRandomNumInRange(50, 200),
	frequency = genRandomNumInRange(0, 5),
): number[] {
	const graphData: number[] = [];

	for (let i = 0; i < count; i++) {
		const x = (i / count) * 2 * Math.PI; // Map to a full sine wave cycle
		const y = amplitude * Math.sin(frequency * x);
		graphData.push(Math.round(Math.abs(y)));
	}

	return graphData;
}

export function generateRandomPieData(numPoints: number): {
	labels: string[];
	series: { value: number }[];
} {
	const labels: string[] = [];
	const series: { value: number }[] = [];

	for (let i = 0; i < numPoints; i++) {
		const randomLabel = Array.from(
			{ length: 5 },
			() => String.fromCharCode(Math.floor(Math.random() * 26) + 97), // Generates random lowercase letters
		).join('');

		const randomValue = Math.random() * 100;

		labels.push(randomLabel);
		series.push({ value: Math.round(randomValue) });
	}

	return { labels, series };
}
