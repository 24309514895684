export type ResaniTenantInfo = {
	name: string;
	headers: {
		tenant: string;
		section: string;
	};
	urls: {
		authority: string;
		api: string;
		realtime: string;
	};
};

export function getResaniTenantInfo(): ResaniTenantInfo {
	if (!window.RESANI_TENANT_INFO)
		throw new Error('No tenant info found, did you initialize first?');

	return window.RESANI_TENANT_INFO;
}

export const initResaniTenantInfo = ({
	isDev,
	isPreview,
	isDevRoute,
	tenantName,
}: {
	isDev?: boolean;
	isPreview?: boolean;
	isDevRoute?: boolean;
	tenantName?: string;
}) => {
	const url = new URL(window.location.toString());

	const name = tenantName || url.hostname.split('.')[0];

	// assume dev mode by default, meaning pnpm dev script is run
	// and it proxies requests
	const base: ResaniTenantInfo = {
		name,
		headers: getResaniHeaderKeys(),
		urls: {
			authority: '/auth',
			api: '/api',
			realtime: '/realtime',
		},
	};

	// for release we just transform the url strings
	if (!isDev && !isPreview)
		base.urls = createResaniURLs(
			base.name,
			url.hostname.includes('.dev.resani') || isDevRoute,
		);

	window.RESANI_TENANT_INFO = base;
};

export const createResaniURLs = (tenantName: string, dev = false) => {
	const devRoute = dev ? '.dev.' : '.';
	return {
		authority: `https://auth${devRoute}resani.com/realms/${tenantName}`,
		api: `https://api.${tenantName}${devRoute}resani.com`,
		realtime: `https://realtime.${tenantName}${devRoute}resani.com/signalR`,
	};
};

export const getResaniHeaderKeys = () => ({
	tenant: 'x-tenant-identifier',
	section: 'x-tenant-identifiers',
});
