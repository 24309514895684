import { getLocalStorage, insertSpacesBeforeUpperCase } from '@frontend/util';
import { useAuthStore, useIsAuthenticated } from './store';
import { LOCAL_STORAGE_AUTH_KEY, OFFLINE_ACCESS_SCOPE } from './constants';

export const useAuth = () => {
	const isAuthenticated = useIsAuthenticated();

	const getToken = () => {
		return getLocalStorage(LOCAL_STORAGE_AUTH_KEY) || '';
	};

	const getUser = () => useAuthStore.getState().user;
	const getManager = () => useAuthStore.getState().manager;

	const hasRole = (role: string) => {
		const roles = getRoles();

		return roles.includes(role);
	};

	const getGivenName = () => {
		const user = getUser();
		return user?.profile?.given_name ?? '';
	};

	const getCurrentRoleParsed = () => {
		const currentRole = getRoles()[0];
		return currentRole ? insertSpacesBeforeUpperCase(currentRole) : undefined;
	};

	const getRoles = () => {
		const user = getUser();
		return (user?.profile?.roles || []) as string[];
	};

	const getCurrentRole = () => {
		const roles = getRoles();
		return roles[0];
	};

	const logout = () => {
		const manager = getManager();
		manager?.signoutRedirect();
	};

	const hasOfflineAccess = () => {
		const user = getUser();
		const scopes = user?.scopes || [];
		return scopes.includes(OFFLINE_ACCESS_SCOPE);
	};

	return {
		isAuthenticated,
		hasRole,
		getGivenName,
		getRoles,
		getCurrentRoleParsed,
		getCurrentRole,
		logout,
		getToken,
		hasOfflineAccess,
	};
};
