import {
	Breadcrumbs,
	LanguageDropdown,
	SidebarHamburger,
	ThemeToggle,
} from '@components';
import { useAuth, useLoader } from '@frontend/context';
import { useTranslation } from '@frontend/locale';
import {
	Button,
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@frontend/ui';
import { UserCircle2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export function Navbar() {
	const { logout, getGivenName, getCurrentRoleParsed } = useAuth();
	const { t } = useTranslation();
	const { addLoader } = useLoader('auth');
	const navigate = useNavigate();

	return (
		<nav className=" bg-background w-full border-y px-4 py-2">
			<div className="flex items-center justify-between">
				<div className="flex items-center gap-4">
					<SidebarHamburger />
					<Breadcrumbs />
				</div>
				<div className="flex gap-2">
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button variant="ghost" className="p-2">
								<UserCircle2 className="h-6 w-6" />
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent className="mr-10 w-52">
							<DropdownMenuLabel className="flex flex-col font-normal">
								<span>{getGivenName()}</span>
								<span className="text-muted-foreground text-[0.7rem]">
									{getCurrentRoleParsed()}
								</span>
							</DropdownMenuLabel>
							<DropdownMenuSeparator />
							<DropdownMenuItem
								disabled={true}
								className="cursor-pointer"
								onClick={() => navigate('settings')}
							>
								{t('common.setting', { count: 2 })}
							</DropdownMenuItem>
							<DropdownMenuItem className="p-0">
								<LanguageDropdown />
							</DropdownMenuItem>
							<DropdownMenuItem className="p-0">
								<ThemeToggle />
							</DropdownMenuItem>
							<DropdownMenuSeparator />
							<DropdownMenuItem
								className="cursor-pointer"
								onClick={() => {
									addLoader(t('titles.logging-out'));
									logout();
								}}
							>
								{t('common.logout')}
							</DropdownMenuItem>
						</DropdownMenuContent>
					</DropdownMenu>
				</div>
			</div>
		</nav>
	);
}
