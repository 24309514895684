import {
	DosagesByFluidTypeCard,
	DosagesByRoomCard,
	DosagesByRoomHeatmapCard,
	DosagesByRoomPieCard,
	DosagesCard,
	useDashboardFilterSidebar,
} from '@components';

export function Dashboard() {
	const {
		filterState: f,
		FilterSidebar,
		ToggleButton,
		BadgesList,
	} = useDashboardFilterSidebar();

	return (
		<>
			<div className="mb-2 flex items-center gap-2">
				<ToggleButton />
				<BadgesList />
			</div>
			{f.sectionIds.length > 0 && (
				<div className="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3">
					<DosagesCard
						preset={f.preset}
						from={f.from}
						to={f.to}
						pFrom={f.pFrom}
						pTo={f.pTo}
						sectionIds={f.sectionIds}
						roomTypeIds={f.roomTypeId ? [f.roomTypeId] : []}
					/>

					<DosagesByFluidTypeCard
						preset={f.preset}
						from={f.from}
						to={f.to}
						sectionIds={f.sectionIds}
					/>

					<DosagesByRoomHeatmapCard
						preset={f.preset}
						from={f.from}
						to={f.to}
						sectionIds={f.sectionIds}
						roomTypeIds={f.roomTypeId ? [f.roomTypeId] : undefined}
					/>

					<DosagesByRoomPieCard
						preset={f.preset}
						from={f.from}
						to={f.to}
						roomTypeIds={f.roomTypeId ? [f.roomTypeId] : undefined}
						sectionIds={f.sectionIds}
					/>

					<DosagesByRoomCard
						preset={f.preset}
						from={f.from}
						to={f.to}
						roomTypeIds={f.roomTypeId ? [f.roomTypeId] : undefined}
						sectionIds={f.sectionIds}
					/>

					{/*
					<div className="mb-4 break-inside-avoid-column">
						<DosagesByUserGroupCard
							pFrom={f.pFrom}
							pTo={f.pTo}
							from={f.from}
							to={f.to}
							preset={f.preset}
							roomTypeIds={f.roomTypeId ? [f.roomTypeId] : undefined}
							sectionIds={f.sections}
						/>
					</div>
					*/}

					{/*
					<div className="mb-4 break-inside-avoid-column">
						<DosagesByShiftCard
							pFrom={f.pFrom}
							pTo={f.pTo}
							from={f.from}
							to={f.to}
							preset={f.preset}
							roomTypeIds={f.roomTypeId ? [f.roomTypeId] : undefined}
							sectionIds={f.sections}
						/>
					</div>
					*/}
				</div>
			)}
			<FilterSidebar />
		</>
	);
}
