import { Device } from '@frontend/api';
import { t } from '@frontend/locale';
import {
	Button,
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuTrigger,
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from '@frontend/ui';
import { getReadableDate, parseFromUTC, formatDate } from '@frontend/util';
import { Column, ColumnDef } from '@tanstack/react-table';
import {
	ArrowDown,
	ArrowUp,
	BatteryFull,
	BatteryLow,
	Cable,
	Droplet,
	Wrench,
} from 'lucide-react';

const createHeaderWithSort = <T,>(column: Column<T>, title: string) => (
	<Button
		variant="ghost"
		className="flex w-full justify-start p-0 text-xs"
		onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
	>
		{column.getIsSorted() === 'asc' ? (
			<ArrowDown className="mr-2 h-4 w-4" />
		) : (
			column.getIsSorted() && <ArrowUp className="mr-2 h-4 w-4" />
		)}
		{title}
	</Button>
);

export const getDeviceColumns = ({
	onClickBatteryChange,
	onClickFluidChange,
}: {
	onClickBatteryChange: (device: Device) => void;
	onClickFluidChange: (device: Device) => void;
}): ColumnDef<Device>[] => [
	//{
	//	accessorKey: 'isOn',
	//	header: ({ column }) =>
	//		createHeaderWithSort(column, t('common.status')),
	//	cell: (device) => {
	//		const isOn = device.getValue();
	//		return (
	//			<div>
	//				{isOn ? (
	//					<Ping pulse={false} title={t('common.on')} />
	//				) : (
	//					<Ping title={t('common.off')} variant="destructive" />
	//				)}
	//			</div>
	//		);
	//	},
	//},
	{
		accessorKey: 'serialNumber',
		header: ({ column }) =>
			createHeaderWithSort(column, t('titles.serial-number')),
		cell: (device) => {
			const serialNumber = device.getValue() as string;
			return <span className="pl-4 font-bold">{serialNumber}</span>;
		},
	},
	{
		id: 'roomName',
		accessorKey: 'room.name',
		header: ({ column }) => createHeaderWithSort(column, t('common.room')),
	},
	//{
	//	id: 'fluidValue',
	//	accessorKey: 'fluid.value',
	//	header: ({ column }) =>
	//		createHeaderWithSort(column, t('common.fluid')),
	//	accessorFn: (device) => {
	//		const fluid = device.fluid;

	//		if (!fluid) return '-';
	//		else
	//			return t('numbers.percentage', {
	//				val: Math.round(fluid.value),
	//			});
	//	},
	//},
	{
		id: 'batteryValue',
		accessorKey: 'battery.value',
		header: ({ column }) => createHeaderWithSort(column, t('common.battery')),
		accessorFn: (device) => {
			const battery = device.battery;

			if (!battery) return '-';
			else
				return t('numbers.percentage', {
					val: Math.round(battery.value || 0),
				});
		},
	},
	//{
	//	id: 'fluidLastChanged',
	//	accessorKey: 'fluid.lastChangedAt',
	//	header: ({ column }) =>
	//		createHeaderWithSort(column, t('titles.device-fluid-changed')),
	//	cell: (device) => {
	//		const lastChangedAt =
	//			device.getValue() as Device['fluid']['lastChangedAt'];

	//		if (!lastChangedAt) {
	//			return <span>-</span>;
	//		}

	//		const date = parseFromUTC(lastChangedAt);

	//		return (
	//			<Tooltip>
	//				<TooltipTrigger className="cursor-defaul w-32 text-left">
	//					{createReadableDate(date)}
	//				</TooltipTrigger>
	//				<TooltipContent className="text-muted-foreground">
	//					{formatDate(date, 'dd/MM/yyyy HH:mm')}
	//				</TooltipContent>
	//			</Tooltip>
	//		);
	//	},
	//},

	{
		id: 'batteryLastChanged',
		accessorKey: 'battery',
		header: ({ column }) =>
			createHeaderWithSort(column, t('titles.battery-changed')),
		cell: (device) => {
			const battery = device.getValue() as Device['battery'];

			if (!battery || !battery.lastChangedAt) {
				return <span>-</span>;
			}

			const date = parseFromUTC(battery.lastChangedAt);

			return (
				<Tooltip>
					<TooltipTrigger className="cursor-defaul">
						{getReadableDate(date)}
					</TooltipTrigger>
					<TooltipContent className="text-muted-foreground">
						{formatDate(date, 'dd/MM/yyyy HH:mm')}
					</TooltipContent>
				</Tooltip>
			);
		},
	},

	{
		id: 'BatteryExpectedToRunOut',
		accessorKey: 'battery',
		header: ({ column }) =>
			createHeaderWithSort(column, t('titles.battery-runs-out-in')),
		cell: (device) => {
			const battery = device.getValue() as Device['battery'];

			if (!battery?.expectedToRunOutAt) {
				return <span>-</span>;
			}

			const date = parseFromUTC(battery.expectedToRunOutAt);

			return (
				<Tooltip>
					<TooltipTrigger className="cursor-defaul w-32 text-left">
						{getReadableDate(date)}
					</TooltipTrigger>
					<TooltipContent className="text-muted-foreground">
						{formatDate(date, 'dd/MM/yyyy HH:mm')}
					</TooltipContent>
				</Tooltip>
			);
		},
	},
	{
		id: 'runsOnBattery',
		accessorKey: 'runningOnBatteries',
		header: ({ column }) =>
			createHeaderWithSort(column, t('titles.power-type')),
		cell: (device) => {
			const isRunningOnBatteries = device.getValue();
			return <div>{isRunningOnBatteries ? <BatteryFull /> : <Cable />}</div>;
		},
	},
	{
		header: t('common.action', { count: 2 }),
		cell: ({ row }) => {
			const device = row.original;
			return (
				<DropdownMenu>
					<DropdownMenuTrigger asChild>
						<Button variant="default" className="h-7 w-7 p-0">
							<Wrench className="h-4 w-4" />
						</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent>
						<DropdownMenuGroup>
							<DropdownMenuItem
								onClick={() => onClickFluidChange(device)}
								className="cursor-pointer"
							>
								<span className="flex gap-4">
									<Droplet className="h-5 w-5" />
									{t('common.fluid')}
								</span>
							</DropdownMenuItem>
							{device.runningOnBatteries && (
								<DropdownMenuItem
									onClick={() => onClickBatteryChange(device)}
									className="cursor-pointer"
								>
									<span className="flex gap-4">
										<BatteryLow className="h-5 w-5" />
										{t('common.battery')}
									</span>
								</DropdownMenuItem>
							)}
						</DropdownMenuGroup>
					</DropdownMenuContent>
				</DropdownMenu>
			);
		},
	},
];
