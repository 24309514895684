import {
	Bar,
	Tooltip,
	Legend,
	CartesianGrid,
	LabelList,
	LabelProps,
} from 'recharts';
import {
	BaseChartContainer,
	BaseChartProps,
	ChartLegend,
	ChartTooltip,
	createXAxis,
	createYAxis,
} from '@components';

import { chartConfig } from './config';
import { useCallback } from 'react';
import { useTheme } from '@frontend/ui';

export const BaseBarChart = ({
	labels,
	series,
	dataKey,
	hasLegend = true,
	hasTooltip = true,
	colors = chartConfig.colors,
	xAxis,
	yAxis,
	tooltipContent,
	layout,
}: BaseChartProps) => {
	const { theme } = useTheme();

	const bars = series.length
		? Object.keys(series[0]).filter((key) => key !== dataKey)
		: [];

	if (!bars) throw new Error('No bars found in series');

	const data: { [key: string]: number | string | Date }[] = labels.map(
		(label, index) => {
			return {
				[dataKey]: label,
				...series[index],
			};
		},
	);

	const CustomLabel = useCallback(
		(props: LabelProps) => {
			let { x, y, width, height, value, name } = props;

			if (
				typeof x === 'number' &&
				typeof y === 'number' &&
				typeof width === 'number' &&
				typeof height === 'number' &&
				typeof value === 'number'
			) {
				const xPosition = x + width / 2;

				const found = data.find((d) => d['name'] === name);

				if (found) {
					const total = Object.values(found).reduce(
						(v, acc) =>
							typeof v === 'number' && typeof acc === 'number' ? acc + v : 0,
						0,
					) as number;

					value = total > 0 ? Math.round((value / total) * 100) : 0;
				}

				// dont render on small widths
				if (width < 35) return null;

				return (
					<text
						x={xPosition}
						y={y - 10}
						style={{ fontSize: 10 }}
						textAnchor="middle"
						dominantBaseline="middle"
						className="fill-muted-foreground"
					>
						{value}%
					</text>
				);
			}

			return null;
		},
		[data],
	);

	const barsJsx = bars.map((bar, _i) => {
		const color = colors[theme][_i];
		return (
			<Bar
				key={bar + '_bar'}
				dataKey={bar}
				//stackId="stacked" // TODO: move to config
				fill={color}
			>
				<LabelList dataKey={bar} content={CustomLabel} />
			</Bar>
		);
	});

	return (
		<BaseChartContainer
			margin={{ top: 15, left: 0, right: 0, bottom: 0 }}
			type="bar"
			data={data}
			layout={layout}
		>
			{createXAxis({ layout, dataKey, ...xAxis })}
			{createYAxis({ layout, dataKey, ...yAxis })}
			<CartesianGrid
				{...chartConfig.cartesianGrid} // necessary cast
			/>
			{hasLegend && <Legend content={<ChartLegend />} />}
			{hasTooltip && <Tooltip content={tooltipContent || <ChartTooltip />} />}
			{barsJsx}
		</BaseChartContainer>
	);
};
