import { NoRowsFound, TableSpinner } from '@components';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@frontend/ui';
import { Table as TableType, flexRender } from '@tanstack/react-table';
import { Loader2 } from 'lucide-react';

const dataTableRowJSX = <TData,>({
	table,
	columnLength,
}: {
	table: TableType<TData>;
	columnLength: number;
}) => {
	const rowJsx = table.getRowModel().rows?.length ? (
		table.getRowModel().rows.map((row) => (
			<TableRow
				key={row.id}
				className="text-xs"
				data-state={row.getIsSelected() && 'selected'}
			>
				{row.getVisibleCells().map((cell) => (
					<TableCell className="p-2" key={cell.id}>
						{flexRender(cell.column.columnDef.cell, cell.getContext())}
					</TableCell>
				))}
			</TableRow>
		))
	) : (
		<TableRow>
			<TableCell colSpan={columnLength} className="h-24 text-center">
				No results.
			</TableCell>
		</TableRow>
	);

	return rowJsx;
};

export const TableView = <TData,>({
	table,
	columnLength,
	isLoading,
}: {
	table: TableType<TData>;
	columnLength: number;
	isLoading?: boolean;
}) => {
	if (isLoading) return <TableSpinner />;
	if (table.getRowModel().rows.length === 0) {
		return <NoRowsFound />;
	}

	return (
		<div className="relative h-full w-full overflow-auto">
			<Table className="absolute">
				<TableHeader>
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRow key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<TableHead
										className="bg-background sticky top-0"
										key={header.id}
									>
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef.header,
													header.getContext(),
												)}
									</TableHead>
								);
							})}
						</TableRow>
					))}
				</TableHeader>
				<TableBody>
					{isLoading ? (
						<TableRow>
							<TableCell colSpan={columnLength}>
								<div className="flex justify-center">
									<Loader2 className="animate-spin" />
								</div>
							</TableCell>
						</TableRow>
					) : (
						dataTableRowJSX({ table, columnLength: columnLength })
					)}
				</TableBody>
			</Table>
		</div>
	);
};
