import { Ticker } from 'pixi.js';
import { PremiseMapApp } from '..';

export default class PremiseTicker extends Ticker {
	private DEFAULT_FPS = 60;
	private _app: PremiseMapApp;

	constructor(app: PremiseMapApp) {
		super();
		this._app = app;
		this.maxFPS = this.DEFAULT_FPS;
		this.autoStart = true;

		this._app;
	}
}
