import { Input, Button, Label } from '#ui';
import { formatDate } from '@frontend/util';
import { CalendarDays } from 'lucide-react';

type DateStaticInputProps = {
	label: string;
	date?: Date;
	onClickCalendar: () => void;
	onChangeInput: (value: string) => void;
};

export function DateStaticInput({
	label,
	date,
	onClickCalendar,
	onChangeInput,
}: DateStaticInputProps) {
	return (
		<div className="flex gap-1 flex-col">
			<Label>{label}</Label>
			<div className="flex gap-1">
				<Input
					value={date && formatDate(date, 'dd/MM/yyyy, HH:mm:ss')}
					onChange={(e) => onChangeInput(e.target.value)}
				/>
				<Button variant={'outline'} onClick={onClickCalendar} className=" p-2">
					<CalendarDays className="stroke-muted-foreground h-5 w-5" />
				</Button>
			</div>
		</div>
	);
}
