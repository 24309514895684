import { APIClient } from '@frontend/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

type UseMutDeleteShiftTypeProps = {
	invalidateQueryKey?: unknown[];
	onSuccess?: () => void;
};

export const useMutDeleteShiftType = ({
	invalidateQueryKey,
	onSuccess,
}: UseMutDeleteShiftTypeProps) => {
	const queryClient = useQueryClient();

	const _delete = async (id: string) =>
		APIClient.request('/v1/shift-types/{id}', 'delete', {
			params: {
				path: {
					id: id,
				},
			},
		});

	return useMutation({
		mutationKey: ['deleteShiftType'],
		mutationFn: _delete,
		onSuccess: () => {
			if (invalidateQueryKey)
				queryClient.invalidateQueries({
					queryKey: invalidateQueryKey,
				});
			onSuccess && onSuccess();
		},
	});
};
