export * from './components';
export * from './theme';
export * from './utils';
export * from './variants';

import * as colors from 'tailwindcss/colors';

export const twColors = colors;

import dtw from 'tailwindcss/defaultTheme';
import tw from '../tailwind.config';

type Preset = typeof tw;
type Theme = Preset['theme'];
type Extend = Theme['extend'];
type Color = Extend['colors'];

type DSpacing = typeof dtw.spacing;
type DScreen = typeof dtw.screens;

export type ThemeColorKey = keyof Color;
export type ThemeSpacingKey = keyof DSpacing;
export type ThemeScreenKey = keyof DScreen;
